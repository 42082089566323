<template>
  <div class="data-table-container">
    <div class="header">
      <span class="total">{{ itemCount | number }}</span> RESULT
    </div>
    <table class="data-table">
      <thead>
        <tr>
          <th class="date-time">
            Date time
          </th>
          <th class="pet">
            Pet
          </th>
          <th class="owner">
            Owner
          </th>
          <th class="note">
            รายการ
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items.results" :key="item.id">
          <td>
            <div>{{ item.created | toThaiDate}}</div>
            <div class="subtitle">
              {{item.created | toHHMM}}
            </div>
          </td>
          <td>
            <template v-if="item.pet">
              <div class="thumbnail">
                <img v-if="item.pet.image && item.pet.image.medium" :src="item.pet.image.medium" />
              </div>
              <div class="info">
                <div class="name">
                  {{ item.pet.name }}
                </div>
                <div class="subtitle">{{ item.pet.type.name }}</div>
              </div>
            </template>
          </td>
         <td>
           <template v-if="item.pet.owners && item.pet.owners.length > 0">
            <div class="thumbnail">
              <img
                v-if=" item.pet.owners[0].profile.image &&  item.pet.owners[0].profile.image.medium"
                :src=" item.pet.owners[0].profile.image.medium"
              />
            </div>
            <div class="info">
              <div class="name">
                <router-link
                  :to="{ name:'hospitalPetOwnerProfile', params: { id:  item.pet.owners[0].id }}"
                >{{ `${ item.pet.owners[0].profile.firstname} ${ item.pet.owners[0].profile.lastname}` }}</router-link>
              </div>
              <div
                class="subtitle"
                v-if=" item.pet.owners[0].profile.reminder_phone"
              >Tel. {{  item.pet.owners[0].profile.reminder_phone}}</div>
            </div>
            </template>
          </td>
          <td class="note">
            <div class="ellipsis">{{ item.reward.name }}</div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="not-found" v-if="items.length === 0">ไม่พบข้อมูลที่ต้องการค้นหา</div>
    <el-pagination
      class="pagination"
      background
      layout="prev, pager, next"
      :current-page="currentPage"
      :page-size="pageSize"
      :total="total"
      @current-change="(page) => $emit('current-change', page)"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Object,
      required: true
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  computed: {
    itemCount() {
      if (this.items && this.items.count) {
        return this.items.count || 0;
      }
      return 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.data-table-container {
  .header {
    margin-bottom: 5px;
  }
}
.data-table {
  width: 100%;
  border-collapse: collapse;

  a {
    color: #333;
    text-decoration: none;
    &:hover {
      color: #02b0e1;
    }
  }

  thead {
    tr {
      border: #e6eaee 1px solid;

      th {
        padding: 5px;
        font-weight: normal;
        color: #7f8fa4;
        background-color: #f5f8fa;
        text-align: left;
        font-size: 0.9em;

        &.date-time {
          width: 200px;
        }

        &.note {
          width: 300px;
        }
      }
    }
  }

  tbody {
    tr {
      height: 82px;

      td {
        position: relative;
        padding: 5px;
        font-size: 0.9em;

        .subtitle {
          color: #666;
        }

        .thumbnail {
          display: inline-block;
          width: 50px;
          height: 50px;
          overflow: hidden;
          background: #eee;
          border-radius: 5px;
          margin-right: 10px;

          > img {
            width: 50px;
            height: 50px;
            border-radius: 5px;
          }
        }

        .info {
          display: inline-block;
          width: calc(100% - 65px);
          vertical-align: top;
        }

        .name,
        .subtitle {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.note > .ellipsis {
          width: 300px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}

.not-found {
  padding: 20px 0;
  text-align: center;
}

.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>


