<template>
  <div class="pet-owner-search-result" v-if="owners && owners.count > 0">
    <div class="header" >{{ owners.count }} OWNERS RESULT</div>
    <div class="content">
      <div class="owner-box" v-for="owner in owners.results" :key="owner.id">
        <div class="owner-box-content">
          <div class="thumbnail" v-if="owner.profile">
            <Avatar :src="owner.profile.image.medium" :size="70" />
          </div>
          <div class="info" v-if="owner.profile">
            <div class="value">
              <router-link :to="{ name:'hospitalPetOwnerProfile', params: { id: owner.id }}">
              {{ `${owner.profile.firstname} ${owner.profile.lastname}` }}
               </router-link>
            </div>
            <div class="label">Tel</div>
            <div class="value">{{ owner.profile.reminder_phone }}</div>
            <div class="label">Email</div>
            <div class="value">{{ owner.profile.email }}</div>
          </div>
          <div class="info" v-else>
            <div class="label">Tel</div>
            <div class="value">{{ owner.phone }}</div>
          </div>
        </div>
        <div class="owner-box-footer">
          <button
            v-if="owner.profile"
            class="btn btn-tiny btn-primary"
            @click="$router.push({ name: 'hospitalPetOwnerProfile', params: { id: owner.id }})"
          >SEE PROFILE</button>
          <button
            v-if="owner.profile && !isAddToPet"
            class="btn btn-tiny btn-outline"
            v-show="currentPlan.hospital_feature.can_create_owner_and_pet"
            @click="$router.push({ name: 'hospitalPetOwnerAddNewPet', params: { id: owner.id }})"
          >ADD NEW PET</button>
          <button
            v-if="owner.profile && isAddToPet"
            class="btn btn-tiny btn-outline"
            @click="$emit('addToPetClick', owner.id)"
          >ADD TO PET</button>
          <button
            v-if="!owner.profile"
            class="btn btn-tiny btn-primary"
            @click="$router.push({ name: 'hospitalPetOwnerRegister', query: { phone: owner.phone } })"
          >ADD TO HOSPITAL</button>
        </div>
      </div>
    </div>
    <div class="footer">
      <el-pagination
        background
        @current-change="(page) => $emit('current-change', page)"
        :current-page="currentPage"
        :page-size="6"
        layout="prev, pager, next, jumper"
        :total="owners.count"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import Avatar from "@/components/avatar/Avatar"

export default {
  props: ["owners", "currentPage", "isAddToPet"],
  components: {
    Avatar
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      currentPlan: state => state.plan.currentPlan,
    }),
  }
}
</script>

<style lang="scss" scoped>
a {
  color: #333;
  text-decoration: none;
  &:hover {
    color: #02b0e1;
  }
}

.pet-owner-search-result {
  .header {
    margin-bottom: 5px;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
  }
  .footer {
    padding: 20px;
    text-align: center;
  }
}

.owner-box {
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(33% - 10px);
  margin: 5px;
  border: 1px solid #aaa;
  flex-direction: column;
  overflow: hidden;

  .owner-box-content {
    display: flex;
    flex: 1;
    flex-direction: row;

    .thumbnail {
      display: flex;
      flex: 1;
      padding: 10px 10px 0;
    }
    .info {
      display: flex;
      flex-direction: column;
      flex: 3;
      padding: 10px 10px 0;
      font-size: 15px;

      > div {
        display: flex;
        &.label {
          font-size: 0.8em;
          line-height: 1.2em;
          color: #999;
        }
        &.value {
          word-break: break-all;
        }
      }
    }
  }

  .owner-box-footer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 10px 0;
  }
}
</style>
