import requestAppointmentList from '@/pages/requestAppointment/requestAppointmentList.vue'
import requestAppointmentDetail from '@/pages/requestAppointment/requestAppointmentDetail.vue'

const routes = [
  {
    path: '/request-appointment',
    name: 'requestAppointment',
    component: requestAppointmentList,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
  {
    path: '/request-appointment/:id',
    name: 'requestAppointmentDetail',
    component: requestAppointmentDetail,
    meta: {
      requiresAuth: true,
      permissions: ['hospital_admin']
    }
  },
]

export default routes