<template>
  <div class="medical-table-container">
    <div class="header">
      <div class="total">{{ resultTitle }}X</div>
    </div>
    <table class="medical-table">
      <thead>
        <tr>
          <th class="status">&nbsp;</th>
          <th class="date-visit">
            Date Visit
            <!-- <i class="el-icon-arrow-down el-icon-right"></i> -->
          </th>
          <th class="pet">
            Pet
            <!--<i class="el-icon-arrow-down el-icon-right"></i>-->
          </th>
          <th class="status-tag">
            Status
          </th>
          <th class="final-diagnosis">
            Final Diagnosis
            <!--<i class="el-icon-arrow-down el-icon-right"></i>-->
          </th>
          <th class="revenue">
            Revenue
            <!-- <i class="el-icon-arrow-down el-icon-right"></i>-->
          </th>
          <th class="action">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <!--Loop For Medical Records -->
        <tr v-for="medical in medicals.results" :key="medical.id">
          <td  >
            <div :class="getTagClass(medical)" class="tagstatus" >
                {{ currentStatus(medical)['name'] }}
            </div>
          </td>
          <td>
            <div>{{ medical.arrived | toThaiDate}}</div>
            <div class="subtitle">{{ medical.arrived | toHHMM}}</div>
          </td>
          <td>
            <template v-if="medical.pet">
              <img
                v-if="medical.pet.rank && medical.pet.rank <= 3"
                :src="getStatusIcon(medical.pet.rank)"
                alt="Status icon"
                :style='{
                  display: "inline-block",
                  height: "2em",
                  marginRight: "6px",
                  verticalAlign: "super"
                }'
              />
              <div
                v-bind:class="{ blankLeft: medical.pet.rank && medical.pet.rank > 3 }"
                class="thumbnail">
                <img v-if="medical.pet.image && medical.pet.image.medium" :src="medical.pet.image.medium">
              </div>
              <div class="info">
                <router-link
                    :to="{ name:'hospitalPetProfile', params: { id: medical.pethospital.id }}"
                  >{{ medical.pet.name }}</router-link>
                <div class="subtitle">{{ medical.pet.type.name }}</div>
              </div>
            </template>
          </td>
          <td>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-tag
                  size="small"
                  effect="plain"
                  v-if="medical.status_tags.receipt_status"
                  :style="{width: 'auto',minWidth:'100%', textAlign: 'center'}"
                  :class="`${getReceiptStattusTag(medical.status_tags.receipt_status)['class']}`">
                  {{ getReceiptStattusTag(medical.status_tags.receipt_status)['name'] }}
                </el-tag>
              </el-col>
              <el-col :span="12">
                <el-tag
                  size="small"
                  effect="plain"
                  v-if="medical.status_tags.stock_status
                    && medical.status_tags.stock_status !== 'stock_not_update'"
                  :style="{width: 'auto', minWidth:'100%', textAlign: 'center'}"
                  :class="`${getStockStattusTag(medical.status_tags.stock_status)['class']}`">
                  {{ getStockStattusTag(medical.status_tags.stock_status)['name'] }}
                </el-tag>
              </el-col>
            </el-row>
          </td>
          <td>
            <div>{{ medical.final_diagnosis }}</div>
          </td>
          <td>
            <div>{{ medical.revenue | currency }}</div>
          </td>
          <td class="action">
            <el-button
              size="medium"
              style="width: 125px;"
              :type="(medical.progress_status === 'in_progress' && 'primary') || ''"
              @click="$router.push({ name: 'medicals-edit-detail', params: { id: medical.id }})"
            >
              {{ (medical.progress_status === 'in_progress' && '+ Record') || 'See Detail' }}
              <i class="el-icon-arrow-right el-icon-right"></i>
            </el-button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="not-found" v-if="total === 0">
      <img src="@/assets/images/no-result/medical-record-no-result.png" class="responsive-img" />
    </div>
    <el-row class="pagination" type="flex" justify="end">
      <el-col :md="4" class="text-left">
        <button v-if="canExport" class="btn btn-small btn-status-cancel"
          @click="() => $emit('export-data')">
          Export Data
        </button>
      </el-col>
      <el-col :md="20" class="text-right">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="currentPage"
          :page-size="pageSize"
          :total="total"
          @current-change="(page) => $emit('current-change', page)"
        ></el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  props: {
    medicals: {
      type: Object,
      required: true
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    },
    resultTitle: {
      type: String,
      default: 'RESULT'
    },
    canExport: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      currentPlan: state => state.plan.currentPlan
    }),
    medicalCount() {
      if (this.medicals && this.medicals.count) {
        return this.medicals.count || 0
      }
      return 0
    }
  },
  methods: {
    getTagClass(medical) {
       return { tag: true, 'tag-vertical': true, [`${this.currentStatus(medical)['class']}`]: true};
    },
    printMedical(id) {
      let routeData = this.$router.resolve({
        name: 'medicals-detail-pdf',
        params: { id }
      })
      window.open(routeData.href, '_blank')
    },
    currentStatus(medicalRecord) {
      let currentStatus = 'exam';
      if (medicalRecord && medicalRecord.overall_status) {
        currentStatus = medicalRecord.overall_status;
      }
      const statusList = {
        'new': {
          'class': '',
          'name': ''
        },
        'exam': {
          'class': 'opd-in-treatment',
          'name': 'In treatment'
        },
        'cashier': {
          'class': 'opd-payment',
          'name': 'Payment'
        },
        'done': {
          'class': '',
          'name': ''
        },
        'finished': {
          'class': 'opd-done',
          'name': 'Done'
        },
      };
      return statusList[currentStatus];
    },

    getReceiptStattusTag(status) {
      const statusList = {
        'for_payment': {
          'class': 'receipt-for-payment',
          'name': 'For payment'
        },
        'paid': {
          'class': 'pos-paid',
          'name': 'Paid'
        },
        'voided': {
          'class': 'receipt-void',
          'name': 'Void'
        }
      };
      return statusList[status];
    },

    getStockStattusTag(status) {
      const statusList = {
        'stock_out': {
          'class': 'pos-stock-out',
          'name': 'Stock out'
        }
      };
      return statusList[status];
    },

    getStatusIcon(value) {
      switch (value) {
        case 1:
          return require("@/assets/icons/medal-gold.svg");

        case 2:
          return require("@/assets/icons/medal-silver.svg");

        case 3:
          return require("@/assets/icons/medal-bronze.svg");
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.tagstatus {
  width:80px;height:30px;
}

.medical-table-container {
  .header {
    margin-bottom: 5px;
  }
}
.medical-table {
  width: 100%;
  border-collapse: collapse;

  a {
    color: #333;
    text-decoration: none;
    &:hover {
      color: #02b0e1;
    }
  }

  thead {
    tr {
      border: #e6eaee 1px solid;

      th {
        padding: 5px;
        font-weight: normal;
        color: #7f8fa4;
        background-color: #f5f8fa;
        text-align: left;
        font-size: 0.9em;

        &.status {
          width: 30px;
        }

        &.date-visit {
          width: 15%;
        }
        &.pet {
          width: 23%;
        }
        &.final-diagnosis {
          width: 15%;
        }
        &.status-tag {
          width: 20%;
          text-align: center;
        }
        &.revenue {
          width: 10%;
        }
        &.action {
          width: 100px;
        }
      }
    }
  }

  tbody {
    tr {
      height: 82px;

      td {
        position: relative;
        padding: 5px;
        font-size: 0.9em;

        .subtitle {
          color: #666;
        }

        .thumbnail {
          display: inline-block;
          width: 50px;
          height: 50px;
          overflow: hidden;
          background: #eee;
          border-radius: 5px;
          margin-right: 10px;

          > img {
            width: 50px;
            height: 50px;
            border-radius: 5px;
          }
        }

        .info {
          display: inline-block;
          width: calc(100% - 95px);
          vertical-align: top;
        }

        .name,
        .subtitle {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.action {
          text-align: right;
        }

        .blankLeft {
          margin-left: 34px;
        }
      }
    }
  }
}

.not-found {
  margin: 0 auto;
  max-width: 300px;
  padding: 50px 0;
  text-align: center;
}

.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>


