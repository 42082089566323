import ApptRequestPage from '@/pages/appointmentRequest/ApptRequestPage.vue'

const routes = [
  {
    path: '/appointment-request',
    name: 'appointmentRequest',
    component: ApptRequestPage,
    meta: {
      requiresAuth: true,
      permissions: ['pet_owner']
    }
  },
]

export default routes