<template>
  <div class="appt-detail-page">
    <ContentBox>
      <div class="screen-section">
        <h1 class="title">ยินดีต้อนรับสู่ระบบนัดหมาย</h1>
        <div class="search-section">
          <label>เลือกสถานพยาบาล <span class="required-mark">*</span></label>
          <el-input
            placeholder="ค้นหาสถานพยาบาล"
            v-model="searchValue"
            class="search-input"
            clearable
            @keyup.enter.native="search"
            @clear="clearSearch"
          >
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </div>
        <div class="hospital-list-container">
          <el-radio-group v-model="selectedHospital" size="small">
            <ul class="hospital-list">
              <li v-for="hospital in hospitals" :key="hospital.id">
                <div class="details">
                  <img :src="hospital.logo.small" class="small-logo" />
                  {{ hospital.name }}
                </div>
                <div class="action"><el-radio-button :label="hospital.id">เลือก</el-radio-button></div>
              </li>
            </ul>
          </el-radio-group>
        </div>
        <div>
          <el-button type="primary" size="large" class="next-btn">เลือกวันเวลานัด &gt;</el-button>
        </div>
      </div>
    </ContentBox>
    
  </div>
</template>

<script>
import { mapState } from "vuex";
import ContentBox from '@/components/container/ContentBox.vue';

export default {
  components: {
    ContentBox
  },
  data() {
    return {
      searchValue: '',
      selectedHospital: null
    }
  },
  computed: {
    ...mapState({
      hospitals: state => state.hospital.hospitals
    }),
  },
  methods: {
    search() {

    },
    clearSearch() {
      this.searchValue = ''
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch("hospital/listHospitals");
    } catch (e) {
      await this.$store.dispatch("ui/showFetchErrorMsg");
    }
  }
}
</script>

<style lang="scss" scoped>
  .appt-detail-page {
    margin: -100px auto 0;
    max-width: 480px;
    padding: 20px;

    .title {
      text-align: center;
    }

    label {
      font-size: 0.9rem;
    }
  }

  .hospital-list-container {
    margin: 20px 0;
    overflow-y: scroll;
    height: 350px;
    border: 1px solid #eee;
  }

  .hospital-list {
    display: flex;
    width: 100%;
    flex-direction: column;

    > li {
      display: flex;
      padding: 10px;
      border-bottom: 1px solid #eee;
      width: 100%;

      .small-logo {
        max-width: 50px;
        vertical-align: middle;
        margin-right: 10px;
      } 

      .details {
        display: flex;
        font-size: 0.8rem;
        align-items: center;
        padding-right: 5px;
      }

      .action {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        align-items: center;
      }
    }  
  }

  .next-btn {
    width: 100%;
  }
</style>
