<template>
  <div>
    <Subnavbar>
      <template slot="title"
        >Request Appointment / <small>{{ $route.params?.id }}</small>
      </template>
      <template slot="actions">
        <dir v-show="requestApp?.status === 'pending'">
          <el-button plain @click="newAppointment()">รับนัดหมาย</el-button>
          <el-button plain @click="dialogConfirm = true">ยกเลิก</el-button>
        </dir>
      </template>
    </Subnavbar>

    <el-row type="flex" class="row-bg" justify="center">
      <el-col :span="12">
        <el-card>
          <RequestAppDetail :id="$route.params?.id" @dataRequest="getData" />
        </el-card>
      </el-col>
    </el-row>

    <el-dialog :visible.sync="dialogConfirm" width="30%" center>
      <el-row type="flex" class="row-bg" justify="center">
        <el-col align="center" :span="18">
          <strong class="title">ยืนยันการยกเลิกขอนัดหมาย</strong>
        </el-col>
      </el-row>
      <p>
        เมื่อยืนยันการยกเลิกนัดหมาย แล้วจะไม่สามารถกลับมา ทำนัดหมายได้อีก
        และจะแสดงสถานะ ยกเลิกที่ หน้า Pet profile ของสัตว์เลี้ยงด้วย
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogConfirm = false">Cancel</el-button>
        <el-button type="primary" @click="cancelAppointment">Confirm</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="dialogConfirmApprove" width="30%" center>
      <el-row type="flex" justify="center">
        <el-col align="center" :span="20">
          <strong class="title">ยืนยันการรับนัดหมาย</strong>
        </el-col>
      </el-row>
       <el-row class="mt-2" type="flex" justify="center">
        <el-col align="center" :span="24">
          <p>เมื่อยืนยันการรับนัดหมายแล้ว เพื่อให้ติดตามการนัดหมายได้สะดวกขึ้น</p>
          <p> กรุณาสร้างนัดหมายที่ระบบ appointment โดยค้นหาจาก เบอร์</p>
          <p>โทรศัพท์ของเจ้าของ หรือ สร้างเจ้าของกับสัตว์เลี้ยง ขึ้นมาก่อน</p>
          <p>แล้วเริ่มกระบวนการทำนัดหมายต่อไป</p>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogConfirmApprove = false">Cancel</el-button>
        <el-button type="primary" @click="approveAppointment"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>


<script>
import Subnavbar from "@/components/navigation/Subnavbar.vue";
import RequestAppDetail from "@/components/requestAppointment/requestAppDetail.vue";
export default {
  components: {
    Subnavbar,
    RequestAppDetail,
  },
  data() {
    return {
      requestApp: null,

      dialogConfirm: false,
      dialogConfirmApprove: false,
    };
  },
  mounted() {
    // if(this.$route);
  },
  methods: {
    getData(data) {
      console.log(
        "🚀 ~ file: requestAppointmentDetail.vue:46 ~ getData ~ data:",
        data
      );
      this.requestApp = data;
    },
    async newAppointment() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);

        await this.$store.dispatch("pet/setSelected", {
          pet: this.requestApp.pet,
        });
        await this.$store.dispatch(
          "activity/setRequestActivites",
          this.requestApp.activities
        );

        if (this.requestApp.is_anonymous) {
          this.dialogConfirmApprove = true;
        } else {
          this.$router.push({
            name: "appointment-select-activities",
            params: { id: this.requestApp.hospital },
            query: {
              is_favorite: false,
              group: 1,
              requestApp: true,
              requestId: this.requestApp.id,
            },
          });
        }
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
      } finally {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      }
    },
    async cancelAppointment() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);

        await this.$store.dispatch("appointment/updateRequestAppointment", {
          params: { id: this.$route.params?.id, status: "cancel" },
        });
        this.$router.go(-1);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
      } finally {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      }
    },
    async approveAppointment() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);

        await this.$store.dispatch("appointment/updateRequestAppointment", {
          params: { id: this.$route.params?.id, status: "accept" },
        });
        this.$router.go(-1);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
      } finally {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      }
    },
  },
};
</script>

<style lang="scss">
.el-dialog--center .el-dialog__body {
  font-size: 16px;
  padding: 10px 10px;
}
</style>