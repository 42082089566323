<template>
  <div class="owner-detail">
    <Subnavbar>
      <template slot="breadcrumb">
        <ul>
          <li>Owner Profile</li>
        </ul>
      </template>
      <template slot="title">OWNER PROFILE</template>
      <template slot="actions">
        <button
          v-if="owner"
          class="btn btn-large"
          @click="isShowConfirmDeleteDialog = true"
        >
          <i class="fa fa-trash-alt"></i> Delete
        </button>
        <button
          v-if="owner"
          class="btn btn-large btn-register-new-pet"
          v-show="currentPlan.hospital_feature.can_create_owner_and_pet"
          @click="
            $router.push({
              name: 'hospitalPetOwnerAddNewPet',
              params: { id: owner.id },
            })
          "
        >
          <i class="fa fa-plus"></i> Add New Pet
        </button>
        <span></span>
      </template>
    </Subnavbar>

    <el-row>
      <el-col class="el-col-24 el-col-md-8" v-if="owner">
        <PetOwnerDetail
          :owner="owner"
          :showProfile="isShowProfile"
          routeEditOwner="HospitalPetOwnerProfileEdit"
          :isHospital="true"
        />
        <PetNote :pet="owner.profile" label="Owner" />
        <PetOwnerAddress
          v-if="isShowProfile && addresses"
          :addresses="addresses"
        />
      </el-col>
      <el-col
        class="el-col-24 el-col-md-16 content-padding"
        style="height: 100%"
      >
        <el-tabs v-model="activeName">
          <el-tab-pane
            label="Appointment"
            name="appointment"
            style="margin-bottom: -40px;"
          >
            <PetAppointmentTimeline
              viewer="hospital"
              viewIn="ownerPage"
              :petHospitalId="$route.params.id"
              :showPetInfo="true"
              v-if="isAppointmentLoaded && owner"
            />
          </el-tab-pane>
          <el-tab-pane label="Pet" name="pet" style="padding-bottom: 10px;">
            <div v-if="pets">
              <el-table :data="pets">
                <el-table-column label="Pet Name" width="200">
                  <template slot-scope="scope">
                    <img
                      class="pet-profile"
                      v-if="scope.row.pet.image != {}"
                      :src="scope.row.pet.image.small"
                    />
                    <img
                      class="pet-profile"
                      v-else
                      src="@/assets/icons/icon-camera-plus.png"
                    />
                    <span class="pet-name" style="margin-left: 10px">{{
                      scope.row.pet.name
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="Type" width="90">
                  <template slot-scope="scope">
                    <img
                      class="pet-type"
                      v-if="scope.row.pet.type.key == 'DOG'"
                      src="@/assets/icons/icon-dog.png"
                    />
                    <img
                      class="pet-type"
                      v-if="scope.row.pet.type.key == 'CAT'"
                      src="@/assets/icons/icon-cat.png"
                    />
                    <span
                      v-if="
                        scope.row.pet.type.key != 'DOG' &&
                          scope.row.pet.type.key != 'CAT'
                      "
                      >{{ scope.row.pet.type.name.split("/")[0] }}</span
                    >
                    <img
                      class="gender"
                      v-if="scope.row.pet.gender == 1"
                      src="@/assets/icons/icon-male-white-alt.png"
                    />
                    <img
                      class="gender"
                      v-else
                      src="@/assets/icons/icon-female-white-alt.png"
                    />
                  </template>
                </el-table-column>
                <el-table-column label="Breeds" width="180">
                  <template slot-scope="scope">
                    <template v-if="scope.row.pet.breed">{{
                      scope.row.pet.breed.name
                    }}</template>
                    <template v-else>ไม่ระบุ</template>
                  </template>
                </el-table-column>
                <el-table-column label align="right">
                  <template slot-scope="scope">
                    <template>
                      <div class="flex">
                        <router-link
                          :to="{
                            name: 'hospitalPetProfile',
                            params: { id: scope.row.pet_hospital.id },
                          }"
                        >
                          <el-button type="default" class="light-blue-button2"
                            >SEE DETAIL</el-button
                          >
                        </router-link>

                        <button
                          class="btn"
                          style="padding: 0.5rem; margin-left: 0.1rem;"
                          @click="selectedPetProfile = scope.row"
                          v-if="scope.row.pet.owners.length >= 2"
                        >
                          <i class="fa fa-trash-alt"></i>
                        </button>
                      </div>
                    </template>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="no-item" v-else>No pet.</div>
          </el-tab-pane>
          <el-tab-pane
            label="Message"
            name="message"
            style="padding-bottom: 10px;"
          >
            <OwnerMessageTimeline
              :ownerId="$route.params.id"
              v-if="isMessageLoaded && owner"
            />
          </el-tab-pane>
          <el-tab-pane
            label="Expense History"
            name="expense"
            v-if="currentPlan.income_expense_section.can_view_finance_history">
            <Expense>
              <ExpenseOverview
                slot="overview"
                :revenue="expenseOverview.revenue"
                :deposit="expenseOverview.deposit"
                :overdue="expenseOverview.overdue"
              />
              <ExpenseTabs active-name="summary">
                <ExpenseTab label="Summary" name="summary">
                  <ExpenseSummary :items="expenseSummaryList" />
                </ExpenseTab>
                <ExpenseTab label="History" name="history">
                  <ExpenseHistory
                    :items="expenseHistory.items"
                    :total="expenseHistory.total"
                    @pageChange="handleExpenseResultPageUpdate"
                    @view="handleExpenseResultView"
                  />
                </ExpenseTab>
              </ExpenseTabs>
            </Expense>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>

    <ConfirmDialog
      v-if="owner && currentHospital"
      :visible="isShowConfirmDeleteDialog"
      @cancel="isShowConfirmDeleteDialog = false"
      @confirm="handleUnlinkOwner"
    >
      <template slot="header">
        <h1 class="title" :style="{ color: '#000' }">
          ยืนยันการลบ "{{ owner.profile.firstname }} {{ owner.profile.lastname }}" <br />ออกจาก "{{
            currentHospital.name
          }}"
        </h1>
      </template>
      <template slot="content">
        <div :style="{ display: 'inline-block', textAlign: 'center' }">
          <p :style="{ fontSize: '14px', textAlign: 'left' }">
            เมื่อยืนยันการลบเจ้าของท่านนี้ออกแล้ว จะไม่สามารถออกใบเสร็จ<br>
            ภายใต้ชื่อเจ้าของท่านี้ได้อีก และ สัตว์ที่ผูกอยู่ (ถ้าไม่มีเจ้าของท่านอื่นอยู่)<br>
            จะไม่สามารถทำรายการต่าง ๆ ที่ต้องการเจ้าของลงไปได้
          </p>
        </div>
      </template>
    </ConfirmDialog>

    <ConfirmDialog
      v-if="owner && currentHospital"
      :visible="!!selectedPetProfile"
      @cancel="selectedPetProfile = null"
      @confirm="handleDeletePet"
    >
      <template slot="header">
        <h1 class="title" :style="{ color: '#000' }">
          ยืนยันการลบ "{{ selectedPetProfile && selectedPetProfile.pet.name }}" <br />
          ออกจาก "{{ owner.profile.firstname }} {{ owner.profile.lastname }}"
        </h1>
      </template>
      <template slot="content">
        <div :style="{ display: 'inline-block', textAlign: 'center' }">
          <p :style="{ fontSize: '14px', textAlign: 'left' }">
            เมื่อยืนยันการลบสัตว์เลี้ยงออกแล้ว<br />สัตว์เลี้ยงจะไม่ถูกผูกกับเจ้าของท่านนี้อีก
          </p>
        </div>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store";
import ContentBox from "@/components/container/ContentBox";
import Subnavbar from "@/components/navigation/Subnavbar";
import Avatar from "@/components/avatar/Avatar";
import PetOwnerDetail from "@/components/pet/PetOwnerDetail";
import PetOwnerAddress from "@/components/pet/PetOwnerAddress";
import PetNote from "@/components/pet/PetNote";
import PetAppointmentTimeline from "@/components/appointment/PetAppointmentTimeline";
import OwnerMessageTimeline from "@/components/message/OwnerMessageTimeline";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import {
  Expense,
  ExpenseOverview,
  ExpenseTabs,
  ExpenseTab,
  ExpenseSummary,
  ExpenseHistory,
} from "./components/expense";

const fetchExpenseHistory = async (id, query) => {
  try {
    store.dispatch("ui/setIsFullScreenLoading", true);

    const result = await store.dispatch('owner/getExpenseHistory', {
      params: { id: id },
      query: query,
    });

    store.dispatch("ui/setIsFullScreenLoading", false);

    const { results, count } = result;

    return {
      items: results.map((item) => ({
        date: new Date(item.datetime),
        type: item.type_name,
        revenue: item._amount,
        refId: item.receipt,
      })),
      total: count,
    };
  } catch (e) {
    store.dispatch("ui/showFetchErrorWithReloadMsg");
    throw e;
  }
};

const fetchExpenseOverview = async (id) => {
  try {
    store.dispatch("ui/setIsFullScreenLoading", true);

    const result = await store.dispatch('owner/getExpenseOverview', {
      params: { id: id },
    });

    store.dispatch("ui/setIsFullScreenLoading", false);

    return {
      revenue: +result.total_income,
      overdue: +result.overdue,
      deposit: +result.deposit,
    };
  } catch (e) {
    store.dispatch("ui/showFetchErrorWithReloadMsg");
    throw e;
  }
};

export default {
  components: {
    Avatar,
    ContentBox,
    Subnavbar,
    PetOwnerDetail,
    PetOwnerAddress,
    PetNote,
    PetAppointmentTimeline,
    OwnerMessageTimeline,
    Expense,
    ExpenseOverview,
    ExpenseTabs,
    ExpenseTab,
    ExpenseSummary,
    ExpenseHistory,
    ConfirmDialog,
  },
  data() {
    return {
      pets: [],
      addresses: [],
      expenseSummaryList: [],
      expenseOverview: {
        revenue: 0,
        deposit: 0,
        overdue: 0,
      },
      expenseHistory: {
        items: [],
        total: 0
      },
      isShowProfile: true,
      activeName: "",
      showConfirmDialog: false,
      isShowConfirmDeleteDialog: false,
      isAppointmentLoaded: false,
      isMessageLoaded: false,
      selectedPetProfile: null,
    };
  },
  computed: {
    ...mapState({
      owner: (state) => state.owner.selected,
      currentHospital: (state) => state.hospital.profile,
      currentPlan: (state) => state.plan.currentPlan,
    }),
  },
  methods: {
    async handleExpenseResultPageUpdate(page) {
      const id = this.$route.params.id;
      const result = await fetchExpenseHistory(id, { page: page });
      this.expenseHistory.items = [
        ...this.expenseHistory.items,
        ...result.items
      ]
    },

    handleExpenseResultView(receiptID) {
      this.$router.push({
        name: "income-expense-receipt-detail",
        params: { id: receiptID },
      });
    },

    async handleUnlinkOwner() {
      const id = this.$route.params.id;
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("owner/unlinkProfile", {
          params: { id: id },
        });
        this.$router.back();
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    },
    async handleDeletePet() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("owner/deletePetFromOwner", {
          params: { id, petId: this.selectedPetProfile.pet.id },
        });
        this.selectedPetProfile = null;
        this.pets = await this.$store.dispatch("owner/listPetsByOwnerId", {
          params: { id: this.owner.id },
        });

        this.$notify({
          type: "success",
          title: "ลบสัตว์เลี้ยงเรียบร้อยแล้ว",
        });
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
  },

  async created() {
    this.activeName = "appointment";
    const { tab } = this.$route.query;
    if (tab) this.activeName = tab;

    try {
      const id = this.$route.params.id;
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      const owner = await this.$store.dispatch("owner/getOwnerProfileById", {
        params: { id: id },
      });

      const pets = await this.$store.dispatch("owner/listPetsByOwnerId", {
        params: { id: owner.id },
      });

      const yearlyIncomes = await this.$store.dispatch("owner/getExpenseYearly", {
        params: { id: id },
      });
      this.expenseSummaryList = yearlyIncomes.map((item) => ({
        year: item.year,
        revenue: item.amount_sum
      }));
      this.expenseOverview = await fetchExpenseOverview(id);
      this.expenseHistory = await fetchExpenseHistory(id, { page: 1 });

      this.addresses = this.owner.profile.addresses;
      this.pets = pets;
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    } catch (error) {
      await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
    }
  },
  watch: {
    activeName(newValue) {
      this.$router.replace({ query: { ...this.$route.query, tab: newValue } });
      if (newValue === "message") {
        this.isMessageLoaded = true;
      } else if (newValue === "appointment") {
        this.isAppointmentLoaded = true;
      }
    },
  },
};
</script>

<style lang="scss">
.owner-profile {
  .header-section {
    text-align: center;
    .name {
      margin: 5px 0;
    }
  }
  .owner-profile-info {
    .form-row {
      margin-bottom: 5px;
    }
    .label {
      font-size: 0.8em;
      color: #aaa;
    }
  }
}
.pet-list {
  .title {
    margin: 0;
    padding-bottom: 10px;
    text-align: center;
    font-size: 1.1em;
  }
  .add-new-pet-button {
    cursor: pointer;
    padding-top: 5px;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    font-size: 0.8em;
    line-height: 1.4em;
    vertical-align: middle;
    font-weight: bold;
    text-align: center;
    background: #ccc;
    border: none;
    outline: none;
  }
  .pet-list-section {
    text-align: center;
    padding: 15px 0;
    border-top: 1px solid #ccc;
  }

  .pet-info {
    cursor: pointer;
    margin: 5px 0 15px;
    .name {
      font-size: 1.2em;
    }
    .type-breed {
      font-size: 0.8em;
      color: #aaa;
    }
  }
}
</style>

<style lang="scss" scoped>
img.gender {
  margin-left: 4px;
  width: 18px;
  height: 18px;
  object-fit: contain;
}

img.pet-type {
  width: 27px;
  height: 27px;
  object-fit: contain;
}

img.pet-profile {
  display: inline;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
  border: solid 1px #979797;
}

.el-table {
  img {
    display: inline;
    vertical-align: middle;
  }

  .el-button {
    height: 40px;
    border-radius: 4px;

    &.light-blue-button {
      width: 40px;
      height: 40px;
      padding: 0px;
      border-radius: 4px;
      &:not(:hover) {
        background-color: #c2e5f0;
        border: none;
      }
    }
  }

  .pet-name {
    font-family: default;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #1890ff;
  }
}
</style>

<style lang="scss">
.owner-detail {
  @include mobile {
    .el-tabs__item {
      padding: 0px;

      font-family: default;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8e8e93;

      &.is-active {
        color: #000000;
      }
    }

    .el-tabs__active-bar {
      margin-left: -5%;
      width: 55% !important;
      height: 3px;
    }

    .el-tabs__nav-wrap::after {
      background: none;
    }

    .el-table {
      th {
        background-color: #f5f8fa;
      }
    }

    .hide-mobile {
      display: none;
    }
  }

  .hide-tab {
    .el-tabs__header {
      display: none;
    }
  }

  th {
    background: #f5f8fa;
  }

  .light-blue-button2 {
    font-family: default;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    color: #156f88;
    padding: 0.5rem;

    border-radius: 4px;
    border: solid 1px #156db1;
  }

  .el-button--danger.is-disabled {
    background: #cfcfcf;
    border-color: #cfcfcf;
  }

  // .el-table {
  //   margin-bottom: 10px;
  // }
}
</style>
