<template>
  <div :class="`subnavbar clearfix ${ mini ? 'mini':'' }`">
    <router-link :to="{ name: back }" v-if="back">
      <el-button class="back" type="text" style="position: absolute; z-index: 8000;"><span class="backward">&lt; Back</span></el-button>
    </router-link>
    <div class="left-col">
      <div>
        <el-dropdown @command="handleDropdownCommand">
          <span class="el-dropdown-link" @click="$router.push({ name: user_type == 'hospital_admin' ? 'hospitalHome' : 'petOwnerHome' })">
            Home
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <template v-if="user_type == 'hospital_admin'">
              <el-dropdown-item command="appointments">Appointment</el-dropdown-item>
              <el-dropdown-item command="pets">Pet</el-dropdown-item>
            </template>
            <template v-else>
            </template>
          </el-dropdown-menu>
        </el-dropdown>
        <span class="breadcrumb-slot">
          <slot name="breadcrumb"></slot>
        </span>
      </div>
      <div>
        <h1 class="title">
          <slot name="title"></slot>
        </h1>
      </div>
    </div>

    <div class="line" v-if="hasLine"></div>

    <div class="right-col">
      <slot v-if="hasActionsSlot" name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasLine: {
      default: false,
      type: Boolean
    },
    back: {
      default: null,
      type: String
    },
    mini: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    handleDropdownCommand(command) {
      switch (command) {
        case "appointments":
          this.$router.push({ name: 'appointments' })
          break
        case "pets":
          this.$router.push({ name: 'hospitalPets' })
          break
      }
    }
  },
  computed: {
    hasActionsSlot() {
      return !!this.$slots.actions
    },
    user_type: function () {
      return localStorage.getItem('user_type');
    }
  }
}
</script>

<style lang="scss" scoped>
.subnavbar {
  position: fixed;
  top: 55px;
  left: 0;
  right: 0;
  width: 100%;
  height: 80px;
  background: #fff;
  border-bottom: 1px solid #ddd;
  z-index: 98;

  @include mobile {
    &.mini {
      height: 60px;

      .back {
        padding: 20px 20px;
      }
    }
  }

  .back {
    float: left;
    padding: 30px 20px;
    display: none;

    @include mobile {
      display: inline-block;
    }

    .backward {
      font-family: default;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8e8e93;

    }
  }

  .left-col {
    float: left;
    padding: 10px 20px;
  }

  .right-col {
    float: right;
    text-align: right;
    padding: 15px 20px;
  }

  @include mobile {

    .line, .left-col {
      display: none;
    }

    .right-col {
      position: absolute;
      padding-left: 0px;
      padding-right: 0px;
      width: 100%;
      text-align: center;
    }

    position: absolute;
  }

  @include tablet {
    position: absolute;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .title {
    padding: 0;
    margin: 5px 0 0 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.4em;
    color:rgba(0, 0, 0, 1);
    text-transform: uppercase;
    -webkit-font-smoothing:never;
    font-family: $kanit;
  }

  .breadcrumb-slot {
    font-size: 14px;
    color: #666;

    > ul {
      display: inline-block;
      margin: 0;
      padding: 0;
      list-style-type: none;

      > li {
        display: inline-block;

        &::before {
          content: "/";
          margin: 0 10px;
        }
      }
    }
  }

  .line {
    position: absolute;
    left: 50%;
    margin-top: 9px;
    width: 1px;
    height: 60px;
    background: #979797;
  }
}
</style>
