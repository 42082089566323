<template>
  <div>
    <div class="head-button">
      <button
        
        :class="{ 'button-head-medical': true, 'active': (headerTopic === 0) }"
        @click="changeHeader(0)"
      >History/P.E./Diag/Treatment</button>
      <button
        :class="{ 'button-head-medical': true, 'active': (headerTopic === 1) }"
        @click="changeHeader(1)"
      >Lab/Image</button>
      <button
        :class="{ 'button-head-medical': true, 'active': (headerTopic === 2) }"
        @click="changeHeader(2)"
      >Activity record</button>
    </div>
    <el-row>
      <el-col :md="24">
        <div class="title-header">{{medicalAddTitle[headerTopic]}}</div>
        <div v-if="headerTopic === 0">
          <MedicalPO
            v-if="renderComponent"
            :pet="pet" 
            :modalData="modalData" 
            :modalPic="modalPic" 
            :POData="POData" 
            :ipd="ipd"
            :progress_status="progress_status"
            :can-edit-data="canEditData"
          />
        </div>
        <div v-if="headerTopic === 1">
          <MedicalLab 
            :pet="pet" 
            :modalData="modalData" 
            :modalPic="modalPic" 
            :LabData="labexam" 
            :progress_status="progress_status"
            :can-edit-data="canEditData"
          />
        </div>
        <div v-if="headerTopic === 2">
          <MedicalAddActivity 
            :pet="pet" 
            :pos="pos" 
            :progress_status="progress_status" 
            :currentStep="currentStep" 
            :ipd="ipd" 
            :ipdStatus="ipdStatus" 
            :actors="actors" 
            :discardable="discardable"
            :can-edit-data="canEditData"
            :can-edit-price-and-stock-amount="canEditPriceAndStockAmount"
          />
        </div>
      </el-col>
    </el-row>
    <modal class="create-modal" v-bind:show="modalData.showModal">
      <span class="title">{{modalData.modalTitle}}</span>
      <span class="input-modal">
        <el-input placeholder="Type here..." size="small" v-model="modalData.modalInput"></el-input>
      </span>
      <el-button class="btn" @click="modalData.showModal=false">Cancel</el-button>
      <el-button
        v-bind:show="modalData.showCreateButton"
        class="btn btn-primary"
        @click="createModal"
      >Create</el-button>
    </modal>
    <PicModal style="z-index: 100;" v-bind:show="modalPic.showModal" v-on:closeModal="modalPic.showModal = false">
      <img :src="modalPic.img.attachmentPreview" class="image image-size" />
      <div class="left-modal" @click="leftModalPic"><img src="@/assets/icons/icon-arrow.png" class="arrow reverse-arrow"></div>
      <div class="right-modal" @click="rightModalPic"><img src="@/assets/icons/icon-arrow.png" class="arrow"></div>
      <div v-if="!modalPic.noTag" class="bottom-modal">
        <div class="bottom-modal-tag">
          <div class="tag-box" v-for="item in modalPic.img.tags" :key="item.name">
            {{item.name}}
          </div>
        </div>
      </div>
    </PicModal>
  </div>
</template>

<script>
import MedicalPO from "@/components/medical/MedicalAddPO"
import MedicalLab from "@/components/medical/MedicalAddLab"
import MedicalAddActivity from "@/components/medical/MedicalAddActivity"
import Modal from "@/components/alert/Modal"
import PicModal from "@/components/medical/common/PicModal"

export default {
  props: [
    "pet", 
    "POData", 
    "labexam", 
    "pos", 
    "progress_status",
    "currentStep", 
    "force_restore", 
    "ipd", 
    "ipdStatus", 
    "actors", 
    "discardable",
    "canEditData",
    "canEditPriceAndStockAmount"
  ],
  components: {
    Modal,
    PicModal,
    MedicalPO,
    MedicalLab,
    MedicalAddActivity
  },
  data() {
    return {
      modalData: {
        modalType: 0,
        showModal: false,
        showCreateButton: true,
        modalTitle: "",
        modalInput: ""
      },
      modalPic: {
        noTag: false,
        showModal: false,
        allImgs: [],
        index: 0,
        img: {
          attachmentPreview: "",
          tags: [],
          description: ""
        }
      },
      headerTopic: 0,
      renderComponent: true,
      medicalAddTitle: ["Problem Oriented", "Lab & Image", "Activity Record"]
    }
  },
  methods: {
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },

    leftModalPic() {
      if (this.modalPic.index !== 0) {
        this.modalPic.index--
        this.modalPic.img = this.modalPic.allImgs[this.modalPic.index]
      }
    },
    rightModalPic() {
      if (this.modalPic.index < (this.modalPic.allImgs.length - 1)) {
        this.modalPic.index++
        this.modalPic.img = this.modalPic.allImgs[this.modalPic.index]
      }
    },
    changeHeader(number) {
      this.headerTopic = number
    },
    async createModal() {
      try {
        if (this.modalData.modalType === 0 && this.modalData.modalInput) {
          let newFoodtype = await this.$store.dispatch(
            "medical/createFoodtype",
            { data: { name: this.modalData.modalInput } }
          )
          if (newFoodtype)
            this.$store.commit("medical/addFoodtype", newFoodtype)
        } else if (
          this.modalData.modalType === 1 &&
          this.modalData.modalInput
        ) {
          let newDiagnosis = await this.$store.dispatch(
            "medical/createDiagnosis",
            { data: { name: this.modalData.modalInput } }
          )
          if (newDiagnosis)
            this.$store.commit("medical/addDiagnosis", newDiagnosis)
        } else if (
          this.modalData.modalType === 2 &&
          this.modalData.modalInput
        ) {
          let newProblem = await this.$store.dispatch("medical/createProblem", {
            data: { name: this.modalData.modalInput }
          })
          if (newProblem) this.$store.commit("medical/addProblem", newProblem)
        } else if (
          this.modalData.modalType === 3 &&
          this.modalData.modalInput
        ) {
          // let newTag = await this.$store.dispatch("medical/createTag", {
          await this.$store.dispatch("medical/createTag", {
            data: { name: this.modalData.modalInput }
          })
        }
        this.modalData.showModal = false
        this.modalData.modalTitle = ""
        this.modalData.modalInput = ""
      } catch (e) {
        this.modalData.modalTitle = "Error for Create"
        this.modalData.showCreateButton = false
        this.modalData.modalInput = ""
      }
    }
  },
  watch: {
    force_restore() {
      this.headerTopic = 0;
      this.forceRerender();
    }
  },
}
</script>

<style lang="scss" scoped>
.head-button {
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;

  button {
    cursor: pointer;
  }
  button[disabled] {
      cursor: default;
      background: #cbcdd4;
      &:hover {
        color: #4a4a4a;
        background: #cbcdd4;
      }
    }
}
.button-head-medical {
  width: 33%;
  height: 65px;
  border-radius: 4px;
  background-color: #cbcdd4;

  font-family: Avenir;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  color: #4a4a4a;
  &:hover,
  &.active {
    background-color: #324a5e;
    color: #f5f8fa;
  }
}
.title-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 33px;
  border-radius: 4px;
  border: solid 1px #e6eaee;
  background-image: linear-gradient(to top, #324a5e, #324a5e);

  font-family: "Kanit", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  color: #ffffff;
}

.title {
  font-family: Arial;
  font-size: 18px;
  font-weight: bold;
  color: #4a4a4a;
  span {
    font-family: Arial;
    font-size: 12px;
    color: #9b9b9b;
  }
}

.image {
  width: 100%;
  display: block;
}
.image-size {
  width: 800px;
  height: auto;
  -moz-transform:scale(1);
  -webkit-transform:scale(1);
  transform:scale(1);
}
.bottom-modal {
  width: 100%;
  height: 50px;
  background-color: #363636;
  position: relative;
  margin-top: -50px;
  opacity: 0.5;
}
.left-modal {
  height: 95%;
  position: absolute;
  top: 5%;
  left: 1%;
  display: flex;
  align-items: center;
}
.right-modal {
  height: 95%;
  position: absolute;
  top: 5%;
  right: 1%;
  display: flex;
  align-items: center;
}
.arrow {
  height: 30px;
  width: 20px;
  cursor: pointer;
}
.reverse-arrow {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.bottom-modal-tag {
  display: flex;
  justify-content: center;
}
.tag-box {
  border-radius: 4px;
  border: solid 1px #cfcfcf;
  margin: 10px 5px;
  padding: 2px 5px;
  background-color: #cfcfcf;
}
</style>
