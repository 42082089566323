<template>
  <ContentBox>
    <div>
      <span class="total">{{ total }}</span> RESULT
    </div>
    <table class="request-appointment-table">
      <thead>
        <tr>
          <th class="status">&nbsp;</th>
          <th class="currently-action">วันที่สร้าง</th>
          <th class="date-of-appointment">วันที่ขอนัดหมาย</th>
          <th class="pet">วันที่ขอนัดหมาย</th>
          <th class="note">วัตถุประสงค์การนัดหมาย</th>
          <th class="owner">เจ้าของ</th>
          <th class="channal">ช่องทาง</th>
          <th class="action">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(it, id) in itemsRequestApp" :key="id">
          <td>
            <div
              style="width: 80px; height: 28px"
              :class="{ tag: true, 'tag-vertical': true, [it.status]: true }"
            >
              {{ it.status }}
            </div>
          </td>
          <td>
            <div>{{ it.created | toThaiDate }}</div>
            <div class="subtitle">{{ it.created | toHHMM }}</div>
          </td>
          <td>
            <div>{{ it.date_time | toThaiDate }}</div>
            <div class="subtitle">{{ it.date_time | toHHMM }}</div>
          </td>
          <td>
            <div>
              <img
                v-if="it.pet.image"
                :src="it.pet.image.medium"
                alt="Status icon"
                :style="{
                  display: 'inline-block',
                  height: '35px',
                  marginRight: '6px',
                  marginBottom: '12px',
                  marginTop: '10px',
                }"
              />
              <div class="info">
                <div class="name">
                  <strong>{{ it.pet.name }}</strong>
                </div>
                <div class="subtitle">{{ it.pet.type.name }}</div>
              </div>
            </div>
          </td>
          <td>
            <div>{{ it.note }}</div>
          </td>
          <td>
            <div>
              {{ it.owner.profile.firstname }} {{ it.owner.profile.lastname }}
            </div>
            <div class="subtitle">{{ it.owner.phone }}</div>
          </td>
          <td>
            <span v-if="!checkChannel(it.channel)"></span>
            <img
              v-else
              :src="checkChannel(it.channel)"
              alt="Status icon"
              :style="{
                display: 'inline-block',
                height: '35px',
                marginRight: '6px',
                marginBottom: '12px',
                marginTop: '15px',
              }"
            />
          </td>
          <th>
            <el-button
              size="mini"
              @click="
                $router.push({
                  name: 'requestAppointmentDetail',
                  params: { id: it.id },
                })
              "
            >
              See Detail
              <i class="el-icon-arrow-right el-icon-right"></i>
            </el-button>
          </th>
        </tr>
      </tbody>
    </table>
    <el-row class="mt-4" type="flex" justify="center">
      <el-col align="center">
      
        <el-pagination
          class="pagination"
          background
          layout="prev, pager, next"
          :current-page="currentPage"
          :page-size="pageSize"
          :total="total"
          @current-change="(page) => {
            currentPage = page
          }"
        ></el-pagination>
      </el-col>
    </el-row>
  </ContentBox>
</template>

<script>
import ContentBox from "@/components/container/ContentBox.vue";
export default {
  name: "request-appointment-table",
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ContentBox,
  },
  data() {
    return {
      itemsRequestApp: [],
      pageSize: 10,
      total: 0,
      currentPage: 1,
    };
  },
  watch: {
    currentPage(val) {
      if (val) this.fetchData();
    },
    filter(val) {
      if (val) this.fetchData();
    },
  },

  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);

        const { results, count } = await this.$store.dispatch(
          "appointment/getListRequestAppointment",
          {
            query: {
              page: this.currentPage,
              ...this.filter,
            },
          }
        );
        this.itemsRequestApp = results;

        this.total = count;
        this.$forceUpdate();
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
      } finally {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      }
    },
    checkChannel(channel) {
      let result = null;
      switch (channel.toLowerCase()) {
        case "facebook":
          result = require('@/assets/icons/facebook.png');
          break;
        case "line":
          result = require("@/assets/icons/line.png");
          break;
        case "sms":
          result = require("@/assets/icons/sms.png");
          break;
        case "instagram":
          result = require("@/assets/icons/instagram.png");
          break;
        case "email":
          result = require("@/assets/icons/gmail.png");
          break;
        case "website":
          result = require("@/assets/icons/website.png");
          break;
        case "itaam-website":
          result = require("@/assets/icons/itaam-website.png");
          break;
        case "hospital-website":
          result = require("@/assets/icons/hospital-website.png");
          break;

        default:
          result = null;
          break;
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.request-appointment-table {
  width: 100%;
  border-collapse: collapse;

  a {
    color: #333;
    text-decoration: none;
    &:hover {
      color: #02b0e1;
    }
  }

  thead {
    tr {
      border: #e6eaee 1px solid;

      th {
        padding: 5px;
        font-weight: normal;
        color: #7f8fa4;
        background-color: #f5f8fa;
        text-align: left;
        font-size: 0.9em;

        &.status {
          width: 30px;
        }

        &.currently-action {
          width: 140px;
        }

        &.date-of-appointment {
          width: 140px;
        }

        &.pet {
          width: 190px;
        }
        &.note {
          width: 190px;
        }
        &.owner {
          width: 150px;
        }
        &.channal {
          width: 90px;
        }

        &.action {
          width: 100px;
        }
      }
    }
  }

  tbody {
    tr {
      height: 82px;
      box-sizing: content-box;
      td {
        position: relative;
        padding: 5px;
        font-size: 0.9em;

        .subtitle {
          color: #666;
        }

        .thumbnail {
          display: inline-block;
          width: 50px;
          height: 50px;
          overflow: hidden;
          background: #eee;
          border-radius: 5px;
          margin-right: 10px;

          > img {
            width: 50px;
            height: 50px;
            border-radius: 5px;
          }
        }

        .info {
          display: inline-block;
          width: calc(100% - 95px);
          vertical-align: top;
          margin-top: 10px;
        }

        .name,
        .subtitle {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .blankLeft {
          margin-left: 34px;
        }
      }
    }
  }

  .cancel {
    background-color: #e6eaee;
    color: #fff;
  }
  .accept {
    background-color: #5b9f11;
    color: #fff;
  }
  .pending {
    background-color: #ff8800;
    color: #fff;
  }
}
</style>