<template>
  <div>
      <div v-if="this.layout === 'vertical'">
          <div>
            <label>
                <slot name="label" :label="this.label">
                    {{ label }}
                </slot>
            </label>
          </div>
          <el-row type="flex" :gutter="8" :style="{ alignItems: 'baseline' }" >
              <el-col :span="null"
                  :style="{ flex: '1', fontWeight: 'bold', fontSize: '1.25em' }">
                  <slot  :value="this.value" :format="this.format" >
                      {{ getValue(value,format) }}
                  </slot>
                </el-col>
                <el-col :span="null">
                  <slot  name="unit"  :unit="this.unit">
                    {{ unit }}
                </slot>
                </el-col>
          </el-row>
      </div>

      <div v-if="this.layout === 'horizontal'">
        <el-row type="flex" :gutter="8" :style="{ alignItems: 'baseline' }">
            <el-col :span="null" :style="{ flex: '1' }">
                <label>
                    <slot name="label" :label="this.label">
                        {{ label }}
                    </slot>
                </label>
            </el-col>
            <el-col :span="null" :style="{ textAlign: 'right' }">
              <div :class="getClass">
                 <slot  :value="this.value" :format="this.format" >
                      {{ getValue(value,format) }}
                  </slot>
              </div>
              <div>
                <slot  :unit="this.unit">
                    {{ unit }}
                </slot>
              </div>
            </el-col>
          </el-row>
      </div>

      <div v-if="this.layout === 'row'">
        <el-row type="flex" :gutter="8" :style="{ alignItems: 'baseline' }">
            <el-col :span="null" :style="{ flex: '1' }">
                <label>
                    <slot name="label" :label="this.label">
                        {{ label }}
                    </slot>
                </label>
            </el-col>
            <el-col :span="null" :style="{ textAlign: 'right' }">
              <div :class="getClass">
                <slot :value="this.value" :format="this.format" >
                      {{ getValue(value,format) }}
                  </slot>
              </div>
            </el-col>
            <el-col :span="null">
               <slot  :unit="this.unit">
                    {{ unit }}
                </slot>
            </el-col>
          </el-row>
      </div>
  </div>
</template>

<script>
import numeral from "numeral";

export default {
  props: {
    label: {
      type: String,
      default: "",
    },

    value: {
      type: Number,
      default: 0,
    },

    unit: {
      type: String,
      default: "",
    },

    format: {
      type: String,
      default: "0,0",
    },

    layout: {
      type: String,
      default: "horizontal",
      validator: (value) => ["horizontal", "vertical", "row"].indexOf(value) !== -1,
    },

    useExtraStyle: {
      type: Boolean,
      default: false
    }
  },
  methods : {
      getClass() {
        return (this.useExtraStyle) ? '' : this.$style.value
      },
      getValue(vl,fmt) {
        const value = numeral(vl).format(fmt).toString();
        return value
      },
  },
}
</script>

<style lang="scss" module>
  .value {
    font-weight: bold;
    font-size: 1.25em;
  }
</style>
