<template>
  <el-container>
    <VetConfirmModal
      v-bind:isShow="isConfirmModalShow"
      :vet="selectedVet"
      v-bind:onSubmit="onNextClicked"
      v-bind:onCancel="() => isConfirmModalShow = false"
    ></VetConfirmModal>

    <router-view
      v-if="donation"
      ref="stepForm"
      :mode="mode"
      :donation="donation"
      @save="validateForm()"
    >
      <template slot="header" slot-scope="{ currentProgress, currentText }">
        <!-- Sub Navbar -->
        <Subnavbar>
          <template slot="breadcrumb">
            <ul>
              <li>Donation Profile</li>
            </ul>
          </template>
          <template slot="title">{{ titleText || currentText }}</template>
          <template slot="actions" class="action-wrapper">
            <!-- <el-button type="text" disabled>วันที่ {{ getCurrentDate }} เวลา {{ getCurrentTime }}</el-button> -->
            <el-button
              type="text"
              v-if="profile.id === donation.hospitalId && !$route.name.includes('Timeline')"
            >
              <label class="btn-large error-field-label">ผู้ตรวจ *</label>
              <el-select
                :disabled="donation.isCaseClosed()"
                v-model="selectedVetID"
                size="large"
                style=" width: 300px;"
                placeholder="กรุณาเลือก"
              >
                <el-option
                  :key="idx"
                  :label="itm.title + ' ' + itm.first_name + ' ' + itm.last_name"
                  :value="itm.id"
                  v-for="(itm, idx) in vets"
                ></el-option>
              </el-select>
            </el-button>
            <span v-if="!$route.name.includes('Timeline')">
              <el-button
                v-if="!donation.isCaseClosed()"
                class="btn btn-large btn-new-pet"
                @click="validateForm()"
              >
                <i class="fa fa-save"></i>Save
              </el-button>
              <span v-else>
                <el-button plain class="btn-large" icon="fa fa-list" @click="changePage('donationTimeline')">Timeline</el-button>
                <el-button
                  class="btn-large"
                  type="primary"
                  icon="fa fa-chevron-left"
                  v-if="getCurrentPage>0"
                  @click="changePage('previous')"
                >Back</el-button>
                <el-button
                  class="btn-large"
                  type="primary"
                  :disabled="!canGoNext"
                  @click="changePage('next')"
                >
                  Next
                  <i class="fa fa-chevron-right"></i>
                </el-button>
              </span>
            </span>
            <span v-else>
              <el-button
                plain
                class="btn-large"
                icon="fa fa-chevron-left"
                @click="$router.push({ name: 'donation'})"
              >Back</el-button>
              <el-button
                plain
                class="btn-large btn-danger"
                icon="fa fa-times"
                v-if="!donation.isBloodCollectSubmitted&&!donation.isTicketRejected"
                @click="rejectTicket()"
              >Cancel Donation</el-button>
            </span>
          </template>
        </Subnavbar>

        <!-- Progress Step -->
        <el-steps
          v-if="donation && currentProgress > 0"
          :active="currentProgress"
          finish-status="info"
        >
          <el-step v-for="p in donation.getProgressList()" :key="p.title" :title="p.title"></el-step>
        </el-steps>

        <el-row>
          <BloodDonationProfileHeader :mode="mode" :donation="donation"></BloodDonationProfileHeader>
        </el-row>
      </template>
    </router-view>
  </el-container>
</template>


<script>
import { mapState } from "vuex";
import _ from "lodash";
// import moment from "moment";
import Subnavbar from "@/components/navigation/Subnavbar";
import BloodDonationProfileHeader from "@/components/bloodbank/blood-donate/BloodDonationProfileHeader";
import PetInfo from "@/models/data/common/pets-info/pet-info/pet-info";
import BloodDonationInfo from "@/models/data/blood-donate/blood-donate-info/blood-donate-info";
import VetConfirmModal from "@/components/bloodbank/controls/vet-confirm-modal/VetConfirmModal.vue";
import BloodBagHistoryInfo from "@/models/data/blood-storage/blood-bag-history-info/blood-bag-history-info";
import ContentTypeEnums from "@/constants/bloodbank/content-type.js";
import BloodCollectionHistoryActionEnums from "@/constants/bloodbank/blood-collect-history-action.js";

export default {
  components: {
    Subnavbar,
    BloodDonationProfileHeader,
    VetConfirmModal
  },
  props: {
    mode: {
      default: "view"
    }
  },
  data() {
    return {
      donation: null,
      isConfirmModalShow: false,
      pageList: [
        "donationExam",
        "donationLab",
        "donationCare",
        "donationCollect"
      ],
      contentTypeEnums: ContentTypeEnums,
      bloodCollectionHistoryActionEnums: BloodCollectionHistoryActionEnums
    };
  },
  computed: {
    ...mapState({
      donations: state => state.bloodbank.donations,
      vets: state => state.hospital.vets,
      profile: state => state.hospital.profile,
      selectedPet: state => state.pet.selected
    }),
    titleText() {
      return this.mode === 'view' ? `Donation Profile / ID: ${this.donation.bloodDonationId} ${ this.$route.name.includes('Timeline') ? ('/ '+this.statusText) : ''}` : ''
    },
    statusText() {
      if (this.donation.donorCareInfo.canDonate === false) {
        return 'Failed Case'
      } else if (this.donation.isTicketRejected) {
        return 'Cancelled'
      } else if (this.donation.isBloodCollectSubmitted) {
        return 'Done'
      } else if (this.donation.isDonorCareSubmitted) {
        return 'Donor care'
      } else if (this.donation.isLabExamSubmitted) {
        return 'Lab'
      } else if (this.donation.isPhysicalExamSubmitted) {
        return 'Donor Submit'
      } else if (this.donation.isDonorProfileSubmitted) {
        return 'Donor Submit'
      }
    },
    // getCurrentDate: function() {
    //   var currentDate = moment().format("DD-MM-YYYY");
    //   return currentDate;
    // },
    // getCurrentTime: function() {
    //   var currentTime = moment().format("HH:mm");
    //   return currentTime;
    // },
    canGoNext() {
      let can = false
      if(this.getCurrentPage<this.pageList.length) {
        if(this.donation.isCaseClosed()) {
          const stepCheck = [
            this.donation.isLabExamSubmitted,
            this.donation.isDonorCareSubmitted,
            this.donation.isBloodCollectSubmitted
          ]
          if(stepCheck[this.getCurrentPage]) {
            can = true
          }
        } else {
          can = true
        }
      }
      return can
    },
    selectedVetID: {
      get() {
        if (!this.donation) return null;
        switch (this.$route.name) {
          case "donationCreateExam":
          case "donationExam":
            return this.donation.medicalRecord.examiner;
          case "donationLab":
            return this.donation.labExam.examiner;
          case "donationCare":
            return this.donation.donorCareInfo.approver;
          case "donationCollect":
            return this.donation.bloodCollectInfo.collector;
        }
      },
      set(val) {
        if (!this.donation) return;
        switch (this.$route.name) {
          case "donationCreateExam":
          case "donationExam":
            this.donation.medicalRecord.examiner = val;
            break;
          case "donationLab":
            this.donation.labExam.examiner = val;
            break;
          case "donationCare":
            this.donation.donorCareInfo.approver = val;
            break;
          case "donationCollect":
            this.donation.bloodCollectInfo.collector = val;
            break;
        }
      }
    },
    selectedVet() {
      return this.vets.find(v => v.id === this.selectedVetID);
    },
    getCurrentPage() {
      return this.pageList.indexOf(this.$route.name);
    }
  },
  methods: {
    changePage(name) {
      if (name === "next")
        this.$router.push({ name: this.pageList[this.getCurrentPage + 1] });
      else if (name === "previous")
        this.$router.push({ name: this.pageList[this.getCurrentPage - 1] });
      else this.$router.push({ name: name });
    },
    validateForm: function() {
      switch (this.$route.name) {
        case "donationExam":
        case "donationCreateExam":
          this.showVetConfirmModal();
          break;

        case "donationLab":
          this.showVetConfirmModal();
          break;

        case "donationCare":
          this.$refs.stepForm.$validator.validateAll().then(result => {
            if (!result || !this.hasApproverSelected()) {
              this.$notify({
                message: "ข้อมูลผิดพลาด กรุณากรอกข้อมูลให้ถูกต้อง",
                type: "error"
              });
              return;
            }
            if (
              _.isBoolean(this.donation.donorCareInfo.canDonate) &&
              !this.donation.donorCareInfo.canDonate
            ) {
              this.$confirm(
                "คุณยืนยันที่จะให้ " +
                  this.donation.pet.name +
                  " ไม่ผ่านการบริจาคเลือดใช่หรือไม่",
                "คำเตือน"
              )
              .then(() => {
                this.showVetConfirmModal();
              })
              .catch(() => {});
            } else {
              this.showVetConfirmModal();
            }
          });
          break;

        case "donationCollect":
          this.showVetConfirmModal();
          break;
      }
    },
    onNextClicked: async function() {
      let result = await this.$refs.stepForm.$validator.validateAll();
      if (!result || !this.hasApproverSelected()) {
        this.$notify({
          message: "ข้อมูลผิดพลาด กรุณากรอกข้อมูลให้ถูกต้อง",
          type: "error"
        });
        return;
      }

      switch (this.$route.name) {
        case "donationExam":
        case "donationCreateExam":
          await this.createOrUpdateDonation();
          this.changePage("donation");
          break;

        case "donationLab":
          this.donation.isLabExamSubmitted = true;
          await this.createOrUpdateDonation();
          this.changePage("donation");
          break;

        case "donationCare":
          this.donation.isDonorCareSubmitted = true;
          this.createOrUpdateDonation().then(() => {
            // Re-clone the donation because we dont go back to main page
            this.donation = new BloodDonationInfo();
            let src = this.donations.find(
              d => d.bloodDonationId === parseInt(this.$route.params.id)
            );
            this.donation.cloneFrom(src);
            this.changePage("next");
          });
          break;

        case "donationCollect":
          this.$confirm(
            "คุณยืนยันการบริจาคของ " +
              this.donation.pet.name +
              " สำเร็จใช่หรือไม่ คุณจะไม่สามารถกลับมาแก้ไขข้อมูลใดๆ กับการบริจาคครั้งนี้ได้อีก",
            "คำเตือน"
          ).then(() => {
            let isBloodCollectJustSubmitted = !this.donation
              .isBloodCollectSubmitted;
            this.donation.isBloodCollectSubmitted = true;
            this.donation.bloodCollectInfo.bloodGroup = this.donation.pet.bloodGroup;
            this.donation.bloodCollectInfo.petType = this.donation.pet.type;
            this.donation.bloodCollectInfo.quantity = this.donation.bloodCollectInfo.originalQuantity;

            if (isBloodCollectJustSubmitted) {
              // Create History BloodCollect
              let newBloodCollectionHistoryInfo = new BloodBagHistoryInfo();
              // Set Info Here
              newBloodCollectionHistoryInfo.blood_collection = this.donation.bloodCollectInfo.id;
              newBloodCollectionHistoryInfo.action = this.bloodCollectionHistoryActionEnums.Collect;
              newBloodCollectionHistoryInfo.content_type = this.contentTypeEnums.BloodDonation;
              newBloodCollectionHistoryInfo.object_id = this.donation.bloodDonationId;

              // bloodCollectionHistoryInstance.addBloodBagHistoryInfo(newBloodCollectionHistoryInfo)
              this.$store.dispatch("bloodbank/createBloodCollectionHistory", {
                data: newBloodCollectionHistoryInfo.toRequestJson()
              });
            }
            this.createOrUpdateDonation().then(() => {
              this.changePage("donation");
            });
          });
          break;
      }
    },
    async createOrUpdateDonation() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        let result = null;
        if (this.mode === "create") {
          result = await this.$store.dispatch(
            "bloodbank/createFullDonation",
            this.donation
          );
        } else if (this.mode === "view") {
          result = await this.$store.dispatch("bloodbank/updateFullDonation", {
            id: this.donation.bloodDonationId,
            data: this.donation
          });
        }
        // eslint-disable-next-line no-console
        console.log(result);
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    hasApproverSelected() {
      switch (this.$route.name) {
        case "donationExam":
        case "donationCreateExam":
          return (
            this.donation.medicalRecord.examiner != null &&
            this.donation.medicalRecord.examiner !== ""
          );

        case "donationLab":
          return (
            this.donation.labExam.examiner != null &&
            this.donation.labExam.examiner !== ""
          );

        case "donationCare":
          return (
            this.donation.donorCareInfo.approver != null &&
            this.donation.donorCareInfo.approver !== ""
          );

        case "donationCollect":
          return (
            this.donation.bloodCollectInfo.collector != null &&
            this.donation.bloodCollectInfo.collector !== ""
          );

        default:
          return true;
      }
    },
    showVetConfirmModal() {
      if (!this.selectedVet) {
        this.$notify({
          message: "ข้อมูลผิดพลาด กรุณาเลือกผู้ตรวจ",
          type: "error"
        });
        return;
      }
      this.isConfirmModalShow = true;
    },
    async rejectTicket() {
      this.$confirm("คุณยืนยันที่จะยกเลิกการบริจาคนี้ใช่ไหม ?", "คำเตือน").then(
        () => {
          this.$store
            .dispatch("bloodbank/cancelDonation", {
              id: this.donation.bloodDonationId,
              data: this.donation
            })
            .then(() => this.$router.push({ name: "donation" }));
        }
      );
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);

      // List&Fill Donations
      if (this.donations.length < 1) {
        await this.$store.dispatch("bloodbank/listAndFillBloodDonations");
      }

      // List Vets
      if (this.vets.length < 1) {
        await this.$store.dispatch("hospital/listVets");
      }

      // If in create mode, fetch the selected pet and set dummy donation
      if (this.mode === "create") {
        await this.$store.dispatch("pet/getPetProfile", {
          params: { petHospitalId: this.$route.params.id }
        });
        this.donation = new BloodDonationInfo();
        this.donation.hospitalId = this.profile.id;
        this.donation.itaampetId = this.selectedPet.pet.id;
        this.donation.pet = new PetInfo().setPropsFromPetObject(
          this.selectedPet
        );
      }
      // If in view mode, clone current donation for edit purpose
      else if (this.mode === "view") {
        this.donation = new BloodDonationInfo();
        let src = this.donations.find(
          d => d.bloodDonationId === parseInt(this.$route.params.id)
        );
        this.donation.cloneFrom(src);
      }

      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    } catch (error) {
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      await this.$store.dispatch("ui/showFetchErrorMsg");
    }
  }
};
</script>

<style lang="scss" scoped>
.subnavbar :deep(.left-col) {

  .title {
    font-size: 15px !important;

    @media (min-width: 1200px) {
      font-size: 20px !important;
    }
  }
}
</style>
