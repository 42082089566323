<template>
  <div class="pet-profile">
    <el-row>
      <el-col :span="8">
        <Avatar v-if="pet.image" :src="pet.image.medium" :size="avatarSize"/>
      </el-col>
      <el-col :span="16">
        <div class="name">
          <img
            v-if="pet.rank && pet.rank <= 3"
            :src="getStatusIcon(pet.rank)"
            alt="Status icon"
            :style='{
              display: "inline-block",
              height: "2em",
              marginRight: "6px",
              verticalAlign: "middle"
            }'
          />
          {{ pet.name }}
        </div>
        <div>
          <span class="value">
            <router-link
                v-if="hospitalId"
                :to="{ name:'hospitalPetProfile',
                params: { id: hospitalId }}"
                :style="{ color: 'inherit', textDecoration: 'none' }">
              {{ pet.type.name }}
            </router-link>
            <template v-else>{{ pet.type.name }}</template>
            <span v-if="pet.breed">| {{pet.breed.name }}</span>
          </span>
        </div>
        <div>
          <label class="label">Age:</label>
          <span class="value">{{ pet.birthday | petAge }}</span>
        </div>
        <div>
          <label class="label">Gender:</label>
          <span class="value">{{ pet.gender | petGender }}</span>
        </div>
        <template v-if="owner">
          <div>
            <label class="label">Owner:</label>
            <span class="value">{{ owner | fullname }}</span>
          </div>
          <div>
            <label class="label">Tel:</label>
            <span class="value">{{ owner.profile.reminder_phone | hyphen}}</span>
          </div>
        </template>

        <slot name="point"></slot>

      </el-col>
    </el-row>
  </div>
</template>

<script>
import Avatar from "@/components/avatar/Avatar"

export default {
  props: ["pet", "hospitalId", "avatarSize", "owner"],
  components: { Avatar },

  methods: {
    getStatusIcon(value) {
      switch (value) {
        case 1:
          return require("@/assets/icons/medal-gold.svg");

        case 2:
          return require("@/assets/icons/medal-silver.svg");

        case 3:
          return require("@/assets/icons/medal-bronze.svg");
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.pet-profile {
  font-size: 14px;
  .name {
    font-size: 16px;
    font-weight: bold;
  }

  .label {
    font-size: 14px;
  }
  .value {
    color: #666;
  }
}


</style>

