import * as vuexHelper from "../helpers/vuexHelper";

import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI
  },
  state: {
    activities: null,
    requestActivites: null,
    groups: []
  },
  asyncActions: [
    {
      name: "listActivities",
      method: "get",
      url: endpoints.activity,
      setState: "activities",
      before(payload, { commit }) {
        commit('SET_ACTIVITIES', null)
      }
    },
    {
      name: "listActivityGroups",
      method: "get",
      url: endpoints.activityGroups,
      setState: "groups",
    },
    {
      name: "listActivitySettingAll",
      method: "get",
      url: endpoints.activitySettingAll,
      setState: "activities",
    },
    {
      name: "updateActivityBulk",
      method: "patch",
      url: endpoints.activitySettingAllBulk,
    },
    {
      name: "deleteActivityBulk",
      method: "post",
      url: `${endpoints.activitySettingAll}bulk_delete/`,
    },
    {
      name: "orderingActivity",
      method: "post",
      url: `${endpoints.activitySettingAll}position/reorder/`,
    },
    {
      name: "createActivityLegacy",
      method: "post",
      url: `${endpoints.activity}legacy/`,
      onSuccess: (result) => {
        return result
      }
    },
    {
      name: "createActivity",
      method: "post",
      url: endpoints.activity,
      onSuccess: (result) => {
        return result
      }
    },
    {
      name: "createActivityOneProduct",
      method: "post",
      url: endpoints.activityOneProduct,
      onSuccess: (result) => {
        return result
      }
    },
    {
      name: 'uploadActivity',
      method: 'patch',
      url: ({ id }) => `${endpoints.activitySettingAll}${id}/image/`
    },
    {
      name: "getActivity",
      method: "get",
      url: ({ id }) => `${endpoints.activity}${id}/`
    },
    {
      name: "getActivityOneProduct",
      method: "get",
      url: ({ id }) => `${endpoints.activityOneProduct}${id}/`
    },
    {
      name: "getActivityOneProductFromProduct",
      method: "get",
      url: ({ id }) => `${endpoints.activityOneProductFromProduct}${id}/`
    },
    {
      name: "updateActivity",
      method: "patch",
      url: ({ id }) => `${endpoints.activity}${id}/`
    },
    {
      name: "updateActivityOneProduct",
      method: "patch",
      url: ({ id }) => `${endpoints.activityOneProduct}${id}/`
    },
    {
      name: "deleteActivity",
      method: "delete",
      url: ({ id }) => `${endpoints.activitySettingAll}${id}/`
    },
    {
      name: "deleteActivityOneProductFromProduct",
      method: "delete",
      url: ({ id }) => `${endpoints.activityOneProductFromProduct}${id}/`
    },
    {
      name: 'uploadActivityV2',
      method: 'patch',
      url: ({ id }) => `${endpoints.activity}${id}/`
    },
    {
      name: "listSummary",
      method: "get",
      url: `${endpoints.activitySummary}`,
    },
    {
      name: "overviewSummary",
      method: "get",
      url: `${endpoints.activitySummary}overview/`,
    },
    {
      name: "detailSummary",
      method: "get",
      url: ({ id }) => `${endpoints.activitySummary}${id}/`
    },
    {
      name: "detailSummaryTransactionList",
      method: "get",
      url: ({ id }) => `${endpoints.activitySummary}${id}/transactions/`
    },
    {
      name: "chartSummary",
      method: "get",
      url: ({ id, year }) => `${endpoints.activitySummary}${id}/yearly/${year}/`
    },
    {
      name: "listPrintGroup",
      method: "get",
      url: `${endpoints.activityPrintGroup}`,
    },
    {
      name: "createPrintGroup",
      method: "post",
      url:  `${endpoints.activityPrintGroup}`,
    },
  ]
};

export default vuexHelper.createStoreModule(config);
