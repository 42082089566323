<template>
  <div class="content">
    <div v-for="section in sections" :key="section.header">
      <div class="expand-box-collapse" :class="section.isCollapse ? 'active' : ''" @click="clickCollapse(section)" v-if="!section.isSubHeader">
        <b>{{section.header}} 
          (<span 
            :class="LabData[section.content.attachments] && LabData[section.content.attachments].length > 0 
              ? 'red-text' : ''">
            {{LabData[section.content.attachments] 
              ? LabData[section.content.attachments].length
              : 0}}
          </span>)
        </b>
      </div>
      <div class="expand-box" v-if="section.isSubHeader">{{section.header}}</div>
      <ContentBox class="content-detail" :class="section.isCollapse ? 'collapse' : ''">
        <div v-if="[1,2,3,4,5,6,7].indexOf(section.id) > -1">
          <div class="flex-row" v-loading="section.isLoading">
            <div 
              v-if="canEditData"
              class="image-container" 
              :style="progress_status === 'done' && 'display: none;'">
              <img src="@/assets/icons/icon-camera-plus.png" />
              <input
                type="file"
                :id="section.id"
                ref="file"
                @change="handleFileUpload(section.content.attachments,section)"
                :hidden="progress_status === 'done'"
              />
            </div>
            <div class="image-card-container">
              <el-card
                :body-style="{ padding: '5px' }"
                v-for="(img, index) in LabData[section.content.attachments]"
                :key="index"
                class="image-card"
              >
                
                <div class="file-previewer clearfix">
                  <div class="file-previewer-card">
                    <div v-if="fileExt(img.attachmentPreview) === 'pdf'" class="thumbnail pdf" @click="handelDownload(img.attachmentPreview)">
                      <i class="fa fa-file-pdf"></i>
                      <div class="overlay">
                        <i class="fa fa-download"></i>
                      </div>
                    </div>

                    <div v-else class="thumbnail" @click="openModalPic(index, section.content.attachments)">
                      <img :src="img.attachmentPreview" class="image image-size"/>
                      <div class="overlay">
                        <i class="fa fa-search-plus"></i>
                      </div>
                    </div>

                    <i v-if="progress_status !== 'done' && canEditData" 
                      class="fa fa-times-circle"
                      style="background-color: #fff; border-radius: 100%; border: 0px #000 solid;"
                      @click="showConfirmDeleteImage(index, section.content.attachments)">
                    </i>
                  </div>
                </div>
                <div style="padding: 14px;height:auto">
                  <div>
                    <div class="title">Tag</div>
                    <div v-if="progress_status === 'done'" class="view-mode">
                      <div class="tag-view" v-for="item in img.tags" :key="item.name">
                        {{item.name}}
                      </div>
                    </div>
                    <div class="select-with-button" v-else>
                      <v-select
                        v-model="img.tags"
                        multiple
                        label="name"
                        :disabled="!canEditData"
                        :options="tag"
                        :clearSearchOnSelect="true"
                        :pushTags="false"
                        :filterable="true"
                        @search="asyncFindTag"
                      />
                      <div 
                        v-if="canEditData"
                        @click="openModal('tag')" 
                        class="select-with-button-content">
                        <span>เพิ่ม Tag</span>
                        <div class="add-select-button">
                          <img
                            src="@/assets/icons/icon-add.png"
                            style="max-width: 100%; max-height: 100%;"
                            alt
                          />
                        </div>
                      </div>
                    </div>
                    <div class="title">Detail</div>
                    <div v-if="progress_status === 'done'" class="view-mode">
                      <div class="detail-view">{{img.description}}</div>
                    </div>
                    <el-input v-else
                      type="textarea"
                      :rows="3"
                      :disabled="!canEditData"
                      placeholder="Type here...."
                      v-model="img.description"
                      :class="progress_status === 'done' && 'disable-ele'"
                    ></el-input>
                  </div>
                </div>
              </el-card>
            </div>
          </div>
        </div>
        <div v-if="section.header === 'Complete Blood Count (CBC)'">
          <div class="row" style="padding-left: 15px; padding-right: 15px;">
            <div class="container no-gutter">
              <div class="row" style="background: #f2f2f2;">
                <div class="col-md-12 col-xs-24 row">
                  <div class="col-md-12 col-xs-12 flex">
                    <div class="row flex-middle flex-center" style="height: 36px; width: 100%;">
                      <h6>Test</h6>
                    </div>
                  </div>
                  <div class="col-md-12 col-xs-12 flex">
                    <div class="row flex-middle flex-center" style="height: 36px; width: 100%;">
                      <h6>Result</h6>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-xs-hidden row">
                  <div class="col-md-12 flex">
                    <div class="row flex-middle flex-center" style="height: 36px; width: 100%;">
                      <h6>Test</h6>
                    </div>
                  </div>
                  <div class="col-md-12 flex">
                    <div class="row flex-middle flex-center" style="height: 36px; width: 100%;">
                      <h6>Result</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-md-12 col-xs-24 padding-slider-lab"
                  v-bind:key="index"
                  v-for="(chem, index) in section.content.chem"
                >
                  <slider-lab
                    :disabled="progress_status === 'done' || !canEditData"
                    :is-required="chem.isRequired"
                    v-validate="chem.isRequired ? 'required|decimal' : 'decimal'"
                    data-vv-value-path="value"
                    :data-vv-name="chem.prop"
                    :isValid="!errors.has(chem.prop)"
                    @updated="onSliderValueUpdated"
                    :input-value="chem.value"
                    :prop-name="chem.prop"
                    :prefix="chem.prefix"
                    :suffix="chem.suffix"
                    :min="chem.min[petType]"
                    :max="chem.max[petType]"
                    :petType="pet && pet.type.key"
                  ></slider-lab>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="section.header === 'Blood Chemistry'">
          <div class="row" style="padding-left: 15px; padding-right: 15px;">
            <div class="container no-gutter">
              <div class="row" style="background: #f2f2f2;">
                <div class="col-md-12 col-xs-24 row">
                  <div class="col-md-12 col-xs-12 flex">
                    <div class="row flex-middle flex-center" style="height: 36px; width: 100%;">
                      <h6>Test</h6>
                    </div>
                  </div>
                  <div class="col-md-12 col-xs-12 flex">
                    <div class="row flex-middle flex-center" style="height: 36px; width: 100%;">
                      <h6>Result</h6>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-xs-hidden row">
                  <div class="col-md-12 flex">
                    <div class="row flex-middle flex-center" style="height: 36px; width: 100%;">
                      <h6>Test</h6>
                    </div>
                  </div>
                  <div class="col-md-12 flex">
                    <div class="row flex-middle flex-center" style="height: 36px; width: 100%;">
                      <h6>Result</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-md-12 col-xs-24 padding-slider-lab"
              v-bind:key="index"
              v-for="(chem, index) in section.content.chem"
            >
              <slider-lab
                :disabled="progress_status === 'done' || !canEditData"
                v-validate="chem.isRequired ? 'require|decimal' : 'decimal'"
                data-vv-value-path="value"
                :data-vv-name="chem.prop"
                :isValid="!errors.has(chem.prop)"
                @updated="onSliderValueUpdated"
                :prop-name="chem.prop"
                :input-value="chem.value"
                :prefix="chem.prefix"
                :suffix="chem.suffix"
                :min="chem.min[petType]"
                :max="chem.max[petType]"
                :petType="pet && pet.type.key"
              ></slider-lab>
            </div>
          </div>
        </div>
        <div v-if="section.header === 'Test Kit'">
          <div class="row flex-start">
            <label-radio-group-pair
              v-for="caseItem in section.content.testCases"
              v-show="pet && (pet.type.key == 'DOG' || pet.type.key == 'CAT') ? caseItem[pet.type.key] : true"
              :key="caseItem.case"
              class="col-md-12 col-xs-24 flex-start"
              spaceSize="5px"
              :label="caseItem.case"
              :model="caseItem.value"
              @updated="onRadioUpdated"
              :propName="caseItem.prop"
              v-bind:options="radioGroupOption"
              :class="(progress_status === 'done' || !canEditData) && 'disable-ele'"
            ></label-radio-group-pair>
          </div>
        </div>
        <div v-if="section.header === 'Other Lab Result'">
          <Viewer v-if="progress_status === 'done' || !canEditData" :value="LabData.note"></Viewer>
          <MarkdownEditor v-else v-model="LabData.note" height="120px"></MarkdownEditor>
        </div>
      </ContentBox>
    </div>
    <ConfirmDialog
            :visible="showConfirmDeleteDialog"
            @cancel="showConfirmDeleteDialog = false"
            @confirm="deleteImage"
    >
      <template slot="header">
        <h1 class="title">ยืนยันการลบรูปภาพนี้</h1>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ContentBox from "@/components/container/ContentBox";
import MarkdownEditor from "@/components/medical/common/MarkdownEditor";
import { Viewer } from '@toast-ui/vue-editor'
import SliderLab from "@/components/medical/common/LabSlider";
import LabelRadioPair from "@/components/medical/common/LabelRadioGroupPair";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";

let OptionEnum = {
  UNCHECKED: 0,
  POSITIVE: 1,
  NEGATIVE: -1
};
let MorphologyType = {
  MORPHOLOGY_TYPE_A: "MORPHOLOGY_TYPE_A",
  MORPHOLOGY_TYPE_B: "MORPHOLOGY_TYPE_B",
  MORPHOLOGY_TYPE_C: "MORPHOLOGY_TYPE_C"
};

export default {
  props: [
    "pet", 
    "modalData", 
    "modalPic", 
    "LabData",
    "canEditData",
    "progress_status"
  ],
  components: {
    "label-radio-group-pair": LabelRadioPair,
    MarkdownEditor,
    Viewer,
    ContentBox,
    "slider-lab": SliderLab,
    ConfirmDialog
  },
  data() {
    return {
      isRestored: false,
      isLoading: false,
      showConfirmDeleteDialog: false,
      deleteImg: {
        index: null,
        section: null
      },
      petType: this.pet.type.key.toLowerCase(),
      radioGroupOption: [
        {
          value: OptionEnum.UNCHECKED,
          label: "ไม่ได้ตรวจ"
        },
        {
          value: OptionEnum.POSITIVE,
          label: "Positive"
        },
        {
          value: OptionEnum.NEGATIVE,
          label: "Negative"
        }
      ],
      sections: [
        {
          id: 1,
          header: "OPD,IPD",
          isSubHeader: false,
          isLoading: false,
          isCollapse: true,
          content: {
            attachments: "opdattachment_set"
          }
        },
        {
          id: 2,
          header: "Blood-chemistry / Complete blood count",
          isSubHeader: false,
          isLoading: false,
          isCollapse: true,
          content: {
            attachments: "cbcattachment_set"
          }
        },
        {
          id: 3,
          header: "X-Ray",
          isSubHeader: false,
          isLoading: false,
          isCollapse: true,
          content: {
            attachments: "xrayattachment_set"
          }
        },
        {
          id: 4,
          header: "Ultrasound",
          isSubHeader: false,
          isLoading: false,
          isCollapse: true,
          content: {
            attachments: "ultrasoundattachment_set"
          }
        },
        {
          id: 5,
          header: "Fresh smear / Histopath",
          isSubHeader: false,
          isLoading: false,
          isCollapse: true,
          content: {
            attachments: "freshsmearattachment_set"
          }
        },
        {
          id: 6,
          header: "Other Image",
          isSubHeader: false,
          isLoading: false,
          isCollapse: true,
          content: {
            attachments: "otherattachment_set"
          }
        },
        {
          id: 7,
          header: "Other Lab",
          isSubHeader: false,
          isLoading: false,
          isCollapse: true,
          content: {
            attachments: "otherlabattachment_set"
          }
        },
        {
          id: 8,
          header: "Complete Blood Count (CBC)",
          isSubHeader: true,
          content: {
            myProgress: 2,
            myText: "Lab",
            NT: OptionEnum.UNCHECKED,
            recommends: [
              "อายุอยู่ระหว่าง 1-7 ปี",
              "น้ำหนักมากกว่า 20 kg.",
              "ป้องกันวัคซีนภายใน 1 ปี",
              "ไม่กินยาในเวลา 1 เดือน"
            ],
            radioGroupOption: [
              {
                value: OptionEnum.UNCHECKED,
                label: "ไม่ได้ตรวจ"
              },
              {
                value: OptionEnum.POSITIVE,
                label: "Positive"
              },
              {
                value: OptionEnum.NEGATIVE,
                label: "Negative"
              }
            ],
            morphologyType: MorphologyType,
            note: "",
            chem: [
              {
                prefix: "PCV",
                suffix: "%",
                min: {
                  dog: 39,
                  cat: 28.2
                },
                max: {
                  dog: 56,
                  cat: 52.7
                },
                value: this.LabData["HCT"],
                prop: "HCT",
                isRequired: true
              },
              {
                prefix: "MPV",
                suffix: "fL",
                min: {
                  dog: 7,
                  cat: 5
                },
                max: {
                  dog: 12.9,
                  cat: 11.8
                },
                value: this.LabData["MPV"],
                prop: "MPV"
              },
              {
                prefix: "HgB",
                suffix: "g/L",
                min: {
                  dog: 110,
                  cat: 103
                },
                max: {
                  dog: 190,
                  cat: 162
                },
                value: this.LabData["HgB"],
                prop: "HgB"
              },
              {
                prefix: "PCT",
                suffix: "%",
                min: {
                  dog: 0.14,
                  cat: 0
                },
                max: {
                  dog: 0.46,
                  cat: 0.79
                },
                value: this.LabData["PCT"],
                prop: "PCT"
              },
              {
                prefix: "RBC",
                suffix: "10<sup>6</sup>/μL",
                min: {
                  dog: 5.5,
                  cat: 7.12
                },
                max: {
                  dog: 8.5,
                  cat: 11.46
                },
                value: this.LabData["RBC"],
                prop: "RBC"
              },
              {
                prefix: "WBC",
                suffix: "10<sup>3</sup>/μL",
                min: {
                  dog: 6,
                  cat: 3.9
                },
                max: {
                  dog: 17,
                  cat: 19
                },
                value: this.LabData["WBC"],
                prop: "WBC"
              },
              {
                prefix: "Reticulocyte",
                suffix: "10<sup>3</sup>/μL",
                min: {
                  dog: 10,
                  cat: 3
                },
                max: {
                  dog: 110,
                  cat: 50
                },
                value: this.LabData["RETIC"],
                prop: "RETIC"
              },
              {
                prefix: "NEU",
                suffix: "10<sup>3</sup>/μL",
                min: {
                  dog: 3,
                  cat: 2.62
                },
                max: {
                  dog: 11.4,
                  cat: 15.17
                },
                value: this.LabData["NEU"],
                prop: "NEU"
              },
              {
                prefix: "MCV",
                suffix: "fL",
                min: {
                  dog: 62,
                  cat: 39
                },
                max: {
                  dog: 72,
                  cat: 56
                },
                value: this.LabData["MCV"],
                prop: "MCV"
              },
              {
                prefix: "Lym",
                suffix: "10<sup>3</sup>/μL",
                min: {
                  dog: 0.8,
                  cat: 0.85
                },
                max: {
                  dog: 5.1,
                  cat: 5.88
                },
                value: this.LabData["Lym"],
                prop: "Lym"
              },
              {
                prefix: "MCH",
                suffix: "pg",
                min: {
                  dog: 20,
                  cat: 12.6
                },
                max: {
                  dog: 25,
                  cat: 16.5
                },
                value: this.LabData["MCH"],
                prop: "MCH"
              },
              {
                prefix: "Mono",
                suffix: "10<sup>3</sup>/μL",
                min: {
                  dog: 0.15,
                  cat: 0.04
                },
                max: {
                  dog: 1.35,
                  cat: 0.53
                },
                value: this.LabData["Mono"],
                prop: "Mono"
              },
              {
                prefix: "MCHC",
                suffix: "g/L",
                min: {
                  dog: 300,
                  cat: 285
                },
                max: {
                  dog: 380,
                  cat: 378
                },
                value: this.LabData["MCHC"],
                prop: "MCHC"
              },
              {
                prefix: "EOS",
                suffix: "10<sup>3</sup>/μL",
                min: {
                  dog: 0.1,
                  cat: 0.09
                },
                max: {
                  dog: 0.75,
                  cat: 2.18
                },
                value: this.LabData["Eos"],
                prop: "Eos"
              },
              {
                prefix: "RDW",
                suffix: "%",
                min: {
                  dog: 11,
                  cat: 14
                },
                max: {
                  dog: 15.5,
                  cat: 18
                },
                value: this.LabData["RDW"],
                prop: "RDW"
              },
              {
                prefix: "Baso",
                suffix: "10<sup>3</sup>/μL",
                min: {
                  dog: 0,
                  cat: 0
                },
                max: {
                  dog: 10,
                  cat: 0.1
                },
                value: this.LabData["Baso"],
                prop: "Baso"
              },
              {
                prefix: "PLT",
                suffix: "10<sup>3</sup>/μL",
                min: {
                  dog: 117,
                  cat: 155
                },
                max: {
                  dog: 460,
                  cat: 641
                },
                value: this.LabData["PLT"],
                prop: "PLT"
              },
            ]
          }
        },
        {
          id: 9,
          header: "Blood Chemistry",
          isSubHeader: true,
          content: {
            myProgress: 2,
            myText: "Lab",
            NT: OptionEnum.UNCHECKED,
            recommends: [
              "อายุอยู่ระหว่าง 1-7 ปี",
              "น้ำหนักมากกว่า 20 kg.",
              "ป้องกันวัคซีนภายใน 1 ปี",
              "ไม่กินยาในเวลา 1 เดือน"
            ],
            radioGroupOption: [
              {
                value: OptionEnum.UNCHECKED,
                label: "ไม่ได้ตรวจ"
              },
              {
                value: OptionEnum.POSITIVE,
                label: "Positive"
              },
              {
                value: OptionEnum.NEGATIVE,
                label: "Negative"
              }
            ],
            morphologyType: MorphologyType,
            note: "",
            chem: [
              {
                prefix: "ALP",
                suffix: "U/L",
                min: {
                  dog: 5,
                  cat: 12
                },
                max: {
                  dog: 160,
                  cat: 59
                },
                value: this.LabData["ALP"],
                prop: "ALP"
              },
              {
                prefix: "CREATININE",
                suffix: "mg/dL",
                min: {
                  dog: 0.5,
                  cat: 0.9
                },
                max: {
                  dog: 1.5,
                  cat: 2.5
                },
                value: this.LabData["CREATININE"],
                prop: "CREATININE"
              },
              {
                prefix: "ALT",
                suffix: "U/L",
                min: {
                  dog: 6,
                  cat: 18
                },
                max: {
                  dog: 70,
                  cat: 121
                },
                value: this.LabData["ALT"],
                prop: "ALT"
              },
              {
                prefix: "Cholesterol",
                suffix: "mg/dL",
                min: {
                  dog: 131,
                  cat: 91
                },
                max: {
                  dog: 345,
                  cat: 305
                },
                value: this.LabData["Cholesterol"],
                prop: "Cholesterol"
              },
              {
                prefix: "AST",
                suffix: "U/L",
                min: {
                  dog: 16,
                  cat: 16
                },
                max: {
                  dog: 55,
                  cat: 67
                },
                value: this.LabData["AST"],
                prop: "AST"
              },
              {
                prefix: "Glucose",
                suffix: "mg/dL",
                min: {
                  dog: 63,
                  cat: 72
                },
                max: {
                  dog: 114,
                  cat: 175
                },
                value: this.LabData["Glucose"],
                prop: "Glucose"
              },
              {
                prefix: "CREATINE KINASE",
                suffix: "U/L",
                min: {
                  dog: 10,
                  cat: 64
                },
                max: {
                  dog: 200,
                  cat: 440
                },
                value: this.LabData["CREATINE_KINASE"],
                prop: "CREATINE_KINASE"
              },
              {
                prefix: "Calcium",
                suffix: "mg/dL",
                min: {
                  dog: 8.4,
                  cat: 8
                },
                max: {
                  dog: 11.8,
                  cat: 12
                },
                value: this.LabData["Calcium"],
                prop: "Calcium"
              },
              {
                prefix: "GGT",
                suffix: "U/L",
                min: {
                  dog: 0,
                  cat: 0
                },
                max: {
                  dog: 13,
                  cat: 6
                },
                value: this.LabData["GGT"],
                prop: "GGT"
              },
              {
                prefix: "Phosphorus",
                suffix: "mg/dL",
                min: {
                  dog: 2.5,
                  cat: 2.9
                },
                max: {
                  dog: 6.1,
                  cat: 6.3
                },
                value: this.LabData["Phosphorus"],
                prop: "Phosphorus"
              },
              {
                prefix: "Albumin",
                suffix: "g/dL",
                min: {
                  dog: 2.7,
                  cat: 3.9
                },
                max: {
                  dog: 3.2,
                  cat: 3.9
                },
                value: this.LabData["Albumin"],
                prop: "Albumin"
              },
              {
                prefix: "TCO2 (Bicarbonate)",
                suffix: "mmol/L",
                min: {
                  dog: 13,
                  cat: 12
                },
                max: {
                  dog: 27,
                  cat: 22
                },
                value: this.LabData["TCO2_Bicarbonate"],
                prop: "TCO2_Bicarbonate"
              },
              {
                prefix: "Total Protein",
                suffix: "g/dL",
                min: {
                  dog: 5.5,
                  cat: 6.3
                },
                max: {
                  dog: 7.5,
                  cat: 8.8
                },
                value: this.LabData["totalProtein"],
                prop: "totalProtein"
              },
              {
                prefix: "Chloride",
                suffix: "mmol/L",
                min: {
                  dog: 108,
                  cat: 114
                },
                max: {
                  dog: 119,
                  cat: 126
                },
                value: this.LabData["Chloride"],
                prop: "Chloride"
              },
              {
                prefix: "Globulin",
                suffix: "g/dL",
                min: {
                  dog: 2.4,
                  cat: 3.0
                },
                max: {
                  dog: 4.0,
                  cat: 5.9
                },
                value: this.LabData["Globulin"],
                prop: "Globulin"
              },
              {
                prefix: "Potassium",
                suffix: "mmol/L",
                min: {
                  dog: 4.0,
                  cat: 3.7
                },
                max: {
                  dog: 5.4,
                  cat: 5.2
                },
                value: this.LabData["Potassium"],
                prop: "Potassium"
              },
              {
                prefix: "Total Bilirubin",
                suffix: "mg/dL",
                min: {
                  dog: 0.0,
                  cat: 0.0
                },
                max: {
                  dog: 0.3,
                  cat: 0.3
                },
                value: this.LabData["totalBilirubin"],
                prop: "totalBilirubin"
              },
              {
                prefix: "Sodium",
                suffix: "mmol/L",
                min: {
                  dog: 142,
                  cat: 147
                },
                max: {
                  dog: 152,
                  cat: 157
                },
                value: this.LabData["Sodium"],
                prop: "Sodium"
              },
              {
                prefix: "Bilirubin-Conjugated",
                suffix: "mg/dL",
                min: {
                  dog: 0,
                  cat: 0
                },
                max: {
                  dog: 0.1,
                  cat: 0.2
                },
                value: this.LabData["Bilirubin_Conjugated"],
                prop: "Bilirubin_Conjugated"
              },
              {
                prefix: "Alb/Glob Ratio",
                suffix: "",
                min: {
                  dog: 0.7,
                  cat: 0.5
                },
                max: {
                  dog: 1.5,
                  cat: 1.2
                },
                value: this.LabData["Alb_Glob_Ratio"],
                prop: "Alb_Glob_Ratio"
              },
              {
                prefix: "Bilirubin-Unconjugated",
                suffix: "mg/dL",
                min: {
                  dog: 0.0,
                  cat: 0.0
                },
                max: {
                  dog: 0.2,
                  cat: 0.2
                },
                value: this.LabData["Bilirubin_Unconjugated"],
                prop: "Bilirubin_Unconjugated"
              },
              {
                prefix: "MCNA/K Ratio",
                suffix: "",
                min: {
                  dog: 28,
                  cat: 29
                },
                max: {
                  dog: 37,
                  cat: 42
                },
                value: this.LabData["NA_K_Ratio"],
                prop: "NA_K_Ratio"
              },
              {
                prefix: "BUN",
                suffix: "mg/dL",
                min: {
                  dog: 9,
                  cat: 16
                },
                max: {
                  dog: 31,
                  cat: 37
                },
                value: this.LabData["BUN"],
                prop: "BUN"
              },
            ]
          }
        },
        {
          id: 10,
          header: "Test Kit",
          isSubHeader: true,
          content: {
            testCases: [
              {
                case: "E.Canis/ E. ewingii Ab",
                value: this.LabData["ECanisEewingiiAb"],
                prop: "ECanisEewingiiAb",
                DOG: true,
                CAT: false
              },
              {
                case: "Lyme Disease",
                value: this.LabData["LymeDisease"],
                prop: "LymeDisease",
                DOG: true,
                CAT: false
              },
              {
                case: "A.phagocytophilum/ a. platys Ab",
                value: this.LabData["AphagocytophilumAplatyAb"],
                prop: "AphagocytophilumAplatyAb",
                DOG: true,
                CAT: false
              },
              {
                case: "Heartworm Ag",
                value: this.LabData["HeartwormAg"],
                prop: "HeartwormAg",
                DOG: true,
                CAT: false
              },
              {
                case: "Giardia Ag",
                value: this.LabData["GiardiaAg"],
                prop: "GiardiaAg",
                DOG: true,
                CAT: true
              },
              {
                case: "Leptospira Ab",
                value: this.LabData["LeptospiraAb"],
                prop: "LeptospiraAb",
                DOG: true,
                CAT: false
              },
              {
                case: "FIV Ab",
                value: this.LabData["FIVAb"],
                prop: "FIVAb",
                DOG: false,
                CAT: true
              },
              {
                case: "FeLV Ag",
                value: this.LabData["FeLVAg"],
                prop: "FeLVAg",
                DOG: false,
                CAT: true
              },
              {
                case: "Feline heartworm Ag",
                value: this.LabData["FelineHeartwormAg"],
                prop: "FelineHeartwormAg",
                DOG: false,
                CAT: true
              },
              {
                case: "Parvovirus Ag",
                value: this.LabData["ParvovirusAg"],
                prop: "ParvovirusAg",
                DOG: true,
                CAT: false
              },
              {
                case: "Pancreas-specific lipase",
                value: this.LabData["PancreasSpecificLipase"],
                prop: "PancreasSpecificLipase",
                DOG: true,
                CAT: true
              },
              {
                case: "NTproBNP",
                value: this.LabData["NTproBNP"],
                prop: "NTproBNP",
                DOG: false,
                CAT: true
              }
            ]
          }
        },
        {
          id: 11,
          header: "Other Lab Result",
          isSubHeader: true
        }
      ]
    };
  },
  methods: {
    async deleteImage() {
      if (this.shouldCommit()) {
        await this.$store.commit("medical/deleteAttachment", {
          section: this.deleteImg.section,
          index: this.deleteImg.index
        });
      } else {
        this.LabData[this.deleteImg.section].splice(this.deleteImg.index, 1)
      }
      this.deleteImg.index = null
      this.deleteImg.section = null
      this.showConfirmDeleteDialog = false
    },
    showConfirmDeleteImage(index, section) {
      this.deleteImg.index = index
      this.deleteImg.section = section
      this.showConfirmDeleteDialog = true
    },
    clickCollapse(section) {
      section.isCollapse = !section.isCollapse
    },
    openModal(type) {
      if (type === "tag") {
        this.modalData.modalType = 3;
        this.modalData.modalTitle = "Create Tag";
      }
      this.modalData.showCreateButton = true;
      this.modalData.showModal = true;
    },
    fileExt(filename) {
      return filename 
        ? filename.split(/[#?]/)[0].split('.').pop().trim()
        : undefined;
    },
    handelDownload(file) {
      var link = document.createElement("a")
      link.target = "_blank"
      link.href = file
      link.download = "file.pdf"
      link.dispatchEvent(new MouseEvent("click"))
    },
    openModalPic(index, section) {
      this.modalPic.img = JSON.parse(JSON.stringify(this.LabData[section][index]))
      this.modalPic.index = index
      this.modalPic.allImgs = JSON.parse(JSON.stringify(this.LabData[section]))
      this.modalPic.allImgs = this.modalPic.allImgs.filter(function(attachment) {
          return this.fileExt(attachment.attachmentPreview) !== 'pdf';
      }.bind(this));
      this.modalPic.noTag = false
      this.modalPic.showModal = true
    },
    onRadioUpdated: function(arg) {
      this.LabData[arg.prop] = arg.value;
    },
    onSliderValueUpdated: function(arg) {
      this.LabData[arg.prop] = arg.value;
    },
    handleFileUpload(attachment_prop, section) {
      let refFile = this.$refs.file.find(x => x.id == section.id);

      Array.from(refFile.files).forEach(async file => {
        section.isLoading = true;
        let response = await this.uploadFile(file);
        let model = {
          attachment: response.id,
          attachmentPreview: response.attachment,
          tags: [],
          description: ""
        };

        if (this.shouldCommit()) {
          this.$store.commit("medical/addAttachment", {
            prop: attachment_prop,
            data: model
          });
        } else {
          this.LabData[attachment_prop].unshift(model);
        }

        section.isLoading = false;
      });
    },
    shouldRestore() {
      return this.LabData.last_updated != null;
    },
    shouldCommit() {
      return this.LabData.last_updated != null && this.LabData.id != null;
    },
    async restoreModel() {
      this.LabData.opdattachment_set.forEach(attch => {
        attch.attachmentPreview = attch.attachment.attachment;
      });
      this.LabData.otherattachment_set.forEach(attch => {
        attch.attachmentPreview = attch.attachment.attachment;
      });
      this.LabData.otherlabattachment_set.forEach(attch => {
        attch.attachmentPreview = attch.attachment.attachment;
      });
      this.LabData.ultrasoundattachment_set.forEach(attch => {
        attch.attachmentPreview = attch.attachment.attachment;
      });
      this.LabData.xrayattachment_set.forEach(attch => {
        attch.attachmentPreview = attch.attachment.attachment;
      });
      this.LabData.freshsmearattachment_set.forEach(attch => {
        attch.attachmentPreview = attch.attachment.attachment;
      });
      this.LabData.cbcattachment_set.forEach(attch => {
        attch.attachmentPreview = attch.attachment.attachment;
      });

      this.isRestored = true;
    },
    async uploadFile(file) {
      try {
        let formData = new FormData();
        formData.append("attachment", file);
        const response = await this.$store.dispatch("medical/attachment", {
          formData
        });
        this.$notify({
          message: `อัพโหลดไฟล์เรียบร้อยแล้ว`,
          type: "success"
        });
        return response;
      } catch (error) {
        this.$notify({
          message: "ไม่สามารถอัพโหลดไฟล์ " + file.name,
          type: "error"
        });
        return null;
      }
    },
    async asyncFindTag(query) {
      try {
        document.querySelector(".vs__dropdown-menu").style.maxHeight = "120px";
      } catch (e) {}

      this.isLoading = true;
      await this.$store.dispatch("medical/getTag", {
        params: { search: query }
      });
      this.isLoading = false;
    }
  },
  watch: {},
  computed: {
    ...mapState({
      tag: state => state.medical.tag || []
    })
  },
  async created() {
  },
  async mounted() {
    if (this.shouldRestore()) {
      this.restoreModel();
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-family: "Kanit", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #2198f1;
}
.disable-ele {
  pointer-events: none;
}
.view-mode {
  min-height: 50px;
  max-width: 300px;
  margin: 0 10px;
  font-family: Arial;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
}
.tag-view {
  margin-right: 10px;
}
.detail-view {
  max-width: 300px;
  display: block;
  white-space: normal;
}

.expand-box, .expand-box-collapse {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 33px;
  background-image: linear-gradient(to top, #f4f7fa, #ffffff);
  font-family: Arial;
  font-size: 16px;
  font-weight: 500;
  color: #4a4a4a;
  cursor: pointer;
  border-radius: 4px;
  border: solid 1px #e6eaee;
}
.active, .expand-box-collapse:hover {
  background-image: linear-gradient(to top, #f5f5f5, #f1f1f1);
}
.collapse {
  display: none;
}

.content-detail {
  height: 100%;
  margin: 0;
  &.symptom {
    border: 2px solid #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  }
}

.flex-row {
  display: flex;
  justify-content: center;
}
.flex-row-header {
  text-align: center;
  font-weight: bold;
  width: 100%;
  font-size: 14px;
}
.select-with-button {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.select-with-button-content {
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
  font-family: Thonburi;
  font-size: 14px;
  color: #4a90e2;
}
.add-select-button {
  align-self: center;
  margin-left: 10px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.image {
  width: 100%;
  display: block;
}
.image-card-container {
  width: 100%;
  height: 100%;
  // overflow: scroll;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.image-card {
  width: 100%;
  max-width: 300px;
  max-height: 450px;
  overflow: scroll;
  display: inline-block;
}
.image-size {
  width: 100%;
  height: auto;
  max-width: 300px;
}
.image-container {
  position: relative;
  height: 80px;
  width: 80px;
  border-radius: 60px;
  background-color: #d8d8d8;
  display: block;
  margin: 8px 5px;
  img {
    display: block;
    width: 40px;
    height: 40px;
    padding: 20px;
    object-fit: contain;
    &.full-logo {
      width: 80px;
      height: 80px;
      padding: 0px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  input {
    height: 80px;
    width: 80px;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
}

h6 {
  margin: 0;
}

.padding-slider-lab {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
}

.red-text {
  color: red;
}
</style>

<style lang="scss" scoped>
.file-previewer {
  position: relative;
}
.file-previewer-card {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border: 1px solid #d9d9d9;
  width: 100%;
  height: 100%;
}

.thumbnail {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;

  img {
    height: 100%;
  }
}
.pdf {
  height: 160px;
}
.thumbnail .overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  transition: opacity 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;

  &:hover {
    opacity: 1;
  }

  > i {
    color: #fff;
    font-size: 2.4em;
    opacity: 0.8;
  }
}

.fa-file-pdf {
  font-size: 3em;
  color: #e12025;
}
.fa-times-circle {
  cursor: pointer;
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 5;
}
</style>


