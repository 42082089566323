<template>
  <div>
    <table class="appointment-pet-table">
      <thead>
        <tr>
          <th class="hn">H.N.</th>
          <th class="name">Pet Name</th>
          <th class="type">Type</th>
          <th class="owner">Owner</th>
          <th class="tel-email">Tel./Email</th>
          <th class="action"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="pet in pets" :key="pet.id">
          <td>{{ pet.hn }}</td>
          <td>
            <div class="thumbnail">
              <img v-if="pet.pet.image && pet.pet.image.medium" :src="pet.pet.image.medium">
            </div>
            <div class="pet-info">{{ pet.pet.name }}</div>
          </td>
          <td>
            {{ pet.pet.type.name }}
            <template v-if="pet.pet.gender === 0">
              <img src="@/assets/icons/icon-female-sign.png" class="gender-icon">
            </template>
            <template v-if="pet.pet.gender === 1">
              <img src="@/assets/icons/icon-male-sign.png" class="gender-icon">
            </template>
          </td>
          <td>
            <template v-if="pet.pet && pet.pet.owners && pet.pet.owners.length > 0">
              <div>{{ `${pet.pet.owners[0].profile.firstname} ${pet.pet.owners[0].profile.lastname}` }}</div>
            </template>
          </td>
          <td>
            <template v-if="pet.pet && pet.pet.owners && pet.pet.owners.length > 0">
              <div v-if="pet.pet.owners[0].phone">Tel. {{ pet.pet.owners[0].phone}}</div>
              <div class="subtitle" v-if="pet.pet.owners[0].profile.email">{{ pet.pet.owners[0].profile.email}}</div>
            </template>
          </td>
          <td>
            <el-button
              size="mini"
              type="primary"
              @click="$router.push({ name: forPage+'CreateExam', params: { id: pet.id }})"
            >
              SELECT
              <i class="el-icon-arrow-right el-icon-right"></i>
            </el-button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="not-found" v-if="pets.length === 0">ไม่พบข้อมูลที่ต้องการค้นหา</div>
    <el-pagination
      class="pagination"
      background
      layout="prev, pager, next"
      :current-page="currentPage"
      :page-size="pageSize"
      :total="total"
      @current-change="(page) => $emit('current-change', page)"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    forPage: {
      type: String,
      default: 'donation'
    },
    pets: {
      type: Array,
      required: true
    },
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    }
  }
};
</script>

<style lang="scss" scoped>
.appointment-pet-table {
  width: 100%;
  border-collapse: collapse;

  thead {
    tr {
      border: #e6eaee 1px solid;

      th {
        padding: 10px 5px;
        font-weight: normal;
        color: #7f8fa4;
        background-color: #f5f8fa;
        font-size: 0.9em;

        &.hn {
          width: 80px;
        }

        &.type {
          width: 100px;
        }

        &.action {
          width: 100px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        position: relative;
        padding: 10px 5px;
        font-size: 0.9em;
        border-bottom: 1px solid #eee;

        .subtitle {
          color: #666;
        }

        .thumbnail {
          display: inline-block;
          width: 50px;
          height: 50px;
          overflow: hidden;
          background: #eee;
          border-radius: 5px;
          margin-right: 10px;
          vertical-align: middle;

          > img {
            width: 50px;
            height: 50px;
            border-radius: 5px;
          }
        }

        .pet-info {
          display: inline-block;
          width: calc(100% - 60px);
          vertical-align: middle;
        }

        .gender-icon {
          margin: 0 5px;
          height: 20px;
          vertical-align: middle;
          opacity: 0.5;
        }
      }
    }
  }
}

.not-found {
  margin: 10px 0;
  text-align: center;
}

.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>


