<template>
  <ContentBox class="request-app-search">
    <h2>ค้นหา</h2>
    <h4>• ช่วงวันที่ขอนัดหมาย</h4>
    <!-- <el-row class="custom-row">
      <el-col :md="12">
       
      </el-col>
      <el-col :xs="8" :sm="6" :md="4">
        <el-divider direction="vertical"></el-divider>
        d
      </el-col>
    </el-row> -->
    <el-row :gutter="5">
      <el-col :md="12">
        <div>
          <el-date-picker
            v-model="filter.start"
            type="date"
            placeholder="Start Date"
            size="small"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
          <span :style="{ margin: '0 4px' }">to</span>
          <el-date-picker
            v-model="filter.end"
            type="date"
            placeholder="End Date"
            value-format="yyyy-MM-dd"
            size="small"
          ></el-date-picker>
        </div>
      </el-col>

      <el-col :md="4">
        <el-input
          v-model="filter.search"
          placeholder="ชื่อสัตว์,Pet ID,ชื่อเจ้าของ,Tel"
          size="small"
          clearable
        >
        </el-input>
      </el-col>
      <el-col :md="3">
        <el-select
          clearable
          v-model="filter.status"
          placeholder="สถานะ"
          size="small"
        >
          <el-option
            v-for="item in itemsStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :md="3">
        <el-select
          clearable
          v-model="filter.channel"
          placeholder="ช่องทาง"
          size="small"
        >
          <el-option
            v-for="item in itemsChannel"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :md="2" style="text-align: end">
        <el-button
          @click="processSearch"
          type="primary"
          icon="el-icon-search"
          size="small"
          >Search</el-button
        >

        <el-link class="btn-clear-all" @click="clear" :underline="false"
          >clearAll</el-link
        >
      </el-col>
    </el-row>
  </ContentBox>
</template>

<script>
import ContentBox from "@/components/container/ContentBox.vue";
export default {
  components: {
    ContentBox,
  },
  data() {
    return {
      filter: {
        start: null,
        end: null,
        search: null,
        status: null,
        channel: null,
      },
      itemsStatus: [
        {
          label: "Pending",
          value: "pending",
        },
        {
          label: "Accept",
          value: "accept",
        },
        {
          label: "Cancel",
          value: "cancel",
        },
      ],
      itemsChannel: [
        {
          label: "Facebook",
          value: "facebook",
        },
        {
          label: "Line",
          value: "line",
        },
        {
          label: "Itaam Website",
          value: "itaam-website",
        },
        {
          label: "Hospital Website",
          value: "hospital-website",
        },
        {
          label: "SMS",
          value: "sms",
        },
        {
          label: "Email",
          value: "email",
        },
        {
          label: "Instagram",
          value: "instagram",
        },
      ],
    };
  },
  mounted() {
    let tem = sessionStorage.getItem("filerRequest");
    if (tem) {
      this.filter = {
        ...this.filter,
        ...JSON.parse(tem),
      };
      this.$emit("input", JSON.parse(tem));
    }
  },
  methods: {
    processSearch() {
      const objSearch = Object.entries(this.filter);
      const filtered = objSearch.filter(([_, value]) => !!value);

      sessionStorage.setItem(
        "filerRequest",
        JSON.stringify(Object.fromEntries(filtered))
      );

      this.$emit("input", Object.fromEntries(filtered));
    },
    clear() {
      sessionStorage.removeItem("filerRequest");
      this.filter = {
        start: null,
        end: null,
        search: null,
        status: null,
        channel: null,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.request-app-search {
  .btn-clear-all {
    font-size: 12px;
    color: #8e8e93;
  }
}
</style>