<template>
  <div class="pet-profile">
    <Subnavbar back="petOwnerHome">
      <template slot="title">PET PROFILE</template>
      <!-- <template slot="actions">
        <button
          class="btn btn-large btn-request-appointment"
          @click="OnRequestAppointment"
        >
          <img src="@/assets/icons/icon-calendar3.png">
          <div class=""> Request Appointment</div>
        </button>
      </template> -->
      <template slot="actions">
        <el-dropdown class="btn3" @command="onFeatureClick">
          <el-button type="primary">
            <div><img src="@/assets/icons/icon-record.png" /></div>
            <div>Record<i class="el-icon-arrow-down el-icon--right"></i></div>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              command="deletePet">
                Delete Pet
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Subnavbar>
    <div class="el-row">
      <div class="el-col el-col-24 el-col-md-8">
        <div class="form-block">
          <PetDetail :pet="profile" :showProfile="isShowProfile"/>
          <PetDetailOwner :pet="profile" :showProfile="isShowProfile" routeProfile=""/>
          <div class="panel-block">
            <el-button class="action-btn" type="default" @click="isShowProfile = !isShowProfile" style="width: 100%; margin-top: 10px; margin-bottom: 15px;">
              {{ isShowProfile ? 'Hide' : 'Show' }} Profile
            </el-button>
          </div>
        </div>
      </div>
      <div class="el-col el-col-24 el-col-md-16 content-padding" style="height: 100%">
        <div class="panel-block">
          <el-tabs v-model="activeName" :stretch="isMobile()" :class="isHospitalConnected ? 'hide-tab' : ''">
            <el-tab-pane
              label="Appointment"
              name="appointment"
              style="margin-bottom: -40px;">
              <PetAppointmentTimeline
                viewer="owner"
                viewIn="petPage"
                type="active"
                :petHospitalId="profile.id"
                v-if="isAppointmentLoaded && profile"
              />
              <el-collapse class="appointment-history-collapse" style="margin-bottom: 60px">
                <el-collapse-item name="1">
                  <template slot="title">
                    <div class="appointment-history-collapse-title">ประวัติการนัดหมาย</div>
                  </template>
                  <PetAppointmentTimeline
                    viewer="owner"
                    viewIn="petPage"
                    :petHospitalId="profile.id"
                    v-if="isAppointmentLoaded && profile"
                  />
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
            <el-tab-pane
              label="HealthBook"
              name="healthBook"
            >
              <h2 class="hidden-sm-and-down">Health History</h2>
              <PetFinalDiagnosisTimeline
                v-if="profile && ishealthBookLoaded"
                viewer="owner"
                title="Health History"
                :petId="profile.id"
              />
              <h2 class="hidden-sm-and-down">Blood</h2>
              <PetBloodTimeline
                v-if="profile && ishealthBookLoaded"
                viewer="owner"
                title="Blood"
                :petId="profile.id"
              />
              <h2>Vaccine Book</h2>
              <PetHealthBookTimeline
                v-if="profile && ishealthBookLoaded"
                viewer="owner"
                :petId="profile.id" />
            </el-tab-pane>
            <el-tab-pane
              label="Redeem"
              name="reward"
              style="border-radius: 4px;border: solid 1px #e6eaee;">
              <PetRewardTimeline
                viewer="owner"
                :petID="profile.id"
                v-if="isRewardLoaded && profile" />
            </el-tab-pane>
            <el-tab-pane
              label="Insurance"
              name="insurance">
              <PetInsuranceTimeline
                viewer="owner"
                :petID="profile.id"
                v-if="isInsuranceLoaded && profile" />
            </el-tab-pane>
            <el-tab-pane
              label="Message"
              name="message">
              <PetMessageTimeline
                :isOwner="true"
                :petHospitalId="profile.id"
                v-if="isMessageLoaded && profile" />
          </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>

    <ConfirmDialog
      v-if="profile && owner"
      :visible="isShowConfirmDeleteDialog"
      @cancel="isShowConfirmDeleteDialog = false"
      @confirm="handleDeletePet"
    >
      <template slot="header">
        <h1 class="title" :style="{ color: '#000' }">
          ยืนยันการลบ "{{profile.name}}"
          <span v-if="owner.profile">
            <br />ออกจาก "{{owner.profile.firstname}} {{owner.profile.lastname}}"
          </span>
        </h1>
      </template>
      <template slot="content">
        <div :style="{ display: 'inline-block', textAlign: 'center' }">
          <p :style="{ fontSize: '14px', textAlign: 'left' }">
            เมื่อยืนยันการลบสัตว์เลี้ยงออกแล้ว<br>สัตว์เลี้ยงจะไม่ถูกผูกกับเจ้าของท่านนี้อีก
          </p>
        </div>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Subnavbar from '@/components/navigation/Subnavbar'
import PetDetail from '@/components/pet/PetDetail'
import PetDetailOwner from '@/components/pet/PetDetailOwner'
import PetTimeline from "@/components/pet/PetTimeline"
import PetMessageTimeline from "@/components/message/PetMessageTimeline"
import PetAppointmentTimeline from "@/components/appointment/PetAppointmentTimeline"
import PetHealthBookTimeline from "@/components/healthbook/timeline/PetHealthBookTimeline"
import PetFinalDiagnosisTimeline from "@/components/medical/timeline/PetFinalDiagnosisTimeline"
import PetBloodTimeline from "@/components/healthbook/timeline/PetBloodTimeline"
import PetRewardTimeline from "@/components/reward/PetRewardTimeline"
import PetInsuranceTimeline from "@/components/insurance/PetInsuranceTimeline";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";

export default {
  components: {
    Subnavbar,
    PetDetail,
    PetDetailOwner,
    PetTimeline,
    PetMessageTimeline,
    PetAppointmentTimeline,
    PetHealthBookTimeline,
    PetFinalDiagnosisTimeline,
    PetBloodTimeline,
    PetRewardTimeline,
    PetInsuranceTimeline,
    ConfirmDialog,
  },
  data() {
    return {
      profile: null,
      isShowProfile: false,
      activeName: "",
      isHospitalConnected: false,
      isMessageLoaded: false,
      isAppointmentLoaded: false,
      ishealthBookLoaded: false,
      isRewardLoaded: false,
      isInsuranceLoaded: false,
      isShowConfirmDeleteDialog: false,
    }
  },
  computed: {
    ...mapState({
      pets: state => state.owner.pets,
      timeline: state => state.owner.timeline,
    }),
    ...mapGetters({
      owner: "owner/profile"
    }),
    sterilizeDate() {
      if (this.profile.sterilize_date == null) return "";
      const arr = this.profile.sterilize_date.split("-");
      const year = parseInt(arr[0]) + 543;
      const month = [ "ม.ค.", "ก.พ.", "มี.ค.", "เม.ษ.", "พ.ค.", "มิ.ย.",
        "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค." ][parseInt(arr[1]-1)];
      return `${parseInt(arr[2])} ${month} ${year.toString().slice(-2)}`;
    },
    birthday() {
      if (this.profile.birthdate == null) return "";
      const arr = this.profile.birthdate.split("-");
      const year = parseInt(arr[0]) + 543;
      const month = [ "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน",
        "กรกฏาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม" ][parseInt(arr[1]-1)];
      return `${parseInt(arr[2])} ${month} ${year}`;
    },
    age() {
      this.calculateAge();
      return `${this.profile.age_year} ปี ${this.profile.age_month} เดือน`;
    }
  },
  async mounted() {
    this.activeName = 'appointment';
    const { tab } = this.$route.query;
    if (tab) this.activeName = tab;
    this.$store.commit('owner/SET_TIMELINE', {});
    await this.$store.dispatch('ui/setIsFullScreenLoading', true)

    await this.$store.dispatch('owner/getPet', {
      params: { id: this.$route.params.id },
    });
    if (this.$store.state.owner.pet) {
      this.profile = {
        image: this.$store.state.owner.pet.image.large,
        name: this.$store.state.owner.pet.name,
        id: this.$store.state.owner.pet.id,
        point: this.$store.state.owner.pet.point,
        birthdate: this.$store.state.owner.pet.birthday,
        gender: this.$store.state.owner.pet.gender,
        sterilize: this.$store.state.owner.pet.sterilize,
        sterilize_date: this.$store.state.owner.pet.sterilize_date,
        blood_group: this.$store.state.owner.pet.blood_group,
        microchip: this.$store.state.owner.pet.microchip,
        weight: this.$store.state.owner.pet.weight,
        insurance_number: this.$store.state.owner.pet.insurance_number,
        drug_allergy_detail: this.$store.state.owner.pet.drug_allergy_detail,
        owners: this.$store.state.owner.pet.owners,
        type: this.$store.state.owner.pet.type,
        breed: this.$store.state.owner.pet.breed,
        hospitals: this.$store.state.owner.pet.hospitals,
      }
    }
    await this.$store.dispatch('ui/setIsFullScreenLoading', false)
  },
  methods: {
    OnRequestAppointment() {
      alert("Coming Soon");
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    calculateAge() {
      if (this.profile.birthdate == null) return;
      var diff = new Date(Date.now()-new Date(this.profile.birthdate));
      if (diff >= 0) {
        this.profile.age_year = diff.getYear()-70;
        this.profile.age_month = diff.getMonth();
      }
    },
    async handleDeletePet() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("owner/deletePet", {
          params: { id },
        });

        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        this.$notify({
          type: "success",
          title: "ลบสัตว์เลี้ยงเรียบร้อยแล้ว",
        });
        this.isShowConfirmDeleteDialog = false;
        this.$router.push({ name: "petOwnerHome" });
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    onFeatureClick(command) {
      switch (command) {
        case "deletePet":
          this.isShowConfirmDeleteDialog = true;
          break;
      }
    },
  },
  watch: {
    activeName (newValue) {
      this.$router.replace({ query: {...this.$route.query, tab: newValue }})
      if (newValue === "message") {
        this.isMessageLoaded = true
      } else if (newValue === "appointment") {
        this.isAppointmentLoaded = true
      } else if (newValue === "healthBook") {
        this.ishealthBookLoaded = true
      } else if (newValue === "reward") {
        this.isRewardLoaded = true
      } else if (newValue === "insurance") {
        this.isInsuranceLoaded = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// .contain {
//   max-width: 320px;
//   margin: 0 auto;
//   padding: 12px;
// }
.form-block {
  @extend %block-container;
  @extend %form-style;

  padding-top: 9px;
  padding-bottom: 20px;
  text-align: center;

  background: none;
  box-shadow: none;
  padding: 0;
  margin: 0 auto;

  max-width: 400px;
  // max-width: 300px;
  // border-radius: 4px;
  // border: solid 1px #e6eaee;
  // background-color: #ffffff;
}
.el-col {
  padding-left: 12px;
  padding-right: 12px;
}
.panel-block {
  //@extend %block-container;
  // @extend %form-style;
  padding: 0px;
  margin-bottom: 10px;

  border: none;
  background: none;
  box-shadow: none;

  // padding-bottom: 48px;
  .el-card {
    box-shadow: none;

  }
  @include non-desktop {
    margin: 0 auto;
    max-width: 400px;
  }
}
.footer-block {
  font-size: 14px;
  text-align: center;
  span {
    color: $gray;
  }
  a {
    text-decoration: none;
    color: #000;
  }
}
</style>

<style lang="scss" scoped>
.login-optional {
  .el-form-item__content {
    div {
      width: auto;
      display: inline-block;
      vertical-align: middle;

      .el-checkbox__label, a {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 0.1px;
        text-align: center;
        color: #8e8e93;
        text-decoration: none;
      }
    }
    div:first-child {
      float: left;
    }
    div:last-child {
      float: right;
    }
  }
  .el-checkbox__inner {
    border-radius: 8px;
  }
}
button.btn-pet-login {
  width: 213px !important;
  font-size: 17px !important;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #e6eaee;
  margin-bottom: 6px;
}

.split {
  // height: 100%;
  // float: left;
  vertical-align: top;
  display: inline-block;
  // position: fixed;
  // z-index: 1;
  top: 0;
  // overflow-x: hidden;
  // padding-top: 20px;

  &.left {
    left: 0;
    width: 120px;
  }

  &.right {
    right: 0;
  }
}

img.cover {
  position: relative;
  width: 100%;
  height: 100px;
  margin-bottom: -70px;
  margin-top: -14px;
}

img.profile {
  width: 128px;
  height: 128px;
  object-fit: cover;
  border-radius: 60px;

  position: relative;
  z-index: 1;

  &.large {
    width: 83px;
    height: 83px;
  }
}

img.pet-profile {
  display: inline;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
  border: solid 1px #979797;
}

img.gender {
  width: 18px;
  height: 18px;
  object-fit: contain;
}


img.pet-type {
  width: 27px;
  height: 27px;
  object-fit: contain;
}

.el-table {
  img {
    display: inline;
    vertical-align: middle;
  }

  .el-button {
    padding: 12px;
    width: 60px;
    height: 40px;
    border-radius: 4px;
    // background-color: #e04e1d;

    // font-family: default;
    // font-size: 14px;
    // font-weight: 900;
    // font-style: normal;
    // font-stretch: normal;
    // line-height: normal;
    // letter-spacing: normal;
    // color: #ffffff;

    &.light-blue-button {
      padding: 0px;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      &:not(:hover) {
        background-color: #c2e5f0;
        border: none;
      }
    }
  }

  .pet-name {
    font-family: default;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #1890ff;
  }
}

.title {
  font-family: default;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #354052;

  margin-bottom: 0px;
}

.subtitle {
  margin-top: 0px;
  margin-bottom: 40px;

  font-family: default;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #7f8fa4;
  .c1 {
    font-weight: normal;
  }
}

.social-info {
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;

  margin-bottom: 7px
}

.el-button {
  &.action-btn {
    color: #156f88;
    border-radius: 5px;
    border: none;
    &:not(:hover) {
      background-color: #c2e5f0;
    }
  }

  &.edit-btn {
    position: relative;
    right: 0;
    margin-top: -24px;
    margin-right: -24px;
    float: right;

    z-index: 1;
  }

  &.connect-btn {
    width: 100%;
    display: block;
    clear: both;

    height: 50px;
    border-radius: 5px;

    font-family: Arial;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;

    margin-left: 0px;
    margin-bottom: 14px;
    // border: solid 2px #e7e7e7;
    // background-color: #0384ff;
  }
}

.el-tabs__nav-wrap::after {
  background-color: none;
}

.el-card__header {
  background-image: linear-gradient(to top, #f4f7fa, #ffffff);
}

.location-picker-container {
  // margin: -20px;
  // margin-top: 0px;
  width: 100%;
  height: 200px;
}

.detail {
  text-align: left;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0px;
  }

  .key {
    font-family: default;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #8e8e93;
  }
  .value {
    font-family: default;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #030303;

    &.small {
      font-family: default;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: normal;
      color: #354052;
    }

    &.big {
      font-family: default;
      font-size: 18px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    &.line {
      border-bottom: 1px solid #e6eaee;
    }

    .sub {
      font-size: 16px;
      font-weight: 0;
    }
  }
}

.card-block {
  .header {
    padding: 12px;
    border-radius: 4px 4px 0px 0px;
    border: solid 1px #e6eaee;
    background-image: linear-gradient(to top, #f4f7fa, #ffffff);

    font-family: default;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #354052;
  }
  .contain {
    padding: 0px;
    border-radius: 0px 0px 4px 4px;
    border: solid 1px #e6eaee;
    background-color: #ffffff;
    .row {
      padding: 12px;
    }
  }
}

.no-item {
  font-family: default;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.7px;
  text-align: center;
  color: #8e8e93;
  margin: 72px 0px;
}

.profile-btn{
  font-family: default;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7f8fa4;

  text-decoration: none;
}
</style>

<style lang="scss">
.pet-profile {
  .content-padding {
    padding: 16px 12px 0px 24px;

    @include mobile {
      padding: 12px 12px 0px 12px;
    }
  }

  .el-tabs__item {
    font-family: default;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8e8e93;

    &.is-active {
      color: #000000;
    }
  }

  .el-tabs__active-bar {
    height: 3px;
  }

  .el-tabs__nav-wrap::after {
    background: none;
  }

  .el-card__body {
    padding: 0px;
  }

  .el-card {
    border: none;
    box-shadow: none;
  }
}
</style>

<style lang="scss">
.pet-profile {
  @include mobile {
    .el-tabs__item {
      padding: 10px;

      font-family: default;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8e8e93;

      &.is-active {
        color: #000000;
      }
    }

    .el-tabs__active-bar {
      // margin-left: -5%;
      // width: 55% !important;
      height: 3px;
    }

    .el-tabs__nav-wrap::after {
      background: none;
    }

    .el-table {
      th {
        background-color: #f5f8fa;
      }
    }

    .hide-mobile {
      display: none;
    }
  }

  .hide-tab {
    .el-tabs__header {
      display: none;
    }
  }

  th {
    background: #f5f8fa;
  }

  .el-button--danger.is-disabled {
    background: #cfcfcf;
    border-color: #cfcfcf;
  }

  .btn-request-appointment {
    color: #fff;
    background-color: #02b0e1;

    font-family: default;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    // line-height: 10px;
    letter-spacing: -0.4px;
    text-align: right;
    color: #ffffff;

    padding-top: 8px;
    margin-left: 0px;

    img {
      width: 29px;
      height: 31px;
      object-fit: contain;
      float: left;
      display: inline;
    }
  }
}
</style>

<style lang="scss">
  .appointment-history-collapse {
    border-radius: 5px;
    background-color: rgba(2, 176, 225, 0.19);
    text-align: center;

    .el-collapse-item__header {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #156f88;
    }

    .appointment-history-collapse-title {
      width: 100%;
      font-size: 14px;
      text-align: center;
      color: #156f88;
    }

    .el-collapse-item__wrap {
      background-color: #f0f2f5;
    }
  }
  .btn3 {
    vertical-align: middle;

    img {
      width: 26px;
      height: 26px;
      object-fit: contain;
    }

    .el-button {
      // width: 160px;
      height: 50px;
      border: none;
      border-radius: 6px;
      background-color: #324a5e;

      margin-left: 10px;
      // padding-left: 10px;
      // padding-right: 10px;
      span {
        line-height: 25px;
      }

      div {
        display: inline;
        vertical-align: middle;

        &:first-child {
          float: left;
        }

        &:last-child {
          margin-left: 10px;
          float: right;
        }
      }
    }
  }
</style>
