var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pet-profile"},[_c('el-row',[_c('el-col',{attrs:{"span":8}},[(_vm.pet.image)?_c('Avatar',{attrs:{"src":_vm.pet.image.medium,"size":_vm.avatarSize}}):_vm._e()],1),_c('el-col',{attrs:{"span":16}},[_c('div',{staticClass:"name"},[(_vm.pet.rank && _vm.pet.rank <= 3)?_c('img',{style:({
            display: "inline-block",
            height: "2em",
            marginRight: "6px",
            verticalAlign: "middle"
          }),attrs:{"src":_vm.getStatusIcon(_vm.pet.rank),"alt":"Status icon"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.pet.name)+" ")]),_c('div',[_c('span',{staticClass:"value"},[(_vm.hospitalId)?_c('router-link',{style:({ color: 'inherit', textDecoration: 'none' }),attrs:{"to":{ name:'hospitalPetProfile',
              params: { id: _vm.hospitalId }}}},[_vm._v(" "+_vm._s(_vm.pet.type.name)+" ")]):[_vm._v(_vm._s(_vm.pet.type.name))],(_vm.pet.breed)?_c('span',[_vm._v("| "+_vm._s(_vm.pet.breed.name))]):_vm._e()],2)]),_c('div',[_c('label',{staticClass:"label"},[_vm._v("Age:")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("petAge")(_vm.pet.birthday)))])]),_c('div',[_c('label',{staticClass:"label"},[_vm._v("Gender:")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("petGender")(_vm.pet.gender)))])]),(_vm.owner)?[_c('div',[_c('label',{staticClass:"label"},[_vm._v("Owner:")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("fullname")(_vm.owner)))])]),_c('div',[_c('label',{staticClass:"label"},[_vm._v("Tel:")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("hyphen")(_vm.owner.profile.reminder_phone)))])])]:_vm._e(),_vm._t("point")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }