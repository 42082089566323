<template>
  <div class="pet-appointment-timeline">
    <div v-if="appointmentsNotComplete.length > 0">
      <PetAppointmentTimelineItem
        :items="appointmentsNotComplete"
        :showPetInfo="showPetInfo"
        :requestFlag="requestFlag"
      />
    </div>
    <div
      class="load-more-container"
      v-if="
        appointmentsNotComplete.length &&
        currentPageNotComplete < totalPagesNotComplete
      "
      style="margin-bottom: 60px"
    >
      <button
        class="btn btn-small btn-primary"
        @click="loadMore('not-complete')"
      >
        Load more...
      </button>
    </div>
    <div v-if="appointmentsComplete.length > 0">
      <el-collapse
        class="appointment-history-collapse"
        style="margin-bottom: 60px"
      >
        <el-collapse-item name="1">
          <template slot="title">
            <div class="appointment-history-collapse-title">
              ประวัติการนัดหมาย
            </div>
          </template>
          <PetAppointmentTimelineItem
            :items="appointmentsComplete"
            :showPetInfo="showPetInfo"
            :requestFlag="requestFlag"
          />
          <div
            class="load-more-container"
            v-if="appointmentsComplete.length && currentPage < totalPages"
            style="margin-bottom: 60px"
          >
            <button
              class="btn btn-small btn-primary"
              @click="loadMore('complete')"
            >
              Load more...
            </button>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>

    <!-- <div
      class="no-item"
      v-if="!appointmentsNotComplete.length && !appointmentsComplete.length"
    >
      <div class="grid flex-col justify-items-center">
        <img
          width="350px"
          src="@/assets/images/no-result/no-result-04.png"
          class="responsive-img"
        />
        <div class="text-gray-400 text-xl">ไม่มีรายการบันทึก</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import _ from "lodash/";
import PetAppointmentTimelineItem from "./PetAppointmentTimelineItem.vue";
export default {
  // viewer : hospital, owner, public
  // viewIn: petPage, ownerPage
  // type: active, ''
  props: [
    "petHospitalId",
    "viewer",
    "viewIn",
    "type",
    "showPetInfo",
    "requestFlag",
  ],
  components: {
    PetAppointmentTimelineItem,
  },
  data() {
    return {
      id: this.petHospitalId,
      appointments: [],
      appointmentsNotComplete: [],
      appointmentsComplete: [],
      currentPageNotComplete: 1,
      currentPage: 1,
      totalPages: 1,
      totalPagesNotComplete: 1,
    };
  },
  methods: {
    goApptDetail(apptId) {
      const route =
        localStorage.getItem("user_type") == "hospital_admin"
          ? "appointment-detail"
          : "public-appointment-detail";
      this.$router.push({ name: route, params: { id: apptId } });
    },
    loadMore(group) {
      this.fetchAppointments(group);
    },
    async fetchAppointments(group) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);

        let query = {};
        var response;

        if (this.currentPage < this.totalPages) {
          this.currentPage += 1;
        }
        if (group === "not-complete") {
          if (this.currentPageNotComplete < this.totalPagesNotComplete) {
            this.currentPageNotComplete += 1;
          }
          query["page"] = this.currentPageNotComplete;
        } else {
          if (this.currentPage < this.totalPages) {
            this.currentPage += 1;
          }
          query["page"] = this.currentPage;
        }

        if (this.viewer == "hospital") {
          if (this.viewIn == "ownerPage") {
            response = await this.$store.dispatch(
              "owner/getOwnerAppointmentTimeline",
              { query, params: { id: this.id } }
            );
          } else if (this.viewIn == "petPage" && this.type == "active") {
            response = await this.$store.dispatch(
              "pet/listAppointmentsActiveTimeline",
              { query, params: { petHospitalId: this.id } }
            );
          } else if (this.viewIn == "petPage" && this.requestFlag) {
            response = await this.$store.dispatch(
              "appointment/getListRequestAppointment",
              {
                query: {
                  pet: this.id,
                },
              }
            );
          } else if (this.viewIn == "petPage") {
            response = await this.$store.dispatch("pet/listAppointments", {
              query,
              params: { petHospitalId: this.id },
            });
          }
        } else if (this.viewer == "owner") {
          if (this.viewIn == "ownerPage") {
            response = await this.$store.dispatch(
              "owner/getAppoinmentTimeline",
              { query }
            );
          } else if (this.viewIn == "petPage" && this.type == "active") {
            response = await this.$store.dispatch(
              "owner/listPetTimelineAppointmentsActive",
              { query, params: { petId: this.id } }
            );
          } else if (this.viewIn == "petPage") {
            response = await this.$store.dispatch(
              "owner/listPetTimelineAppointments",
              { query, params: { petId: this.id } }
            );
          }
        } else if (this.viewer == "public") {
          if (this.viewIn == "petPage" && this.type == "active") {
            response = await this.$store.dispatch(
              "pet/listPublicTimelineAppointmentActive",
              { query, params: { code: this.id } }
            );
          } else if (this.viewIn == "petPage") {
            response = await this.$store.dispatch(
              "pet/listPublicTimelineAppointment",
              { query, params: { code: this.id } }
            );
          }
        }

        const listGroupOne = ["no_response", "comfirm", "accept", "pending"];
        const listGroupTwo = ["cancel", "done", "postpone", "no_show"];

        if (this.type !== "active") {
          if (this.currentPage > 1) {
            // this.appointments = [...this.appointments, ...response.results];

            if (group === "not-complete") {
              this.appointmentsNotComplete = [
                ...this.appointmentsNotComplete,
                ..._.filter(response.results, (it) =>
                  _.includes(listGroupOne, it.status)
                ),
              ];
            } else {
              this.appointmentsComplete = [
                ...this.appointmentsComplete,
                ..._.filter(response.results, (it) =>
                  _.includes(listGroupTwo, it.status)
                ),
              ];
            }
          } else {
            this.appointmentsNotComplete = _.filter(response.results, (it) =>
              _.includes(listGroupOne, it.status)
            );
            this.appointmentsComplete = _.filter(response.results, (it) =>
              _.includes(listGroupTwo, it.status)
            );
          }
          if (group === "not-complete") {
            this.totalPagesNotComplete = Math.ceil(response.count / 10);
          } else {
            this.totalPages = Math.ceil(response.count / 10);
          }
        } else {
          this.appointmentsNotComplete = _.filter(response.results, (it) =>
            _.includes(listGroupOne, it.status)
          );
          this.appointmentsComplete = _.filter(response.results, (it) =>
            _.includes(listGroupTwo, it.status)
          );
        }

        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
      }
    },
  },

  async mounted() {
    this.fetchAppointments();
  },
};
</script>

<style lang="scss" scoped>
.pet-info {
  vertical-align: middle;
  height: 42px;

  div {
    top: 0;
    width: 200px;

    .detail {
      width: 200px;
      .key,
      .value {
        display: block;
      }
    }
  }

  img.pet-profile {
    float: left;
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 4px;
    border: solid 1px #979797;
  }
}

.no-item {
  font-family: default;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.7px;
  text-align: center;
  color: #8e8e93;
  margin: 72px 0px;
}

.detail {
  text-align: left;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0px;
  }

  .time {
    width: 100%;
    max-width: 269px;
    height: 30px;
    border-radius: 4px;
    color: white;
    background-color: #156f88;

    text-align: center;
    vertical-align: middle;
    line-height: 30px;

    span {
      &:first-child {
        float: left;
        margin-left: 8px;
      }
      &:last-child {
        float: right;
        margin-right: 8px;
      }
    }
  }

  .key {
    font-family: default;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #8e8e93;
  }
  .value {
    font-family: default;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #030303;

    &.small {
      font-family: default;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: normal;
      color: #354052;
    }

    &.big {
      font-family: default;
      font-size: 18px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    &.line {
      border-bottom: 1px solid #e6eaee;
    }

    .sub {
      font-size: 16px;
      font-weight: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
.status {
  line-height: 18px;
  position: absolute;
  top: 12px;
  right: 12px;
  @include mobile {
    top: 0;
    right: 0;
    position: relative;
    float: none;
  }
  display: inline-block;
  // width: 50px;
  span {
    display: inline;
    vertical-align: middle;
    margin-right: 6px;

    font-weight: bold;
    color: #02b0e1;
  }

  .detail {
    display: inline;
    vertical-align: middle;

    padding: 0px 8px 0px 8px;
    height: 18px;
    border-radius: 3px;
    background-color: #02b0e1;

    text-align: center;
    vertical-align: middle;
    line-height: 18px;

    font-family: default;
    font-size: 10px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  &.request_postpone {
    div {
      background: #ff4f4a;
    }
    span {
      color: #ff4f4a;
    }
  }

  &.no_show {
    div {
      background: #ff8800;
    }
    span {
      color: #ff8800;
    }
  }

  &.no_respond {
    div {
      background: #ffc300;
    }
    span {
      color: #ffc300;
    }
  }

  &.confirm {
    div {
      background: #7ed321;
    }
    span {
      color: #7ed321;
    }
  }

  &.done {
    div {
      background: #90d5e8;
    }
    span {
      color: #90d5e8;
    }
  }

  &.postpone {
    div {
      background: #a8aab7;
    }
    span {
      color: #a8aab7;
    }
  }

  &.terminate {
    div {
      background: #d8d8d8;
    }
    span {
      color: #d8d8d8;
    }
  }
}
</style>


<style lang="scss">
.pet-appointment-timeline {
  .el-timeline {
    padding: 0px 0px 0px 12px;
    margin: auto;
    overflow: hidden;
  }

  .el-timeline-item__wrapper {
    padding-left: 50px;
  }

  .el-timeline-item__tail {
    left: 3px;
    border-left: 4px solid #e4e7ed;
  }

  .el-timeline-item {
    .el-timeline-item__node--normal {
      left: -12px;
      width: 34px;
      height: 34px;
      background-image: url("../../assets/icons/icon-calendar2.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

    &:first-child {
      .el-timeline-item__node--normal {
        display: none;
      }
    }

    &:last-child {
      .el-timeline-item__node--normal {
        display: none;
      }
      padding: 0px;
    }

    // &:nth-last-child(2) {
    //   height: 300px;
    // }
  }
}
</style>

<style lang="scss">
.pet-appointment-timeline {
  .el-card__body {
    padding: 0px;
  }
}
.load-more-container {
  text-align: center;
  padding: 0 0 20px 0;
}
</style>


<style lang="scss" scoped>
.el-button {
  img {
    width: 20px;
    height: 20px;
    object-fit: contain;

    display: inline;
    vertical-align: middle;
  }

  &.side-button {
    width: 100%;
    // margin-top: 5px;
    margin-bottom: 15px;
  }
}

.el-button {
  &.action-btn {
    color: #156f88;
    border-radius: 5px;
    border: none;
    &:not(:hover) {
      background-color: #edfbff;
    }

    &.b1 {
      border-radius: 0px;
      width: 100%;
    }
  }
}

.contain {
  padding: 12px;
}
</style>