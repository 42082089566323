<template>
  <el-dialog :visible="visible" width="600px" center @close="$emit('cancel')">
    <div class="dialog-content">
      <h1 class="title">ยืนยันสร้างนัดหมาย</h1>
      <template v-if="pet">
        <div class="custom-row">
          <label>Pet</label>
          {{ pet.pet.name }}
        </div>
      </template>
      <template v-if="owner">
        <div class="custom-row">
          <label>Owner</label>
          {{ `${owner.profile.firstname} ${owner.profile.lastname}` }}
        </div>
      </template>
      <div class="custom-row" v-if="date">
        <label>วันที่นัด</label>
        {{ date | toThaiDate }}
        <template v-if="!isNoTime">{{date | toHHMM}}</template>
        <template v-else>(ไม่ระบุเวลา)</template>
      </div>
      <div class="custom-row">
        <label>กิจกรรม</label>
        <ol>
          <li v-for="activity in activities" :key="activity.id">{{ activity.name }}</li>
        </ol>
      </div>
      <div class="custom-row">
        <label>สัตวแพทย์</label>
        <template v-if="vet">{{ `${vet.first_name} ${vet.last_name}`}}</template>
        <template v-else>ไม่ระบุ</template>
      </div>
      <div class="custom-row">
        <label>บันทึกเพิ่มเติม</label>
        {{ note || '-' }}
      </div>
      <hr />
      <template v-if="credit">
        <div class="custom-row">
          <label>Your Credit</label>
          {{ credit.your_left_credit | number }}
        </div>
        <div class="custom-row">
          <label>SMS</label>
          {{ credit.total_sms_credit | number }}
        </div>
        <div class="custom-row">
          <label>Social</label>
          {{ credit.total_social_credit | number }}
        </div>
      </template>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('cancel')">Cancel</el-button>
      <el-button type="primary" @click="$emit('confirm')">Confirm</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    credit: {
      type: Object
    },
    visible: {
      type: Boolean
    },
    activities: {
      type: Array
    },
    pet: {
      type: Object
    },
    owner: {
      type: Object
    },
    date: {
      type: Date
    },
    isNoTime: {
      type: Boolean
    },
    vet: {
      type: Object
    },
    note: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-content {
  font-size: 16px;
  .title {
    margin: -15px 0 10px 0;
    padding: 0;
    font-size: 20px;
    text-align: center;
    color: $light-blue;
  }

  .custom-row {
    margin-bottom: 5px;
  }

  label {
    display: inline-block;
    vertical-align: top;
    color: #333;
    width: 100px;
    font-weight: bold;
  }

  ol {
    display: inline-block;
    margin: 0 0 10px 20px;
    padding: 0;
    > li {
      margin-bottom: 3px;
    }
  }
}
</style>

