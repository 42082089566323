<template>
  <div>
    <el-row v-if="progress_status === 'in_progress' && !after_finish">
      <el-col :md="2">
        <span class="text-header">Arrive : </span>
      </el-col>
      <el-col :md="5">
        <el-date-picker
          class="date-picker-medical"
          type="date"
          placeholder="Pick a day"
          size="large"
          v-model="startDate"
          :clearable="false"
          @change="handleChange"
        ></el-date-picker>
      </el-col>
      <el-col :md="5">
        <el-time-select
          class="date-picker-medical"
          placeholder="Select time"
          size="large"
          format="HH:mm"
          :picker-options="{
            start: '00:00',
            end: '23:59',
            step: '00:01',
          }"
          v-model="startTime"
          :clearable="false"
          @change="handleChange"
        ></el-time-select>
      </el-col>
      <el-col :md="12"></el-col>
    </el-row>
    <el-row v-if="progress_status === 'done' || after_finish">
      <el-col :md="2">
        <span class="text-header">Arrive : </span>
      </el-col>
      <el-col :md="4">
        {{ startDate | toThaiDate }}
      </el-col>
      <el-col :md="2">
        {{ startDate | toHHMM }} น.
      </el-col>
      <el-col :md="2">
        <span class="text-header">Finish : </span>
      </el-col>
      <el-col :md="4">
        {{ endDate | toThaiDate }}
      </el-col>
      <el-col :md="2">
        <div v-if="endDate">{{ endDate | toHHMM }} น.</div>
      </el-col>
      <el-col :md="8">
        <v-select
          v-model="revision"
          label="name"
          :options="revisions"
          :clearSearchOnSelect="true"
          :pushTags="false"
          :filterable="true"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ContentBox from "@/components/container/ContentBox"
import moment from "moment"
import { toThaiDate, toHHMM } from "@/filters/datetime"
import { combineDateTime } from "@/utils/datetime"

export default {
  props: ['datedata', 'revision_set', 'watching_revision', 'progress_status', 'after_finish'],
  components: {
    ContentBox
  },
  data() {
    return {
      startDate: this.datedata.arrived,
      startTime: toHHMM(this.datedata.arrived),
      endDate: this.datedata.finished,
      endTime: (this.datedata.finished && moment.isDate(this.datedata.finished)) ? toHHMM(this.datedata.finished) : null,
      finish: this.datedata.isFinish,
      revisions: this.revision_set,
      revision: {}
    }
  },
  computed: {

  },
  methods: {
    handleChange() {
      const timeToDateStart = moment(this.startTime, "HH:mm").toDate()
      const newStartDate = combineDateTime(this.startDate, timeToDateStart)
      if (moment.isDate(newStartDate)) {
        this.datedata["arrived"] = newStartDate
      } else {
        this.datedata["arrived"] = null
      }

      const timeToDateEnd = moment(this.endTime, "HH:mm").toDate()
      const newEndDate = combineDateTime(this.endDate, timeToDateEnd)
      if (moment.isDate(newEndDate)) {
        this.datedata["finished"] = newEndDate
      } else {
        this.datedata["finished"] = null
      }

      this.datedata["isFinish"] = this.finish
      if (this.finish && !moment.isDate(this.endDate)) {
        this.datedata["finished"] = new Date()
        this.endDate = new Date()
        this.endTime = toHHMM(new Date())
      } else if (!this.finish) {
        this.datedata["finished"] = null
        this.endDate = null
        this.endTime = null
      }
    },
    setNewRevision() {
      if (this.revisions && this.revisions.length > 0) {
        this.revisions.forEach(item => {
          item.name = (item.revision === 1 && item.revision + "st" || item.revision === 2 && item.revision + "nd" || item.revision === 3 && item.revision + "rd" || item.revision + "th") + " Version : " + (toThaiDate(item.last_updated))
        })
        if (this.watching_revision) {
          this.revision = this.revisions.find(r => r.id === this.watching_revision.id)
        }
      }
    }
  },
  watch: {
    async revision() {
      if (this.revision.revision) {
        this.$emit('change', this.revision)
      }
    },
    progress_status() {
      this.revision = this.revisions[this.revisions.length - 1]
    },
    revision_set: {
      handler() {
        this.revisions = this.revision_set
        this.setNewRevision()
      },
      deep: true
    }
  },
  mounted() {
    this.setNewRevision()
  }
}
</script>

<style lang="scss" scoped>
.date-picker-medical {
  width: 180px;
}
.text-header {
  width: 60px;
  height: 27px;
  white-space: nowrap;
  font-family: Kanit;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.display-end {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px 0 -10px;
}
.display-date-end {
  display: flex;
  justify-content: flex-end;
}
.checkbox-double-scale {
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
}
</style>


