<template>
  <div id="page">
    <Navbar :profile="profile" @logout="logout"/>
    <div id="main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Navbar from "@/components/navigation/Navbar.vue";

export default {
  components: {
    Navbar
  },
  computed: {
    ...mapGetters({
      profile: "hospital/profile"
    })
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.replace("/login");
    }
  },
  async mounted() {
    if (localStorage.getItem("user_type") == "hospital_admin")
    await this.$store.dispatch("hospital/getProfile");
  }
};
</script>

<style lang="scss" scoped>
#page {
  position: relative;
  width: 100%;
  height: 100%;
  background: #f0f2f5;
  overflow: auto;
}
#main {
  padding-top: 150px;
  margin: 0 auto;
  max-width: 1000px;
}
</style>
