export const login = "/auth/login/";
export const logout = "/auth/logout/";

export const appointments = "/appointment/";

export const medicals = "/medicalrecord/";

export const inventory = "/stock/";

export const petProfiles = "/pet/profiles/";
export const petProfilesPulbic = "/pet/publics/profiles/";
export const petOverview = "/pet/overview/";
export const petCauseOfDeaths = "/pet/causeofdeaths/";
export const petBreeds = "/pet/breeds/";
export const petTypes = "/pet/types/";
export const petExistingSearch = "/pet/existing/search/";
export const petSystemSearch = "/pet/system/search/";
export const petHospital = "/pet/hospital/";
export const petReward = "/pet/rewards/";
export const petRewardRedeem = "/pet/rewards/redeems/";
export const petRewardOverview = "/pet/rewards/overview/";
export const petExpense = "/pet/expense/";

export const owner = "/owner/";
export const ownerProfiles = "/owner/profiles/";
export const ownerRegister = "/owner/register/";
export const ownerOTPVerify = "/otp/verify";
export const ownerLogin = "/auth/owner/login/";
export const ownerProfileMe = "/owner/me/";
export const ownerPet = "/owner/me/pets/";
export const ownerExistingSearch = "/owner/existing/search/";
export const ownerSystemSearch = "/owner/system/search/";
export const ownerHospitalConnection = "/hospital/connection?secret_code="
export const ownerForgetPassword = "/owner/password_recovery/";
export const ownerForgetPasswordOTP = "/otp/verify/";
export const ownerProfileCreate = "/owner/profiles/create/";
export const ownerMeHospital = "/owner/me/hospital/";
export const ownerSocialLine = "/owner/me/line/";
export const ownerSocialOneSignal = "/owner/me/onesignal/";
export const ownerSocialMessenger = "/owner/me/messenger/";
export const ownerInvite = "/owner/invite/";
export const ownerResendOTP = "/owner/register/resend_otp/";
export const ownerCreatePassword = "/owner/create_password/";
export const ownerTimelineMessage = "owner/me/timeline/message/"

export const activityGroups = "/activity/groups/";
export const activity = "/activity/activities/";
export const activityPrintGroup = "/activity/printgroup/";
export const activityOneProduct = "activity/of-product/";
export const activityOneProductFromProduct = "activity/sp-product/";
export const activitySummary = "/activity/summary/";
export const activityrecord = "/activityrecord/records/";
export const activitySettingAll = "/activity/settings/all/";
export const activitySettingAllBulk = "/activity/settings/all/bulk/";

export const hospitalProfile = "/hospital/me/";
export const hospitalQRCode = "/hospital/me/qrcode/";
export const hospitalTodayOverview = "/hospital/me/overview/today/";
export const hospitalVet = "/hospital/vet/";
export const hospitalStaff = "/hospital/staff/";
export const hospitalRegister = "/hospital/register/";
export const hospitalVerify = "/hospital/register/verify/";
export const hospitalForgetPassword = "/hospital/password_recovery/";

export const locationSubdistrict = "/locations/subdistrict/";

export const address = "/address/addresses/";

export const broadcast = "/broadcast"

export const message = "/message"

export const article = "/article"

export const bloodDonation = "/bloodbank/blooddonation/";
export const bloodDonationItem = "/bloodbank/blooddonation/:id/";
export const medicalRecord = "/bloodbank/donationmedicalrecord/";
export const medicalRecordItem = "/bloodbank/donationmedicalrecord/:id/";
export const labExam = "/bloodbank/labexam/";
export const labExamItem = `/bloodbank/labexam/:id/`;
export const donorCare = "/bloodbank/donorcare/";
export const donorCareItem = `/bloodbank/donorcare/:id/`;
export const bloodCollect = "/bloodbank/bloodcollection/";
export const bloodCollectItem = `/bloodbank/bloodcollection/:id/`;
export const bloodCollectionHistory = `/bloodbank/bloodcollectionhistory/`;
export const bloodCollectionHistoryItem = `/bloodbank/bloodcollectionhistory/:id/`;
export const LAB_EXAM_META = `/bloodbank/labexammeta/`;

export const BLOOD_REQUEST = `/bloodbank/bloodrequest/`;
export const BLOOD_REQUEST_ITEM = `/bloodbank/bloodrequest/:id/`;
export const BLOOD_REQUEST_PROFILE = `/bloodbank/bloodrequestprofile/`;
export const BLOOD_REQUEST_PROFILE_ID = `/bloodbank/bloodrequestprofile/:id/`;
export const BLOOD_REQUEST_MEDICAL_RECORD = `/bloodbank/requestmedicalrecord/`;
export const BLOOD_REQUEST_MEDICAL_RECORD_ITEM = `/bloodbank/requestmedicalrecord/:id/`;
export const BLOOD_COLLECT = `/bloodbank/bloodcollection/`;
export const BLOOD_COLLECT_ITEM = `/bloodbank/bloodcollection/:id/`;
export const BLOOD_COLLECTION_HISTORY = `/bloodbank/bloodcollectionhistory/`;
export const BLOOD_COLLECTION_HISTORY_ITEM = `/bloodbank/bloodcollectionhistory/:id/`;
export const BLOOD_REQUEST_HOSPITAL = `/bloodbank/bloodbagrequest/`;
export const BLOOD_REQUEST_HOSPITAL_ITEM = `/bloodbank/bloodbagrequest/:id/`;
export const BLOOD_REQUEST_APPROVAL = `/bloodbank/bloodbagrequestapproval/`;
export const BLOOD_REQUEST_APPROVAL_ITEM = `/bloodbank/bloodbagrequestapproval/:id/`;
export const PRODUCT_REQUEST = `/bloodbank/productrequest/`;
export const PRODUCT_REQUEST_ITEM = `/bloodbank/productrequest/:id/`;
export const CROSSMATCH = `/bloodbank/crossmatch/`;
export const CROSSMATCH_ITEM = `/bloodbank/crossmatch/:id/`;
export const FEEDBACK = `/bloodbank/feedback/`;
export const FEEDBACK_ITEM = `/bloodbank/feedback/:id/`;
export const FEEDBACK_IMAGE = `/bloodbank/feedbackpicture/`;
export const FEEDBACK_IMAGE_ID = `/bloodbank/feedbackpicture/:id/`;
export const RECEIVE_BLOOD_BAG = `/bloodbank/receivebloodbag/`;
export const RECEIVE_BLOOD_BAG_ITEM = `/bloodbank/receivebloodbag/:id/`;
export const RESERVE_BLOOD_BAG = `/bloodbank/reservebloodbag/`;
export const RESERVE_BLOOD_BAG_ITEM = `/bloodbank/reservebloodbagitem/`;
export const RESERVE_BLOOD_BAG_ITEM_ID = `/bloodbank/reservebloodbagitem/:id/`;
export const BLOOD_SUGGEST = `/bloodbank/bloodsuggest/`;
export const BLOOD_SUGGEST_ITEM = `/bloodbank/bloodsuggest/:id/`;
export const HOSPITAL_LIST = `/hospital/hospital/`;
export const DIAGNOSIS = `/bloodbank/diagnosis/`;

export const AI_QUANTITY_PREDICTION = "https://nqh48rassj.execute-api.ap-southeast-1.amazonaws.com/deploy/model/;version2/2";
export const AI_POSSIBILITY_CALCULATION = "https://a34cde3hmi.execute-api.ap-southeast-1.amazonaws.com/;deployment/model3";

export const vetVerification = `/hospital/vet-verification/`;
export const plan = "/plan"

export const reportPetVisitExpenceOverview = "/report/pet/overview/visit-expence/"
export const reportPetVisitExpenceRateSummary = "/report/pet/summary/visit-expence/"
export const reportPetVisitExpenceRateAge = "/report/pet/age/visit-expence/"
export const reportPetVisitExpenceRateBreed = "/report/pet/breed/visit-expence/"
export const reportPetVisitExpenceRatePets = "/report/pet/pets/visit-expence/"
export const reportPetVisitFrequency = "/report/pet/visit/frequencyrate/"
export const reportPetExpenceRate = "/report/pet/expencerate/"
export const reportOwnerVisitExpenceRateSummary = "/report/owner/summary/visit-expence/"
export const reportOwnerVisitExpenceRateAge = "/report/owner/age/visit-expence/"
export const reportOwnerVisitExpenceRateOwners = "/report/owner/owners/visit-expence/"
export const reportOwnerVisitFrequency = "/report/owner/visit/frequencyrate/"
export const reportOwnerExpenceRate = "/report/owner/expencerate/"
export const reportFinanceAndCaseCompare = "/report/financeandcasebymonth/"
export const reportPetAndOwernCompare = "/report/petandownerbymonth/"
export const reportAppointmentCompare = "/report/appointmentbymonth/"
export const reportPetOwners = `/report/pet_owners/`;
export const reportPets = `/report/pets/`;
export const reportBloodbank = `/report/bloodbank/`;

export const productsMinimal = "/product/products/minimal/"
export const productsMinimalRealImage = "/product/products/minimalrealimage/"
export const products = "/product/products/"
export const productType = "/product/type/"
export const productUnit = "/product/unit/"
export const productSummary = "/product/summary/"
export const productMedicationUse = "/product/medication/medicationuse/"
export const productContact = "/product/contact/"

export const receipt = "/receipt/"

export const incomeExpenseOverview = "/incomeexpense/overview/";
export const incomeExpense = "/incomeexpense/";

export const POS = "/pos/standalone/"
export const POS_DETAIL = "/pos/standalone/:id/"
export const POS_OVERVIEW = "/pos/standalone/overview/"

export const Shift = "incomeexpense/hospitalcash/"
export const HEALTH_RECORD = `/health-record/`
