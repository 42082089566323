<template>
  <div class="pet-profile">
    <el-row>
      <el-col :span="4">
        <AvatarWithOwner
          v-if="pet.image"
          :src="pet.image.medium"
          :size="80"
          :ownerSrc="ownerImageSrc"
        />
      </el-col>
      <el-col :span="11">
        <div class="name">{{ pet.name }}</div>
        <div>
          <label class="label">Type:</label>
          <span class="value">
            {{ pet.type.name }}
            <span v-if="pet.breed">| {{pet.breed.name }}</span>
          </span>
        </div>
        <div>
          <label class="label">Pet ID:</label>
          <span class="value">{{ pet.id }}</span>
        </div>
      </el-col>
      <el-col :span="9">
        <div>
          <label class="label">Age:</label>
          <span class="value">{{ pet.birthday | petAge }}</span>
        </div>
        <div>
          <label class="label">Gender:</label>
          <span class="value">{{ pet.gender | petGender }}</span>
        </div>
        <template v-if="owner">
          <div>
            <label class="label">Owner:</label>
            <span class="value">{{ owner | fullname }}</span>
          </div>
          <div>
            <label class="label">Tel:</label>
            <span class="value">{{ owner.profile.reminder_phone | hyphen}}</span>
          </div>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AvatarWithOwner from "@/components/avatar/AvatarWithOwner"

export default {
  props: ["pet", "owner"],
  components: { AvatarWithOwner },
  computed: {
    ownerImageSrc() {
      if (this.owner["image"] && this.owner["image"].medium) {
        return this.owner["image"].medium
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.pet-profile {
  margin: 10px 0;
  .name {
    margin-bottom: 1px;
    font-size: 18px;
    font-weight: bold;
  }
  .label {
    font-size: 14px;
  }
  .value {
    color: #666;
  }
}
</style>

