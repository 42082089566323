<template>
  <div class="hopital-pet-owner-register">
    <Subnavbar>
      <template slot="breadcrumb">
        <ul>
          <li>Edit pet owner profile</li>
        </ul>
      </template>
      <template slot="title">EDIT PET OWNER PROFILE</template>
      <template slot="actions">
        <button class="btn btn-large btn-outline" @click="$router.back()">Cancel</button>
        <button class="btn btn-large btn-primary" style="width: 100px" @click="onVerifyForm">Save</button>
      </template>
    </Subnavbar>

    <ContentBox>
      <h2 class="title">Edit Pet Owner Profile</h2>
      <div class="upload-section">
        <div class="image-container">
          <img v-if="profileImageUrl" class="full-logo" :src="profileImageUrl" alt />
          <img v-else src="@/assets/icons/icon-camera-plus.png" />
          <input type="file" id="file" ref="file" @change="handleFileUpload" />
        </div>
        <span>Profile Picture *</span>
      </div>
      <el-row>
        <el-col class="col" :span="8">
          <h3 class="subtitle">1. General Info</h3>
          <div class="form-wrapper">
            <PetOwnerGeneralInfoForm
              :isEditing="true"
              :firstname="form.owner.profile.firstname"
              :lastname="form.owner.profile.lastname"
              :gender="form.owner.profile.gender"
              :birthdate="form.owner.profile.birthdate"
              :phone="form.owner.phone"
              :phone2="form.owner.profile.phone2"
              :phone3="form.owner.profile.phone3"
              :reminderPhoneField="form.owner.profile.reminderPhoneField"
              :id_card_number="form.owner.profile.id_card_number"
              :email="form.owner.profile.email"
              @change="onOwnerInfoChange"
            />
          </div>
        </el-col>
        <el-col class="col" :span="8">
          <h3 class="subtitle">2. Map</h3>
          <div class="form-wrapper">
            <GoogleMapForm
              v-if="isGoogleMapLoaded"
              :lat="form.address.latitude"
              :lng="form.address.longitude"
              @change="onLocationChange"
            />
          </div>
        </el-col>
        <el-col class="col" :span="8">
          <h3 class="subtitle">3. Address</h3>
          <div class="form-wrapper">
            <PetOwnerAddressForm
              :address1="form.address.address1"
              :address2="form.address.address2"
              :subdistrict="form.address.subdistrict"
              :subdistrictText="form.address.subdistrictText"
              :district="form.address.district"
              :districtText="form.address.districtText"
              :province="form.address.province"
              :provinceText="form.address.provinceText"
              :zipcode="form.address.zipcode"
              @change="onAddressChange"
            />
          </div>
        </el-col>
      </el-row>
    </ContentBox>
    <ConfirmDialog
      :visible="showConfirmDialog"
      @cancel="showConfirmDialog = false"
      @confirm="onConfirmSave"
    >
      <template slot="header">
        <h1 class="title">ยืนยันแก้ไข Pet Owner Profile?</h1>
      </template>
      <template slot="content"></template>
    </ConfirmDialog>
  </div>
</template>

<script>
// import { mapState } from "vuex"
import moment from "moment";
import ContentBox from "@/components/container/ContentBox";
import Subnavbar from "@/components/navigation/Subnavbar";
import PetOwnerGeneralInfoForm from "@/components/pet/PetOwnerGeneralInfoForm";
import GoogleMapForm from "@/components/pet/GoogleMapForm";
import PetOwnerAddressForm from "@/components/pet/PetOwnerAddressForm";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";

export default {
  components: {
    ContentBox,
    Subnavbar,
    PetOwnerGeneralInfoForm,
    GoogleMapForm,
    PetOwnerAddressForm,
    ConfirmDialog
  },
  data() {
    return {
      isGoogleMapLoaded: false,
      owner: null,
      form: {
        owner: {
          phone: "",
          profile: {
            image: null,
            firstname: "",
            lastname: "",
            gender: "",
            birthday: null,
            phone2: "",
            phone3: "",
            reminderPhoneField: 1,
            email: "",
            id_card_number: "",
            messenger_reciept_id: "",
            line_id: "",
            hospital_note: "",
            new_address: null
          }
        },
        address: {
          address1: "",
          address2: "",
          subdistrict: "",
          subdistrictText: "",
          district: "",
          districtText: "",
          province: "",
          provinceText: "",
          zipcode: "",
          latitude: 0,
          longitude: 0
        }
      },
      profileImageUrl: null,
      showConfirmDialog: false
    };
  },
  methods: {
    handleFileUpload() {
      if (this.$refs.file.files[0]) {
        this.profileImageUrl = window.URL.createObjectURL(
          this.$refs.file.files[0]
        );
      }
    },
    onOwnerInfoChange(ownerInfo) {
      this.form = {
        ...this.form,
        owner: { profile: ownerInfo }
      };
    },
    onLocationChange(location) {
      this.form = {
        ...this.form,
        address: {
          ...this.form.address,
          latitude: location.lat,
          longitude: location.lng
        }
      };
    },
    onAddressChange(value) {
      this.form = {
        ...this.form,
        address: {
          ...this.form.address,
          address1: value.address1,
          address2: value.address2,
          subdistrict: value.subdistrict,
          subdistrictText: value.subdistrictText,
          district: value.district,
          districtText: value.districtText,
          province: value.province,
          provinceText: value.provinceText,
          zipcode: value.zipcode
        }
      };
    },
    onVerifyForm() {
      if (
        this.form.owner.profile.firstname === "" ||
        this.form.owner.profile.lastname === "" ||
        this.form.owner.profile.gender === "" ||
        this.form.owner.profile.phone === "" ||
        this.form.address.address1 === "" ||
        this.form.address.subdistrict === ""
      ) {
        this.$notify({
          message: "กรุณากรอกข้อมูลให้ครบถ้วน",
          type: "warning"
        });
      } else {
        this.showConfirmDialog = true;
      }
    },
    async onConfirmSave() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        var formData = new FormData();
        formData.append("partial", true);

        if (this.$refs.file.files[0]) {
          formData.append("image", this.$refs.file.files[0]);
        }

        if (!this.form.owner.profile.reminderPhoneField) {
          this.form.owner.profile.reminderPhoneField = 1;
        }

        if (
          this.form.owner.profile.birthdate !== undefined &&
          this.form.owner.profile.birthdate !== null &&
          this.form.owner.profile.birthdate !== ""
        ) {
          this.form.owner.profile.birthdate = moment(this.form.owner.profile.birthdate).format("YYYY-MM-DD")
        } else {
          this.form.owner.profile.birthdate = null
        }

        if (this.owner.profile.addresses.length) {
          const addressId = this.owner.profile.addresses[0].id;
          await this.$store.dispatch("address/updateAddress", {
            params: { id: addressId },
            data: {
              address1: this.form.address.address1,
              subdistrict: this.form.address.subdistrict,
              zipcode: this.form.address.zipcode,
              latitude: this.form.address.latitude,
              longitude: this.form.address.longitude
            }
          });
        } else {
          const createdAddress = await this.$store.dispatch("address/createAddress", {
            data: {
              address1: this.form.address.address1,
              subdistrict: this.form.address.subdistrict,
              zipcode: this.form.address.zipcode,
              latitude: this.form.address.latitude,
              longitude: this.form.address.longitude
            }
          });
          this.form.owner.profile.new_address = createdAddress.id;
        }

        formData.append("profile", JSON.stringify(this.form.owner.profile));

        await this.$store.dispatch("owner/updateProfileById", {
          params: { id: this.owner.id },
          formData
        });

        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        this.showConfirmDialog = false;
        this.$notify({
          message: "แก้ไข Pet Owner Profile เรียบร้อยแล้ว",
          type: "success"
        });
        this.$router.push({
          name: "hospitalPetOwnerProfile",
          params: { id: this.owner.id }
        });
      } catch (error) {
        this.showConfirmDialog = false;
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        if (error && error.phone) {
          this.$notify({
            message: "เบอร์ติดต่อมีอยู่ในระบบเรียบร้อยแล้ว",
            type: "warning"
          });
          return;
        } else if (error && error.email) {
           this.$notify({
            message: "อีเมลที่คุณกรอกไม่ถูกต้อง",
            type: "warning"
          })
          return
        }
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    assignOwnerDataToForm(owner) {
      if (owner.profile.image && owner.profile.image.medium) {
        this.profileImageUrl = owner.profile.image.medium;
      }
      this.form = {
        ...this.form,
        owner: {
          phone: owner.phone,
          profile: {
            image: null,
            firstname: owner.profile.firstname,
            lastname: owner.profile.lastname,
            gender: owner.profile.gender,
            birthdate: owner.profile.birthdate,
            phone2: owner.profile.phone2,
            phone3: owner.profile.phone3,
            reminderPhoneField: owner.profile.reminder_phone_field,
            email: owner.profile.email,
            id_card_number: owner.profile.id_card_number,
            hospital_note: owner.profile.note,
            new_address: null
          }
        }
      };

      if (owner.profile.addresses && owner.profile.addresses.length > 0) {
        const ownerAddress = owner.profile.addresses[0];
        this.form = {
          ...this.form,
          address: {
            address1: ownerAddress.address1 || "",
            address2: ownerAddress.address2 || "",
            subdistrict: ownerAddress.subdistrict
              ? ownerAddress.subdistrict.id
              : "",
            subdistrictText: ownerAddress.subdistrict
              ? ownerAddress.subdistrict.name_th
              : "",
            district: ownerAddress.district ? ownerAddress.district.id : "",
            districtText: ownerAddress.district
              ? ownerAddress.district.name_th
              : "",
            province: ownerAddress.province ? ownerAddress.province.id : "",
            provinceText: ownerAddress.province
              ? ownerAddress.province.name_th
              : "",
            zipcode: ownerAddress.zipcode || "",
            latitude: ownerAddress.latitude ? ownerAddress.latitude : 0,
            longitude: ownerAddress.longitude ? ownerAddress.longitude : 0
          }
        };
      }
      this.isGoogleMapLoaded = true;
    }
  },
  async created() {
    try {
      const id = this.$route.params.id;
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      this.owner = await this.$store.dispatch("owner/getOwnerProfileById", {
        params: { id: id }
      });
      this.assignOwnerDataToForm(this.owner);
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    } catch (error) {
      await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
    }
  }
};
</script>

<style lang="scss">
.hopital-pet-owner-register {
  .title {
    margin: 0;
    padding: 0;
    font-size: 1.4em;
    text-align: center;
    font-weight: bold;
  }
  .subtitle {
    margin: -26px 0 0 -10px;
    padding: 0;
    font-size: 1.2em;
    font-weight: normal;
  }
  .col {
    border-left: 6px solid #ccc;
    min-height: 480px;
  }
  .form-wrapper {
    padding: 10px;
  }
  .upload-section {
    padding: 10px 0 30px 0;
    text-align: center;
  }
  .image-container {
    position: relative;
    height: 80px;
    width: 80px;
    border-radius: 60px;
    background-color: #d8d8d8;
    display: block;
    margin: 8px auto;
    img {
      display: block;
      width: 40px;
      height: 40px;
      padding: 20px;
      object-fit: contain;
      &.full-logo {
        width: 80px;
        height: 80px;
        padding: 0px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    input {
      height: 80px;
      width: 80px;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }
}
</style>
