<template>
  <div class="appt-detail-page">
    <div class="screen-section">
      <ApptDetailNavbar
        :appt="appt"
        :apptState="apptState"
        @actions="onActions"
        :isPostponeActive="isPostponeActive"
      />
      <div v-if="appt">
        <ContentBox v-if="isPostponeActive">
          <PostponeForm
            :showCloseIcon="true"
            :requestDate="getRequestPostponeDate"
            @close="isPostponeActive = false"
            @confirm="onPostponeConfirm"
          />
        </ContentBox>
        <ContentBox>
          <ApptFullDetail
            :appt="appt"
            @addnote="handleAddFollowUpNoteClick"
          ></ApptFullDetail>
        </ContentBox>
        <div v-if="appt.timeline?.length > 0">
          <ApptTimeline :appt="appt" />
        </div>
      </div>
      <div v-else>
        <DataLoading />
      </div>
      <ConfirmDialog
        :visible="showCancelDialog"
        @cancel="showCancelDialog = false"
        @confirm="updateAppointment({ action: 'cancel' })"
      >
        <template slot="header">
          <h1 class="title">คุณต้องการยกเลิกนัด?</h1>
        </template>
        <template slot="content">
          <P>เมื่อกด confirm แล้ว จะไม่สามารถแก้ไขได้ในภายหลัง</P>
        </template>
      </ConfirmDialog>
      <ConfirmDialog
        :visible="showConfirmDialog"
        @cancel="showConfirmDialog = false"
        @confirm="updateAppointment({ action: 'confirm' })"
      >
        <template slot="header">
          <h1 class="title">ยืนยันการนัดหมายครั้งนี้?</h1>
        </template>
      </ConfirmDialog>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters } from "vuex";
import medicalType from "@/constants/medical/medical-type.js";
import ApptDetailNavbar from "@/components/appointment/ApptDetailNavbar";
import ApptFullDetail from "@/components/appointment/ApptFullDetail";
import ApptTimeline from "@/components/appointment/ApptTimeline";
import ContentBox from "@/components/container/ContentBox";
import PostponeForm from "@/components/appointment/PostponeForm";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import DataLoading from "@/components/loading/DataLoading";
import { showFollowUpNoteDialog } from "@/components/dialog/appointment";

export default {
  components: {
    ApptDetailNavbar,
    ApptFullDetail,
    ApptTimeline,
    ConfirmDialog,
    ContentBox,
    DataLoading,
    PostponeForm,
  },
  data() {
    return {
      showCancelDialog: false,
      showConfirmDialog: false,
      isPostponeActive: false,
      medicalType: medicalType,
    };
  },
  computed: {
    ...mapState({
      appt: (state) => state.appointment.selected,
    }),
    ...mapGetters({
      apptState: "appointment/apptState",
    }),
    getRequestPostponeDate() {
      if (this.appt.status === "request_postpone") {
        return new Date(this.appt.request_postpone.postpone_request_datetime);
      }
      return new Date();
    },
  },
  methods: {
    onActions(action) {
      const goToPrintPage = (name, params) => {
        let routeData = this.$router.resolve({
          name,
          params,
        });
        window.open(routeData.href, "_blank");
      };

      switch (action) {
        case "cancel":
          this.showCancelDialog = true;
          break;
        case "postpone":
          this.isPostponeActive = !this.isPostponeActive;
          break;
        case "confirm":
          this.showConfirmDialog = true;
          break;
        case "done":
          this.$router.push({
            name: "medicals-markdone-appointment",
            params: { type: medicalType.TYPE_APPOINTMENT, id: this.appt.id },
          });
          break;
        case "print/a5":
          goToPrintPage("appointment-detail-pdf", { id: this.appt.id });
          break;
        case "print/slip":
          goToPrintPage("appointment-print-slip", { id: this.appt.id });
          break;
      }
    },
    async onPostponeConfirm(data) {
      if (data.date) {
        if (moment().isBefore(data.date)) {
          const updatedData = {
            action: "postpone",
            date_time: data.date,
          };
          this.updateAppointment(updatedData);
        } else {
          this.$notify({
            message: "กรุณาเลือกระบุวันเวลานัดมากกว่าเวลาปัจจุบัน",
            type: "warning",
          });
        }
      } else {
        this.$notify({
          message: "กรุณาเลือกระบุวันเวลานัด",
          type: "warning",
        });
      }
    },
    async updateAppointment(data) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("appointment/updateAppointment", {
          params: { id: this.appt.id },
          data: data,
        });
        await this.$store.dispatch("appointment/getAppointment", {
          params: { id: this.appt.id },
        });
        this.showCancelDialog = false;
        this.showConfirmDialog = false;
        this.isPostponeActive = false;
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },

    async handleAddFollowUpNoteClick() {
      const addFollowUpNote = async (id, note) => {
        try {
          this.$store.dispatch("ui/setIsFullScreenLoading", true);

          await this.$store.dispatch("appointment/addFollowUpNote", {
            params: { id: id },
            data: { note: note },
          });

          this.$notify.success("บันทึกข้อมูลเรียบร้อยแล้ว");

          this.$router.go();
        } catch (e) {
          this.$notify.error(e.detail);
        } finally {
          this.$store.dispatch("ui/setIsFullScreenLoading", false);
        }
      };

      const { action, note } = await showFollowUpNoteDialog({
        note: this.appt.follow_up_note,
      });

      switch (action) {
        case "confirm":
          await addFollowUpNote(this.$route.params.id, note);
      }
    },

    async fetchData(id) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);

        // if (this.$route.query.type === "requestApp") {
        //   await this.$store.dispatch("appointment/getRequestAppointmentById", {
        //     params: { id: id },
        //   });
        // } else {
          await this.$store.dispatch("appointment/getAppointment", {
            params: { id: id },
          });
        // }

        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    },
  },
  async created() {
    const id = this.$route.params.id;
    this.fetchData(id);
  },
  watch: {
    "$route.params.id"(newId) {
      this.fetchData(newId); // if id changes, will refectch data
    },
  },
};
</script>

<style lang="scss" scoped>
.appt-detail-page {
  margin: 0 auto;
  width: 620px;
}
</style>
