<template>
  <div style="font-family:'Kanit',serif;color:black;">
    <Subnavbar>
      <template slot="title">Appointment List</template>
      <template slot="actions">
        <button
          class="btn btn-large btn-appointment action-button "
          @click="$router.push({ name: 'appointment-select-pet' })"
        >
          <i class="fa fa-plus-circle"></i>Appointment
        </button>
        <button
          class="btn btn-large btn-new-pet action-button "
          v-show="currentPlan.hospital_feature.can_create_owner_and_pet"
          @click="$router.push({ name: 'hospitalPetsSearch' })">
          <i class="fa fa-plus-circle"></i>New Pet
        </button>
        <button class="btn btn-large btn-calendar action-button " @click="$router.push({ name: 'calendar' })">
          <i class="fa fa-calendar-alt"></i>Calendar
        </button>
        <button class="btn btn-large btn-pet-profile action-button " @click="$router.push({ name: 'hospitalPets' })">
          <i class="fa fa-list-alt"></i>Pet Profile
        </button>
      </template>
    </Subnavbar>

    <ApptOverview
      :isLoading="isOverviewLoading"
      :overview="overview"
      :currentDate="overviewDate"
      @changePrevMonth="changePrevMonth"
      @changeNextMonth="changeNextMonth"
    />

    <ApptSearch
      :search="searchForm.search"
      :startDate="searchForm.start"
      :endDate="searchForm.end"
      :status="searchForm.status"
      :vets="vets"
      :selectedVetId="searchForm.vetId"
      :activityGroups="activityGroups"
      :selectedActivityGroupId="searchForm.activityGroupId"
      @search="handleSearch"
    />

    <ContentBox>
      <template v-if="appts && appts.results">
        <ApptTable
          :appts="appts"
          :total="appts.count"
          :current-page="currentPage"
          @current-change="pageChange"
        />
      </template>
    </ContentBox>
  </div>
</template>

<script>
import { mapState } from "vuex"
import ContentBox from "@/components/container/ContentBox.vue"
import Subnavbar from "@/components/navigation/Subnavbar.vue"
import ApptOverview from "@/components/appointment/ApptOverview.vue"
import ApptSearch from "@/components/appointment/ApptSearch.vue"
import ApptTable from "@/components/appointment/ApptTable.vue"
import _ from 'lodash'

export default {
  components: {
    ContentBox,
    Subnavbar,
    ApptOverview,
    ApptSearch,
    ApptTable
  },
  data() {
    return {
      currentPage: 1,
      isOverviewLoading: false,
      overviewDate: new Date(),
      searchForm: {
        search: '',
        start: '',
        end: '',
        status: '',
        vetId:'',
        activityGroupId: ''
      }
    }
  },
  computed: {
    ...mapState({
      appts: state => state.appointment.all,
      overview: state => state.appointment.overview,
      vets: state => state.hospital.vets,
      activityGroups: state => state.activity.groups,
      currentPlan: state => state.plan.currentPlan
    })
  },
  methods: {
    handleSearch(form) {
      let query = {}
      this.currentPage = 1
      query['page'] = 1
      if (form.search !== '') query['search'] = form.search
      if (form.start && form.start !== '') query['start'] = form.start
      if (form.end && form.end !== '') query['end'] = form.end
      if (form.status !== '') query['status'] = form.status
      if (form.vetId !== '') query['vet__id'] = form.vetId
      if (form.activityGroupId !== '') query['activities__activity__sub_group__group__id'] = form.activityGroupId

      this.fetchAppointments(query)
    },
    pageChange(page) {
      let query = this.getQueryString()
      query = { ...query, page }
      this.currentPage = page
      this.fetchAppointments(query)
    },
    changePrevMonth() {
      const t = this.overviewDate.setMonth(this.overviewDate.getMonth() - 1)
      this.overviewDate = new Date(t)
      this.fetchOverview()
    },
    changeNextMonth() {
      const t = this.overviewDate.setMonth(this.overviewDate.getMonth() + 1)
      this.overviewDate = new Date(t)
      this.fetchOverview()
    },
    async fetchOverview () {
      const y = this.overviewDate.getFullYear()
      const m = this.overviewDate.getMonth()
      const firstDay = new Date(y, m, 1)
      const lastDay = new Date(y, m + 1, 0,23,59,59,999)

      try {
        this.isOverviewLoading = true
        await this.$store.dispatch("appointment/getOverview", { query: {
          start: firstDay,
          end: lastDay
        }})
        this.isOverviewLoading = false
      } catch (error) {
        this.isOverviewLoading = false
         this.$notify({
          message: `พบปัญหาการโหลดข้อมูล overview`,
          type: "error"
        })
      }
    },
    getQueryString() {
      let query = {}

      if (this.$route.query.page) {
        query["page"] = parseInt(this.$route.query.page)
        this.currentPage = parseInt(this.$route.query.page)
      } else {
        this.currentPage = 1
      }

      if (this.$route.query.search) {
        this.searchForm.search = this.$route.query.search
        query["search"] = this.$route.query.search
      }

      if (this.$route.query.start) {
        const start = new Date(parseInt(this.$route.query.start))
        query["start"] = start
        this.searchForm.start = start
      }

      if (this.$route.query.end) {
        const end = new Date(parseInt(this.$route.query.end))
        query["end"] = end
        this.searchForm.end = end
      }

      if (this.$route.query.status) {
        query["status"] = this.$route.query.status
        this.searchForm.status = this.$route.query.status
      }

      if (this.$route.query.vetId) {
        query["vet__id"] = parseInt(this.$route.query.vetId)
        this.searchForm.vetId = parseInt(this.$route.query.vetId)
      }

      if (this.$route.query.activityGroupId) {
        query["activities__activity__sub_group__group__id"] = parseInt(this.$route.query.activityGroupId)
        this.searchForm.activityGroupId = parseInt(this.$route.query.activityGroupId)
      }

      return query
    },
    async fetchAppointments (query) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("activity/listActivityGroups")
        await this.$store.dispatch("hospital/listVets")
        await this.$store.dispatch("appointment/listAppointments", {
          query
        })

        let urlQuery = { ...query }
        if (query.start) urlQuery['start'] = query.start.getTime()
        if (query.end) urlQuery['end'] = query.end.getTime()
        if (query["vet__id"]) {
          urlQuery =  _.omit(urlQuery, ["vet__Id"])
          urlQuery['vetId'] = query["vet__id"]
        }

        if (query["activities__activity__sub_group__group__id"]) {
          urlQuery =  _.omit(urlQuery, ["activities__activity__sub_group__group__id"])
          urlQuery['activityGroupId'] = query["activities__activity__sub_group__group__id"]
        }

        this.$router.replace({ query: urlQuery })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
      }
    }
  },
  async created() {
    this.fetchOverview()
    this.fetchAppointments(this.getQueryString())
  }
}
</script>

<style lang="scss" scoped>
.box {
  margin-bottom: 15px;
}
.box-data-table {
  padding: 20px;
}

.action-button {
  font-family: Arial;
  font-size: 14px;
  font-weight: bolder;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.41px;
  text-align: center;
  color:black;
}
</style>


