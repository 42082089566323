<template>
  <div style="font-family:'Kanit',serif;color:black;">
    <Subnavbar>
      <template slot="title">OPD Record List</template>
      <template slot="actions">
        <button
          class="btn btn-large btn-medical action-button"
          v-show="currentPlan.opd_section.can_create_opd"
          @click="$router.push({ name: 'medicals-select-pet' })"
        >
          <i class="fa fa-plus-circle"></i>OPD Record
        </button>
        <button
          class="btn btn-large btn-new-pet action-button"
          v-show="currentPlan.hospital_feature.can_create_owner_and_pet"
          @click="$router.push({ name: 'hospitalPetsSearch' })">
          <i class="fa fa-plus-circle"></i>New Pet
        </button>
      </template>
    </Subnavbar>

    <MedicalOverview
      v-if="currentPlan.opd_section.can_view_opd_overview"
      :isLoading="isOverviewLoading"
      :currentDate="overviewDate"
      :overview="overview"
      @changePrevMonth="changePrevMonth"
      @changeNextMonth="changeNextMonth"
    />

    <MedicalSearch
      :search="searchForm.search"
      :startDate="searchForm.start"
      :endDate="searchForm.end"
      :status="searchForm.status"
      :vets="vetsForFilter"
      :selectedVetId="searchForm.vetId"
      :is-bad-debt="searchForm.isBadDebt"
      @search="handleSearch"
    />

    <ContentBox>
      <template v-if="medicals && medicals.results">
        <MedicalTable
          :medicals="medicals"
          :total="medicals.count"
          :current-page="currentPage"
          :resultTitle="medicals.count + ' RESULT'"
          :can-export="currentPlan.export_section.can_export_opd"
          @current-change="pageChange"
          @export-data="showExportDialog"
        />
      </template>
    </ContentBox>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { medicalStatus } from '../../constants'
import { ExportService } from "@/services"
import { download } from "@/utils"
import ContentBox from "@/components/container/ContentBox"
import Subnavbar from "@/components/navigation/Subnavbar"
import MedicalOverview from "@/components/medical/MedicalOverview"
import MedicalSearch from "@/components/medical/MedicalSearch"
import MedicalTable from "@/components/medical/MedicalTable"
import { showExportOPDDialog } from "@/components/dialog/export-opd"
import _ from 'lodash'

export default {
  components: {
    ContentBox,
    Subnavbar,
    MedicalOverview,
    MedicalSearch,
    MedicalTable
  },
  data() {
    return {
      currentPage: 1,
      overviewDate: new Date(),
      isOverviewLoading: false,
      vetsForFilter: [],
      searchForm: {
        search: '',
        start: '',
        end: '',
        status: '',
        vetId:'',
        isBadDebt: undefined
      }
    }
  },
  computed: {
    ...mapState({
      medicals: state => state.medical.all,
      overview: state => state.medical.overview,
      vets: state => state.hospital.vets,
      staffs: state => state.hospital.staffs,
      currentPlan: state => state.plan.currentPlan,
    })
  },
  methods: {
    handleSearch(form) {
      let query = {}
      this.currentPage = 1
      query['page'] = 1
      if (form.search !== '') query['search'] = form.search
      if (form.start && form.start !== '') query['start'] = form.start
      if (form.end && form.end !== '') query['end'] = form.end
      if (form.status !== '') {
        if (form.status === medicalStatus.PAYMENT) {
          query["payment_status"] = form.status
        } else {
          query["overall_status"] = form.status
        }
      }
      if (form.vetId !== '') query['vet_id'] = form.vetId
      if (form.isBadDebt !== '') query['is_bad_debt'] = form.isBadDebt

      this.fetchMedicals(query)
    },
    pageChange(page) {
      let query = this.getQueryString()
      query = { ...query, page }
      this.currentPage = page
      this.fetchMedicals(query)
    },
    changePrevMonth() {
      const t = this.overviewDate.setMonth(this.overviewDate.getMonth() - 1)
      this.overviewDate = new Date(t)
      this.fetchOverview()
    },
    changeNextMonth() {
      const t = this.overviewDate.setMonth(this.overviewDate.getMonth() + 1)
      this.overviewDate = new Date(t)
      this.fetchOverview()
    },
    async fetchOverview () {
      const y = this.overviewDate.getFullYear()
      const m = this.overviewDate.getMonth()
      const lastDay = new Date(y, m + 1, 0,23,59,59,999)
      const start = y + '-' + (m+1) + '-' + '1' + 'T00:00:00'
      const end = y + '-' + (m+1) + '-' + lastDay.getDate() + 'T23:59:59'
      try {
        this.isOverviewLoading = true
        await this.$store.dispatch("medical/getOverview", { query: {
          start: start,
          end: end
        }})
        this.isOverviewLoading = false
      } catch (error) {
        this.isOverviewLoading = false
         this.$notify({
          message: `พบปัญหาการโหลดข้อมูล overview`,
          type: "error"
        })
      }
    },
    getQueryString() {
      let query = {}

      if (this.$route.query.page) {
        query["page"] = parseInt(this.$route.query.page)
        this.currentPage = parseInt(this.$route.query.page)
      } else {
        this.currentPage = 1
      }

      if (this.$route.query.search) {
        this.searchForm.search = this.$route.query.search
        query["search"] = this.$route.query.search
      }

      if (this.$route.query.start) {
        const start = new Date(parseInt(this.$route.query.start))
        query["start"] = start
        this.searchForm.start = start
      }

      if (this.$route.query.end) {
        const end = new Date(parseInt(this.$route.query.end))
        query["end"] = end
        this.searchForm.end = end
      }

      if (this.$route.query.overall_status) {
        if (this.$route.query.overall_status === medicalStatus.PAYMENT) {
          query["payment_status"] = this.$route.query.overall_status
        } else {
          query["overall_status"] = this.$route.query.overall_status
        }
        this.searchForm.status = this.$route.query.overall_status
      }

      if (this.$route.query.vetId) {
        query["vet_id"] = this.$route.query.vetId
        this.searchForm.vetId = this.$route.query.vetId
      }

      if (this.$route.query.is_bad_debt) {
        query["is_bad_debt"] = !!this.$route.query.is_bad_debt
        this.searchForm.isBadDebt = !!this.$route.query.is_bad_debt
      }

      return query
    },
    async fetchVetsFilter() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("hospital/listStaff")
        await this.$store.dispatch("hospital/listVets")

        this.vetsForFilter = this.staffs.map((item) => ({
          id: item.id,
          first_name: item.first_name,
          last_name: item.last_name,
          type: 'Staff'
        }))

        this.vetsForFilter.push(
          ...this.vets.map((item) => ({
            id: item.id,
            first_name: item.first_name,
            last_name: item.last_name,
            type: 'VET'
          }))
        )

        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
      }
    },
    async fetchMedicals (query) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("medical/listMedicals", {
          query
        })

        let urlQuery = { ...query }
        if (query.start) urlQuery['start'] = query.start.getTime()
        if (query.end) urlQuery['end'] = query.end.getTime()
        if (query.payment_status) {
          urlQuery["overall_status"] = query.payment_status
          delete urlQuery['payment_status']
        }
        if (query["vet_id"]) {
          urlQuery =  _.omit(urlQuery, ["vet_Id"])
          urlQuery['vetId'] = query["vet_id"]
        }

        this.$router.replace({ query: urlQuery })
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg")
      }
    },

    async showExportDialog() {
      const { start, end } = await showExportOPDDialog();
      this.exportData(start, end);
    },

    async exportData(start, end) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)

        const { data, headers } = await ExportService.exportOPDs({
          ...this.getQueryString(), ...{start: start, end: end}});
        download(data, "opds.xlsx", headers)

        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg")
      }
    },
  },
  async created() {
    this.fetchOverview()
    this.fetchVetsFilter()
    this.fetchMedicals(this.getQueryString())
  }
}
</script>

<style lang="scss" scoped>
.action-button {
  font-family: Arial;
  font-size: 14px;
  font-weight: bolder;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.41px;
  text-align: center;
  /*color: #082f39;*/
  color:black;
}
.box {
  margin-bottom: 15px;
}
.box-data-table {
  padding: 20px;
}
</style>


