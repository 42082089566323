<template>
  <el-card>
    <h1 class='header'>{{(donation && (mode === 'view')) ? `Donation ${donation.bloodDonationId}` : 'Donor Profile'}}</h1>
    <el-row v-if='donation'>
      <el-col :span='3'>
        <img :src='donation.pet.imageUrl' />
      </el-col>
      <el-col :span='7'>
        <el-row>Name {{ donation.pet.name }} </el-row>
        <el-row>{{ donation.pet.type }} </el-row>
        <el-row>H.N. {{ donation.pet.hospitalNumber }} </el-row>
      </el-col>
      <el-col :span='7'>
        <el-row>เพศ: {{ donation.pet.sex }} </el-row>
        <el-row>อายุ: {{ getPetAge(donation.pet.age) }} </el-row>
        <el-row>เจ้าของ: {{ donation.pet.owners.length > 0 ? donation.pet.owners[0].profile.firstname : '' }} {{ donation.pet.owners.length > 0 ? donation.pet.owners[0].profile.lastname : '' }} </el-row>
        <el-row>เบอร์: {{ donation.pet.owners.length > 0 ? donation.pet.owners[0].phone : '' }} </el-row>
      </el-col>
      <el-col :span='7'>
        <el-row>บริจาคล่าสุด: {{ getLastBloodDonateDate }} </el-row>
        <el-row>บันทึกล่าสุด: {{ getLastModifiedDate }} </el-row>
        <el-row>status: {{ donation.pet.status }} </el-row>
      </el-col>
      <el-button type='primary' size='mini' @click="$router.push({ name: 'hospitalPetProfile', params: { id: donation.pet.systemId }})">Pet detail</el-button>
    </el-row>
  </el-card>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex';

export default {
  props: {
    donation: Object,
    mode : {
      default: 'view'
    }
  },
  computed: {
    ...mapState({
      donations: state => state.bloodbank.donations
    }),
    getLastModifiedDate() {
      return this.getFormattedDate(this.donation.modifiedDate)
    },
    getLastBloodDonateDate() {
      let petLastDonationInfo = this.donations.find(d => d.pet.id ? d.pet.id === this.donation.pet.id : false)
      return petLastDonationInfo ? this.getFormattedDate(petLastDonationInfo.created) : '-'
    },
  },
  methods: {
    getFormattedDate(date) {
      if (date == null) {
        return '-'
      }

      let latestDonationDate = new Date(date)
      let day
      let i = latestDonationDate.getDate()
      let j = i % 10
      let k = i % 100

      day = i + 'th'

      if (j === 1 && k !== 11) {
        day = i + 'st'
      }
      if (j === 2 && k !== 12) {
        day = i + 'nd'
      }
      if (j === 3 && k !== 13) {
        day = i + 'rd'
      }

      let currentDate = day + ' ' + moment(latestDonationDate).format('MMM, YY')
      return currentDate
    },

    getPetAge(date) {
      if (date == null) {
        return '-'
      }
      var currentDate = new Date()
      var timeDiff = Math.abs(currentDate.getTime() - date.getTime())
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
      var diffMonth = parseInt(diffDays / 30 % 12)
      var diffYear = parseInt(diffDays / 30 / 12)
      return (diffYear > 0 ? diffYear + ' ปี' : '') + (diffMonth > 0 || diffYear <= 0 ? diffMonth + ' เดือน' : '')
    }
  }

}
</script>

<style scoped>
  .el-card {
    width: 100%;
  }

  .header {
    text-align: center;
  }
</style>
