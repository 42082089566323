<template>
  <div style="font-family:'Kanit',serif;color:black;">
    <Subnavbar>
      <template slot="title">Calendar</template>
      <template slot="actions">
        <button
          class="btn btn-large btn-appointment action-button"
          @click="$router.push({ name: 'appointment-select-pet' })"
        >
          <i class="fa fa-plus-circle"></i>Appointment
        </button>
        <button
          class="btn btn-large btn-new-pet action-button"
          v-show="currentPlan.hospital_feature.can_create_owner_and_pet"
          @click="$router.push({ name: 'hospitalPetsSearch' })">
          <i class="fa fa-plus-circle"></i>New Pet
        </button>
        <button class="btn btn-large btn-calendar action-button"  @click="$router.push({ name: 'calendar' })">
          <i class="fa fa-calendar-alt"></i>Calendar
        </button>
        <button class="btn btn-large btn-pet-profile action-button" @click="$router.push({ name: 'hospitalPets' })">
          <i class="fa fa-list-alt"></i>Pet Profile
        </button>
      </template>
    </Subnavbar>
    <CalendarWithSearch />
  </div>
</template>

<script>
import { mapState } from "vuex"
import Subnavbar from "@/components/navigation/Subnavbar"
import CalendarWithSearch from './CalendarWithSearch'

export default {
  components: {
    Subnavbar,
    CalendarWithSearch
  },
  computed: {
    ...mapState({
      currentPlan: state => state.plan.currentPlan
    })
  },
}
</script>

<style lang="scss" scoped>
.action-button {
  font-family: Arial;
  font-size: 14px;
  font-weight: bolder;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.41px;
  text-align: center;
  /*color: #082f39;*/
  color:black;
}
</style>