import * as vuexHelper from "../helpers/vuexHelper";
import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI
  },
  state: {
    healthForm: {}
  },
  asyncActions: [
    {
      name: "getListPreventionCategory",
      method: "get",
      url: () => `${endpoints.HEALTH_RECORD}prevention-categories/`,
    },
    {
      name: "getListFoodCategory",
      method: "get",
      url: () => `${endpoints.HEALTH_RECORD}food-categories/`,
    },
    {
      name: "getListTags",
      method: "get",
      url: () => `${endpoints.HEALTH_RECORD}tags/?type=1`,
    },
    {
      name: "getListHealthRecord",
      method: "get",
      url: () => `${endpoints.HEALTH_RECORD}`,
    },
    {
      name: "getListHealthRecordById",
      method: "get",
      url: ({ id }) => `${endpoints.HEALTH_RECORD}${id}`,
    },
    {
      name: "saveHealthRecord",
      method: "post",
      url: () => `${endpoints.HEALTH_RECORD}`,
    },
    {
      name: "deleteHealthRecord",
      method: "delete",
      url: ({ id }) => `${endpoints.HEALTH_RECORD}${id}/`,
    },
    {
      name: "updateHealthRecord",
      method: "put",
      url: ({ id }) => `${endpoints.HEALTH_RECORD}${id}/`,
    },
    {
      name: "saveHealthRecordAttachments",
      method: "post",
      url: () => `${endpoints.HEALTH_RECORD}attachments/`,
    },
  
  ]
};

export default vuexHelper.createStoreModule(config);
