<template>
  <div :class="{ 'message-detail-page': true, 'no-subnav': !profile }">
    <Subnavbar v-if="profile">
      <template slot="title">Message Detail</template>
      <template slot="actions"></template>
    </Subnavbar>

    <ContentBox v-if="msg">
      <div class="msg-header">
        <h2 class="title">{{ msg.title }}</h2>
      </div>
      <div class="msg-subheader">
        <el-row>
          <el-col :span="12">
            <div class="hospital">
              <template v-if="getHospital()">
              <img :src="getHospital().logo" v-if="getHospital() && getHospital().logo" class="logo">
              {{ getHospital().name }}
              </template>
              &nbsp;
            </div>
          </el-col>
          <el-col :span="12">
            <div class="date">{{ msg.created | toThaiDate }}</div>
          </el-col>
        </el-row>
      </div>
      <div class="thumbnail" v-if="msg.cover_img">
        <img :src="msg.cover_img" />
      </div>

      <div class="detail" v-if="isHtml" v-html="msg.content"></div>
      <div class="detail" v-else>{{ msg.content }}</div>

      <template v-if="msg.attachments_uri && msg.attachments_uri.length > 0">
        <label>Attachments</label>
        <div class="clearfix">
          <AttachmentPreviewer
            v-for="(url, index) in msg.attachments_uri"
            :key="index"
            style="float: left; width: 110px; height: 110px; margin: 4px;"
            :url="url"
          />
        </div>
      </template>
    </ContentBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Subnavbar from "@/components/navigation/Subnavbar";
import ContentBox from "@/components/container/ContentBox";
import AttachmentPreviewer from "@/components/upload/AttachmentPreviewer";

export default {
  components: {
    ContentBox,
    Subnavbar,
    AttachmentPreviewer
  },
  data() {
    return {
      msg: null,
      isHtml: false
    };
  },
  computed: {
    ...mapState({
      detail: state => state.broadcast.detail,
      profile: state => state.hospital.profile
    })
  },
  methods: {
    getHospital() {
      if (!this.msg) return null

      if (this.msg.hospital) return this.msg.hospital
      else if (this.msg.conversation_room) {
        return {
          'id': this.msg.conversation_room.hospital_id,
          'logo': this.msg.conversation_room.hospital_logo,
          'name': this.msg.conversation_room.hospital_name
        }
      }
    },
    async fetchBroadcastDetail() {
      try {
        const type = this.$route.params.type;
        const id = this.$route.params.id;
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);

        switch (type) {
          case "broadcast_broadcast":
            this.msg = await this.$store.dispatch(
              "broadcast/getPublicBroadcast",
              {
                params: { id }
              }
            );
            break;
          case "broadcast_systembroadcast":
            this.msg = await this.$store.dispatch("broadcast/getPublicSystem", {
              params: { id }
            });
            this.isHtml = true;
            break;
          case "broadcast_supplierbroadcast":
            this.msg = await this.$store.dispatch(
              "broadcast/getPublicSupplier",
              {
                params: { id }
              }
            );
            this.isHtml = true;
            break;
          case "message_message":
            this.msg = await this.$store.dispatch(
              "message/getPublicDirectMessage",
              {
                params: { id }
              }
            );
            break;
          case "article_article":
            this.msg = await this.$store.dispatch(
              "article/getArticleDetail", {
                params: { id }
              }
            );
            this.isHtml = true;
            break;
        }

        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    }
  },
  watch: {
    '$route' (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchBroadcastDetail();
      }
    }
  },
  mounted() {
    this.fetchBroadcastDetail();
  }
};
</script>

<style lang="scss" scoped>
.message-detail-page {
  margin: 0 auto;
  max-width: 600px;

  &.no-subnav {
    margin-top: -70px;
  }
  label {
    color: $light-blue;
  }

  .thumbnail img {
    width: 100%;
  }
  
  .msg-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;

    .title {
      margin: 0;
      font-size: 1.4em;
      text-align: left;
    }
  }

  .msg-subheader {
    padding: 15px 0;
    
    .hospital {
      color: #999;
      .logo {
        width: 30px;
        border-radius: 50%;
        vertical-align: middle;
        border: 1px solid #ccc;
        margin-right: 10px;
      }
    }
    .date {
      text-align: right;
      color: #999;
    }
  }
  .detail {
    min-height: 50px;
    padding: 10px 0;
    margin-bottom: 20px;
    white-space: pre-line;
    color: #333;
    font-family: "Roboto","Lucida Grande","DejaVu Sans","Bitstream Vera Sans",Verdana,Arial,sans-serif;

    :deep(p) {
      strong {
        color: #000;
        font-weight: bolder;
        font-family: "Kanit", sans-serif;
      }
    }

    :deep(img) {
      max-width: 100%;
      height: auto;
    }

    :deep(blockquote) {
        border-left: 4px solid #DDD;
        padding: 5px 15px;
        color: #777;
        background-color: #fff;
    }

    :deep(ol) {
        padding-left: 30px;

        li, dt, dd {

          p {
            margin: 0;
          }

          br {
            display: none;
          }
      }
    }
  }
}
</style>
