<template>
  <div class="hopital-pet-owner-register">
    <Subnavbar>
      <template slot="breadcrumb">
        <ul>
          <li>Register new pet owner</li>
        </ul>
      </template>
      <template slot="title">
        REGISTER NEW PET OWNER
      </template>
      <template slot="actions">
        <button class="btn btn-large btn-outline" @click="$router.back()">Cancel</button>
        <button class="btn btn-large btn-primary" style="width: 100px"
          @click="onVerifyForm"
        >Save</button>
      </template>
    </Subnavbar>

    <ContentBox>
      <h2 class="title">Create Pet Owner Profile</h2>
      <div class="upload-section">
        <div class="image-container">
          <img v-if="profileImageUrl" class="full-logo" :src="profileImageUrl" alt="">
          <img v-else src="@/assets/icons/icon-camera-plus.png">
          <input type="file" id="file" ref="file" @change="handleFileUpload"/>
        </div>
        <span>Profile Picture *</span>
      </div>
      <el-row>
        <el-col class="custom-col" :span="8">
          <h3 class="subtitle">1. General Info</h3>
          <div class="form-wrapper">
            <PetOwnerGeneralInfoForm
              :firstname="form.owner.profile.firstname"
              :lastname="form.owner.profile.lastname"
              :gender="form.owner.profile.gender"
              :birthdate="form.owner.profile.birthdate"
              :phone="form.owner.phone"
              :id_card_number="form.owner.profile.id_card_number"
              :email="form.owner.profile.email"
              :isPhoneExist="isPhoneExist"
              @change="onOwnerInfoChange"
             />
          </div>
        </el-col>
        <el-col class="custom-col" :span="8">
          <h3 class="subtitle">2. Map</h3>
          <div class="form-wrapper">
            <GoogleMapForm
              :lat="form.address.latitude"
              :lng="form.address.longitude"
              @change="onLocationChange"
             />
          </div>
        </el-col>
        <el-col class="custom-col" :span="8">
          <h3 class="subtitle">3. Address</h3>
          <div class="form-wrapper">
            <PetOwnerAddressForm
              :address1="form.address.address1"
              :address2="form.address.address2"
              :subdistrict="form.address.subdistrict"
              :zipcode="form.address.zipcode"
              @change="onAddressChange"
             />
          </div>
        </el-col>
      </el-row>
    </ContentBox>
    <ConfirmDialog
      :visible="showConfirmDialog"
      @cancel="showConfirmDialog = false"
      @confirm="onConfirmSave"
    >
      <template slot="header">
        <h1 class="title">ยืนยันการสร้าง Pet Owner Profile?</h1>
      </template>
      <template slot="content">

      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
// import { mapState } from "vuex"
import ContentBox from "@/components/container/ContentBox"
import Subnavbar from "@/components/navigation/Subnavbar"
import PetOwnerGeneralInfoForm from '@/components/pet/PetOwnerGeneralInfoForm'
import GoogleMapForm from '@/components/pet/GoogleMapForm'
import PetOwnerAddressForm from '@/components/pet/PetOwnerAddressForm'
import ConfirmDialog from "@/components/dialog/ConfirmDialog"
import moment from "moment"

export default {
  components: {
    ContentBox,
    Subnavbar,
    PetOwnerGeneralInfoForm,
    GoogleMapForm,
    PetOwnerAddressForm,
    ConfirmDialog
  },
  data() {
    return {
      form: {
        owner: {
          phone: '',
          profile: {
            image: null,
            firstname: '',
            lastname: '',
            gender: '',
            birthdate: '',
            email: '',
            id_card_number: '',
            messenger_reciept_id: '',
            line_id: '',
            hospital_note: ''
          }
        },
        address: {
          address1: '',
          address2: '',
          subdistrict: '',
          zipcode: '',
          latitude: 0,
          longitude: 0
        }
      },
      isPhoneExist: false,
      profileImageUrl: null,
      showConfirmDialog: false
    }
  },
  methods: {
    handleFileUpload() {
      if (this.$refs.file.files[0]) {
        this.profileImageUrl =  window.URL.createObjectURL(this.$refs.file.files[0])
      }
    },
    onOwnerInfoChange (ownerInfo) {
      this.form = {
        ...this.form,
        owner: { profile: ownerInfo, phone: ownerInfo.phone },
      }
    },
    onLocationChange (location) {
      this.form = {
        ...this.form,
        address: {
          ...this.form.address,
          latitude: location.lat,
          longitude: location.lng
        }
      }
    },
    onAddressChange (value) {
      this.form = {
        ...this.form,
        address: {
          ...this.form.address,
          address1: value.address1,
          address2: value.address2,
          subdistrict: value.subdistrict,
          zipcode: value.zipcode,
        }
      }
    },
    onVerifyForm () {
      if (this.form.owner.profile.firstname === '' || this.form.owner.profile.lastname === '' || this.form.owner.profile.gender === '' ||
          this.form.owner.phone === '' || this.form.address.address1 === '' || this.form.address.subdistrict === ''
      ) {
          this.$notify({
            message: 'กรุณากรอกข้อมูลให้ครบถ้วน',
            type: "warning"
          })
        } else {
          this.showConfirmDialog = true
        }
    },
    async onConfirmSave () {
       try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        if (this.form.owner.profile.birthdate !== undefined && this.form.owner.profile.birthdate !== null && this.form.owner.profile.birthdate !== '') {
          this.form.owner.profile.birthdate = moment(this.form.owner.profile.birthdate).format('YYYY-MM-DD')
        } else {
          this.form.owner.profile.birthdate = null
        }

        const owner = await this.$store.dispatch("owner/createProfile", {
          data: this.form
        })
        if (this.$refs.file.files[0]) {
          var formData = new FormData()
          formData.append('partial', true)
          formData.append('image', this.$refs.file.files[0])
          await this.$store.dispatch("owner/updateProfileById", {
            params: { id: owner.id },
            formData,
          })
        }
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        this.$notify({
          message: 'สร้าง Pet Owner Profile เรียบร้อยแล้ว',
          type: "success"
        })
        this.$router.push({ name: 'hospitalPetOwnerProfile', params: { id: owner.id } })
      } catch (error) {
        this.showConfirmDialog = false
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        if (error && error.phone) {
            this.isPhoneExist = true;
           this.$notify({
            message: 'เบอร์ติดต่อมีอยู่ในระบบเรียบร้อยแล้ว',
            type: "warning"
          })
          return
        } else if (error && error.email) {
           this.$notify({
            message: "อีเมลที่คุณกรอกไม่ถูกต้อง",
            type: "warning"
          })
          return
        }
        await this.$store.dispatch("ui/showFetchErrorMsg")
      }
    },
    getQueryStringFormUrl() {
      this.form.owner.phone = this.$route.query.phone || '';
    }
  },
  watch: {
    'form.owner.phone' (newVal, oldVal) {
      if(newVal !== oldVal && this.isPhoneExist) {
        this.isPhoneExist = false
      }
    },
  },
  async created() {
    this.getQueryStringFormUrl();
  }
}
</script>

<style lang="scss">
  .hopital-pet-owner-register {
    .title {
      margin: 0;
      padding: 0;
      font-size: 1.4em;
      text-align: center;
      font-weight: bold;
    }
    .subtitle {
      margin: -26px 0 0 -10px;
      padding: 0;
      font-size: 1.2em;
      font-weight: normal;
    }
    .custom-col {
      border-left: 6px solid #ccc;
      min-height: 480px;
    }
    .form-wrapper {
      padding: 10px;
    }
    .upload-section {
      padding: 10px 0 30px 0;
      text-align: center;
    }
    .image-container {
      position: relative;
      height: 80px;
      width: 80px;
      border-radius: 60px;
      background-color: #d8d8d8;
      display: block;
      margin: 8px auto;
      img {
        display: block;
        width: 40px;
        height: 40px;
        padding: 20px;
        object-fit: contain;
        &.full-logo {
          width: 80px;
          height: 80px;
          padding: 0px;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      input {
        height: 80px;
        width: 80px;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
      }
    }
  }
</style>
