import { render, staticRenderFns } from "./requestAppTable.vue?vue&type=template&id=572018e0&scoped=true&"
import script from "./requestAppTable.vue?vue&type=script&lang=js&"
export * from "./requestAppTable.vue?vue&type=script&lang=js&"
import style0 from "./requestAppTable.vue?vue&type=style&index=0&id=572018e0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "572018e0",
  null
  
)

export default component.exports