import * as vuexHelper from "../helpers/vuexHelper";

import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI
  },
  state: {
    overview: null,
    all: null,
    timeline: [],
    selected: null,
    pets: [],
    petpublic: null,
    breeds: [],
    types: [],
    searchResults: null,
  },
  asyncActions: [
    {
      name: "getOverview",
      method: "get",
      url: endpoints.petOverview,
      setState: "overview"
    },
    {
      name: "listPetProfiles",
      method: "get",
      url: endpoints.petProfiles,
      setState: "all"
    },
    {
      name: "getPetProfile",
      method: "get",
      url: ({ petHospitalId }) => `${endpoints.petProfiles}${petHospitalId}/`,
      setState: "selected",
      before: (payload, { commit }) => {
        commit('SET_SELECTED', null);
      }
    },
    {
      name: 'getPetByID',
      method: 'get',
      url: ({ id }) => `${endpoints.ownerPet}${id}/`,
      setState: 'selected',
    },
    {
      name: "getPetProfilePublic",
      method: "get",
      url: ({ code }) => `${endpoints.petProfilesPulbic}${code}/`,
      setState: "petpublic",
      before: (payload, { commit }) => {
        commit('SET_PETPUBLIC', null);
      }
    },
    {
      name: 'searchNotOwners',
      method: 'get',
      url: ({ id }) => `${endpoints.petProfiles}${id}/not/owners/`
      // /pet/profiles/{{pet_id_for_add_to_owner}}/not/owners/?search=0862068980
    },
    {
      name: 'existingSearch',
      method: 'get',
      url: endpoints.petExistingSearch,
      onSuccess: (result, payload, { commit }) => {
        if (result) {
          commit('SET_SEARCH_RESULTS', result);
        }
      }
    },
    {
      name: 'systemSearch',
      method: 'get',
      url: endpoints.petSystemSearch,
      onSuccess: (result, payload, { commit }) => {
        if (result) {
          commit('SET_SEARCH_RESULTS', result);
        }
      }
    },
    {
      name: "createPetProfile",
      method: "post",
      url: `${endpoints.petProfiles}`
    },
    {
      name: "updatePetProfile",
      method: "patch",
      url: ({ petHospitalId }) => `${endpoints.petProfiles}${petHospitalId}/`
    },
    {
      name: "deletePetFromHospital",
      method: "delete",
      url: ({ petHospitalId }) => `${endpoints.petProfiles}${petHospitalId}/`
    },
    {
      name: "listPetTimeline",
      method: "get",
      url: ({ petHospitalId }) => `${endpoints.petProfiles}${petHospitalId}/timeline/`,
      setState: "timeline"
    },
    {
      name: "updatePetSterilize",
      method: "patch",
      url: ({ petHospitalId }) => `${endpoints.petProfiles}${petHospitalId}/sterilize/`
    },
    {
      name: "updatePetMicrochip",
      method: "post",
      url: ({ petHospitalId }) => `${endpoints.petProfiles}${petHospitalId}/microchip/`
    },
    {
      name: "updatePetWeight",
      method: "post",
      url: ({ petHospitalId }) => `${endpoints.petProfiles}${petHospitalId}/weight/`
    },
    {
      name: "updatePetBloodGroup",
      method: "post",
      url: ({ petHospitalId }) => `${endpoints.petProfiles}${petHospitalId}/bloodgroup/`
    },
    {
      name: "updatePetDead",
      method: "patch",
      url: ({ petHospitalId }) => `${endpoints.petProfiles}${petHospitalId}/dead/`
    },
    {
      name: "uploadPetImage",
      method: "patch",
      url: ({ petHospitalId }) => `${endpoints.petProfiles}${petHospitalId}/image/`
    },
    {
      name: "uploadSterilizeFile",
      method: "post",
      url: ({ petHospitalId }) => `${endpoints.petProfiles}${petHospitalId}/sterilize/attachments/`
    },
    {
      name: "uploadMicrochipFile",
      method: "post",
      url: ({ petHospitalId }) => `${endpoints.petProfiles}${petHospitalId}/microchip/attachments/`
    },
    {
      name: "uploadDeadFile",
      method: "post",
      url: ({ petHospitalId }) => `${endpoints.petProfiles}${petHospitalId}/dead/attachments/`
    },
    {
      name: "addToOwner",
      method: "post",
      url: ({ petId }) => `${endpoints.petProfiles}${petId}/owners/`
    },
    {
      name: "listBreeds",
      method: "get",
      url: endpoints.petBreeds,
      setState: "breeds"
    },
    // {
    //   name: "listBreedsByTypeKey",
    //   method: "get",
    //   url: ({ typeKey }) => `${endpoints.petBreeds}/?pet_type__key=${typeKey}`,
    //   setState: "breeds"
    // },
    {
      name: "listTypes",
      method: "get",
      url: endpoints.petTypes,
      setState: "types"
    },
    {
      name: "addPetToHospital",
      method: "post",
      url: endpoints.petHospital,
    },
    {
      name: "addPetToHospitalWithPhone",
      method: "post",
      url: ({ phone }) => `${endpoints.petHospital}?phone=${phone}`
    },
    {
      name: "addPetToOwner",
      method: "post",
      url: ({ petId }) => `${endpoints.petProfiles}${petId}/owners/`
    },
    {
      name: "listMessages",
      method: "get",
      url: ({ petHospitalId }) => `${endpoints.petProfiles}${petHospitalId}/message/`
    },
    {
      name: "listTimelineMessages",
      method: "get",
      url: ({ petHospitalId }) => `${endpoints.petProfiles}${petHospitalId}/timeline/message/`
    },
    {
      name: "listTimelineMedicalRecord",
      method: "get",
      url: ({ petId }) => `${endpoints.petProfiles}${petId}/timeline/medicalrecord/`
    },
    {
      name: "listTimelineFinalDiagnosis",
      method: "get",
      url: ({ petId }) => `${endpoints.petProfiles}${petId}/timeline/medicalrecord/finaldiagnosis/`
    },
    {
      name: "listTimelineHospitalHealthBook",
      method: "get",
      url: ({ petId }) => `${endpoints.petProfiles}${petId}/timeline/healthbook/`
    },
    {
      name: "listAppointments",
      method: "get",
      url: ({ petHospitalId }) => `${endpoints.petProfiles}${petHospitalId}/timeline/appointment/`
    },
    {
      name: "listAppointmentsActiveTimeline",
      method: "get",
      url: ({ petHospitalId }) => `${endpoints.petProfiles}${petHospitalId}/timeline/appointment/active/`
    },
    {
      name: "listTimelineHospitalReward",
      method: "get",
      url: ({ petId }) => `${endpoints.petProfiles}${petId}/timeline/rewards/`
    },
    {
      name: "listTimelineHospitalInsurance",
      method: "get",
      url: ({ petId }) => `${endpoints.petProfiles}${petId}/insurances/`
    },
    {
      name: "listPublicTimelineMessages",
      method: "get",
      url: ({ code }) => `${endpoints.petProfilesPulbic}${code}/timeline/message/`
    },
    {
      name: "listPublicTimelineFinalDiagnosis",
      method: "get",
      url: ({ code }) => `${endpoints.petProfilesPulbic}${code}/timeline/medicalrecord/finaldiagnosis/`
    },
    {
      name: "listPublicTimelineHospitalHealthBook",
      method: "get",
      url: ({ code }) => `${endpoints.petProfilesPulbic}${code}/timeline/healthbook/`
    },
    {
      name: "listPublicTimelineHospitalBlood",
      method: "get",
      url: ({ code }) => `${endpoints.petProfilesPulbic}${code}/timeline/blood/`
    },
    {
      name: "listPublicTimelineAppointment",
      method: "get",
      url: ({ code }) => `${endpoints.petProfilesPulbic}${code}/timeline/appointment/`
    },
    {
      name: "listPublicTimelineAppointmentActive",
      method: "get",
      url: ({ code }) => `${endpoints.petProfilesPulbic}${code}/timeline/appointment/active/`
    },
    {
      name: "listPublicTimelineHospitalReward",
      method: "get",
      url: ({ code }) => `${endpoints.petProfilesPulbic}${code}/timeline/rewards/`
    },
    {
      name: "listPublicTimelineInsurance",
      method: "get",
      url: ({ code }) => `${endpoints.petProfilesPulbic}${code}/insurances/`
    },
    {
      name: 'getExpenseYearly',
      method: 'get',
      url: ({ id }) => `${endpoints.petProfiles}${id}/summary/years/`,
    },
    {
      name: 'getExpenseHistory',
      method: 'get',
      url: ({ id }) => `${endpoints.petProfiles}${id}/summary/`,
    },
    {
      name: 'getExpenseOverview',
      method: 'get',
      url: ({ id }) => `${endpoints.petProfiles}${id}/summary/overview/`,
    },
    {
      name: 'getSegmentExpenseList',
      method: 'get',
      url: `${endpoints.petExpense}`,
    },
    {
      name: 'getSegmentExpenseOverview',
      method: 'get',
      url: `${endpoints.petExpense}overview/`,
    },
  ]
};

export default vuexHelper.createStoreModule(config);
