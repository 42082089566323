<template>
  <el-card class="box-card">
    <el-row>
      <el-col :span="4">
        <span class="title">
          [ID: {{request.id}}]
        </span><br>
        <span>
          {{formattedTime}}
        </span><br>
        <el-button class='statusTag' size="mini" type="primary"
          :style="`color: ${statusColor}; background-color: ${statusBackgroundColor}; border-color: ${statusBackgroundColor}`">
          {{statusText}}
        </el-button>
      </el-col>
      <el-col :span="3">
        <span class="header">Recipient</span>
        <div>
          <span class="title">{{request.petInfo.name}}</span><br>
          <span>{{request.petInfo.type}} Weight:{{formattedWeight}}</span>
        </div>
      </el-col>
      <el-col :span="3">
        <span class="header">Owner</span>
        <div>
          <span class="title">{{request.petInfo.owners && request.petInfo.owners.length > 0 ? request.petInfo.owners[0].profile.firstname : '-'}}</span><br>
          <span>{{request.petInfo.owners && request.petInfo.owners.length > 0 ? 'Tel :' + request.petInfo.owners[0].phone : 'Tel : -'}}</span>
        </div>
      </el-col>
      <el-col :span="2">
        <span class="header">Product</span>
        <div>
          <span class="title">{{request.recipientProfileInfo.productRequest ?
          request.recipientProfileInfo.productRequest.selectedBloodProduct : "--"}}</span><br>
          <span>{{request.recipientProfileInfo.productRequest ?
                  request.recipientProfileInfo.productRequest.quantity : "--"}}</span>
        </div>
      </el-col>
      <el-col :span="5">
        <span class="header">From</span>
        <div>
          <span class="title">{{getStepFrom[0]}}</span><br>
          <span>{{getStepFrom[1]}}</span>
        </div>
      </el-col>
      <el-col :span="3">
        <span class="header">Use Date</span>
        <div>
          <span class="title">{{request.recipientProfileInfo.medicalRecord ?
                  request.recipientProfileInfo.medicalRecord
                  .isImmediatelyRequired ?
                  "เร็วที่สุด" :
                  `${moment(
                      request.recipientProfileInfo.medicalRecord.reserveDate
                    ).format("DD MMM YYYY")}` : "--"}}</span><br>
          <span>{{request.reserveBlood ?
                  request.recipientProfileInfo.medicalRecord
                  .isImmediatelyRequired ?
                  "" :
                  `${moment(
                      request.recipientProfileInfo.medicalRecord.reserveDate
                    ).format("HH.mm")} น.` : "--"}}</span>
        </div>
      </el-col>
      <el-col :span="3">
        <el-button size="small"
          @click="$router.push({ name: 'requestTimeline',
          params: { id: request.id },
          props: {statis: statusText} })"
        >
          ดูรายละเอียด
        </el-button>
      </el-col>
      <el-col :span="1">
        <el-button
          v-on:click="toggle"
          :icon="isCollapseFlag?'el-icon-arrow-up':'el-icon-arrow-down'" circle>
        </el-button>
      </el-col>
    </el-row>
    <br>
    <el-row>
      <el-collapse-transition>
        <div v-show="isCollapseFlag">
          <el-steps :active="request.getCurrentProgress()" finish-status="success">
            <el-step v-for='p in request.getProgressList()' :key='p.title' :title="p.title"></el-step>
          </el-steps>
        </div>
      </el-collapse-transition>
    </el-row>
  </el-card>

</template>


<script>
import { mapState } from "vuex"
import moment from 'moment'
import _ from "lodash"
import ContentBox from "@/components/container/ContentBox"

export default {
  components: {
    ContentBox
  },
  props: ["request"],
  data() {
    return {
      isCollapseFlag: false,
      moment:moment
    }
  },
  computed: {
    ...mapState({
      profile: state => state.hospital.profile,
      vets: state => state.hospital.vets,
      hospitals: state => state.hospital.hospitals
    }),
    formattedTime() {
      return moment(this.request.createDate).format('DD MMMM YYYY')
    },
    formattedWeight() {
      return _.ceil(this.request.recipientProfileInfo.medicalRecord.weight, 1)
    },
    statusText() {
      if (this.request.isFeedbackSubmitted) {
        return 'Done'
      } else if (this.request.isTicketRejected) {
        return 'Cancelled'
      } else if (this.request.isBloodBagReceived) {
        return 'รับเลือดแล้ว'
      } else if (this.request.isWithdrawBloodBagReceived) {
        return 'เบิกถุงเลือด'
      } else if (this.request.isCrossmatchSubmitted) {
        return 'Crossmatch'
      } else if (this.request.recipientProfileInfo.isLabExamSubmitted || this.request.recipientProfileInfo.isProductRequestSubmitted || this.request.recipientProfileInfo.isRecipientProfileSubmitted) {
        return 'ยื่นคำขอถุงเลือด'
      }
    },
    statusColor() {
      if (this.request.isFeedbackSubmitted) {
        return '#fff'
      } else if (this.request.isTicketRejected) {
        return '#fff'
      } else if (this.request.isBloodBagReceived) {
        return '#000'
      } else if (this.request.isWithdrawBloodBagReceived) {
        return '#000'
      } else if (this.request.isCrossmatchSubmitted) {
        return '#000'
      } else if (this.request.recipientProfileInfo.isLabExamSubmitted || this.request.recipientProfileInfo.isProductRequestSubmitted || this.request.recipientProfileInfo.isRecipientProfileSubmitted) {
        return '#000'
      }
    },
    statusBackgroundColor() {
      if (this.request.isFeedbackSubmitted) {
        return '#324a5e'
      } else if (this.request.isTicketRejected) {
        return '#ff4f4a'
      } else if (this.request.isBloodBagReceived) {
        return '#90d5e8'
      } else if (this.request.isWithdrawBloodBagReceived) {
        return '#17d2b2'
      } else if (this.request.isCrossmatchSubmitted) {
        return '#7ed321'
      } else if (this.request.recipientProfileInfo.isLabExamSubmitted || this.request.recipientProfileInfo.isProductRequestSubmitted || this.request.recipientProfileInfo.isRecipientProfileSubmitted) {
        return '#f8e71c'
      }
    },
    getStepFrom() {
      let hospitalIdToInfo = _.keyBy(this.hospitals, o => o.id)

      let employeeIdToInfo = _.keyBy(this.vets, o =>  o.id)
      return [  hospitalIdToInfo[this.request.hospitalId] ?
                hospitalIdToInfo[this.request.hospitalId].name : "--"
                ,
               this.request.recipientProfileInfo &&
                this.request.recipientProfileInfo.productRequest &&
                this.request.recipientProfileInfo.productRequest.requester && employeeIdToInfo[this.request.recipientProfileInfo.productRequest.requester] ?
              `${
                  employeeIdToInfo[
                    this.request.recipientProfileInfo.productRequest.requester
                  ].title
                } ${
                  employeeIdToInfo[
                    this.request.recipientProfileInfo.productRequest.requester
                  ].first_name
                } ${
                  employeeIdToInfo[
                    this.request.recipientProfileInfo.productRequest.requester
                  ].last_name
                }` : "--"]
    }
  },
  methods: {
    toggle: function () {
      this.isCollapseFlag = !this.isCollapseFlag
    }
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
.statusTag {
  width: 80%;
}

.title {
  font-weight: bold;
}

.header {
  font-weight: 100;
  font-style: italic;
  border-bottom: 1px solid #ccc;
}

.el-col {
  text-align: center;
  padding: 0px 10px;
  border-left: 1px solid #ccc;
  &:first-child {
    border-left: none;
  }
  height: 75px;
  span {
    vertical-align: middle;
  }
}


</style>
