<template>
  <el-dialog :title="getDialogTitle()" class="payment-method-create-dialog" :visible="visible" width="724px" center @close="$emit('cancel')">
    <div class="content">
        <div class="form-group">
            <label>ช่องทางชำระ (Payment Type) <span class="hint">ช่องทางที่รับเป็นเงินสด ไม่ต้องใส่ข้อมูลธนาคาร</span></label>
            <el-select v-model="form.payment_method_type" placeholder="เลือกช่องทางชำระเงิน">
                <el-option
                v-for="item in paymentTypes"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                </el-option>
            </el-select>
        </div>
        <div class="form-group"
            v-if="getPaymentTypeSelectedObject(form.payment_method_type).allowed_bank">
            <label>ธนาคาร (Bank)</label>
            <el-select v-model="form.bank" placeholder="เลือกธนาคาร">
                <el-option
                v-for="item in banks"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                </el-option>
            </el-select>
        </div>
        <div class="form-group"
            v-if="getPaymentTypeSelectedObject(form.payment_method_type).allowed_bank_account_id">
            <label>เลขที่บัญชี (Bank ID)</label>
            <el-input
                placeholder="เลขที่บัญชี"
                v-model="form.bank_account_id"
                clearable>
            </el-input>
        </div>
        <div class="form-group">
            <label>ชื่อบัญชี (Account Name)</label>
            <el-input
                placeholder="name"
                v-model="form.account_name"
                clearable>
            </el-input>
        </div>
        <div class="form-group line-form-group"
            v-if="getPaymentTypeSelectedObject(form.payment_method_type).allowed_fee_percentage">
            <el-row align="middle">
                <el-col :span="8">
                    <label>มีค่าธรรมเนียม</label>
                    <el-switch v-model="form.has_fee"></el-switch>
                </el-col>
                <el-col :span="16">
                    <label>ค่าธรรมเนียม(%)</label>
                    <el-input-number
                        :disabled="!form.has_fee"
                        v-model="form.fee_percentage" 
                        :min="0" :max="5" :step="0.1">
                    </el-input-number>
                </el-col>
            </el-row>
        </div>
        <div class="form-group">
            <label>ใส่ช่องทางชำระเงิน ถ้าช่องนั้นมี ธนาคาร ชื่อบัญชี เลขที่บัญชี หรือ เลขที่อ้างอิงช่องทางอื่นสามารถใส่ข้อมูลลงมาเพื่อความถูกต้องได้</label>
        </div>
    </div>
    <div slot="footer">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-button type="outline" style="width: 100%;" @click="$emit('cancel')">Cancel</el-button>
        </el-col>
        <el-col :span="12">
          <el-button type="primary" :disabled="!isFormValid" @click="save" style="width: 100%;">Save</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 20px;" v-if="!isTypeCreate()">
          <el-col :span="24">
              <el-button type="outline" class="btn-delete" style="width: 100%;" @click="confirmDeleteVisible = true">Delete payment method</el-button>
          </el-col>
      </el-row>
    </div>


    <el-dialog title="Delete payment method" :visible="confirmDeleteVisible" width="500px" center append-to-body @close="confirmDeleteVisible = false">
        <div class="text-center">ยืนยันการลบวิธีการชำระเงิน</div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="confirmDeleteVisible = false">cancel</el-button>
            <el-button type="primary" @click="$emit('delete', editItemData)">confirm</el-button>
        </span>
    </el-dialog>

  </el-dialog>
</template>

<script>

export default {
props: {
    visible: {
      type: Boolean,
      default: false
    },
    dialogType: {
        type: String,
        default: ''
    },
    banks: {
        type: Array,
        default: null
    },
    paymentTypes: {
        type: Array,
        default: null
    },
    editItemData: {
        type: Object,
        default: null
    }
},
data() {
    return {
        confirmDeleteVisible: false,
        form: {
            payment_method_type: '',
            bank: null,
            bank_account_id: '',
            account_name: '',
            has_fee: false,
            fee_percentage: 0,
        },
    }
},
computed: {
    isFormValid() {
    //   return !!(
    //     this.form.microchip !== "" &&
    //     this.form.microchip_date !== "" &&
    //     moment(this.form.microchip_date).isValid()
    //   );
        return true;
    }
},
methods: {
    getDialogTitle() {
        return this.dialogType == 'create' ? 'Create Payment Method' : 'Edit Payment Method';
    },
    getPaymentTypeSelectedObject(id) {
        for (var i = 0; i < this.paymentTypes.length; i++) {
            if (this.paymentTypes[i]['id'] === id) {
                return this.paymentTypes[i];
            }
        }
        return null;
    },
    isTypeCreate() {
        return this.dialogType == 'create';
    },
    save() {
        const data = {
            ...this.form
        };
        
        const selectType = this.getPaymentTypeSelectedObject(data.payment_method_type);
        if (!selectType.allowed_bank) {
            data.bank = null;
        }
        if (!selectType.allowed_bank_account_id) {
            data.bank_account_id = '';
        }
        if (!selectType.allowed_fee_percentage | !data.has_fee) {
            data.has_fee = false;
            data.fee_percentage = 0;
        }

        if (this.editItemData) {
            this.$emit("edit", data);
        } else {
            this.$emit("create", data);
        }
    },
    restoreObject() {
        this.form.payment_method_type = this.editItemData.payment_method_type.id;
        this.form.bank = (this.editItemData.bank) ? this.editItemData.bank.id : null;
        this.form.bank_account_id = this.editItemData.bank_account_id;
        this.form.account_name = this.editItemData.account_name;
        this.form.has_fee = this.editItemData.has_fee;
        this.form.fee_percentage = this.editItemData.fee_percentage;
    },
    clearFormData() {
        this.form.payment_method_type = this.paymentTypes[0].id;
        this.form.bank = null;
        this.form.bank_account_id = '';
        this.form.account_name = '';
        this.form.has_fee = false;
        this.form.fee_percentage = 0;
    }
},
created() {
    if (this.dialogType == 'create') {
        this.clearFormData();
    } else {
        this.restoreObject();
    }
}
};
</script>


<style lang="scss" scoped>
.payment-method-create-dialog {
    :deep(.el-dialog--center) .el-dialog__body {
        padding: 20px 64px;
    }
    :deep(.el-dialog--center) .el-dialog__footer {
        padding-left: 64px;
        padding-right: 64px;
    }
    .title {
        text-align: center;
        margin-bottom: 10px;
    }
    .check-icon {
        width: 20px;
        height: 15px;
        margin-right: 10px;
    }
    .form-group {
        margin: 10px 0;

        label {
            display: block;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;
            margin-top: 14px;
        }
        label.position-left {
            display: inline;
            margin-right: 24px;
        }

        .el-select {
            width: 100%;
        }
    }
    .line-form-group {
        margin-top: 18px;
    }
    .hint {
        font-size: 12px;
        color: #d0021b;
    }
}

</style>
