<template>
  <div class="pet-detail">
    <div class="form-block" v-if="pet">
      
      <router-link :to="{ name: routeEditPet, params: { id: $route.params.id }}">
        <el-button class="edit-btn" type="default" icon="el-icon-edit" circle></el-button>
      </router-link>
      <img class="cover" src="@/assets/images/common/cover.jpg" />
      <img v-if="pet.rank && pet.rank <= 3"
        class="medal"
        :src="getStatusIcon(pet.rank)"
        alt="Status icon"
      />
      <img class="profile" v-if="pet.image" :src="pet.image" />
      <img class="profile" v-else src="@/assets/icons/icon-camera-plus.png">
      <div class="title">
        {{ `${pet.name}` }}
      </div>
      <div class="subtitle" v-if="pet.type && pet.breed">{{ pet.type.name }} | <span class="c1">{{ pet.breed.name }}</span></div>
      <div class="rectangle">
        <span class="health-point">Health Point: <span class="pet-point">{{pet && pet.point}}</span> <img src="@/assets/images/common/favorite-true.png" style="width: 15px; height: 15px;"></span>
      </div>

      <div class="inner-block">
        <template v-if="showProfile">
          <div class="detail">
            <div class="key">Pet ID</div>
            <div class="value">{{ pet.id }}</div>
          </div>
          <template v-if="pet.hn">
            <div class="detail">
              <div class="key">HN</div>
              <div class="value">{{ pet.hn }}</div>
            </div>
          </template>
          <template v-if="pet.hospitals">
            <div class="detail-image" v-for="hospital in pet.hospitals" :key="hospital.id">
              <img class="image" :src="hospital.logo" style="width:32px;height:32px;">
              <div class="detail" style="width:80%">
                <div class="key">HN.{{ hospital.name }}</div>
                <div class="value">{{ hospital.hn != "" ? hospital.hn : "-" }}</div>
              </div>
            </div>
          </template>
          <div class="divider"></div>
          <div class="detail">
            <div class="key">อายุ / Age</div>
            <div class="value">{{ age }} <span class="sub">({{ birthday }})</span></div>
          </div>
          <div class="detail">
            <div class="key">เพศ / Gender</div>
            <div class="value">
              {{ pet.gender==1 ? 'ผู้ / Male' : '' }}
              {{ pet.gender==0 ? 'เมีย / Female' : '' }}
              {{ pet.gender==-1 ? 'ไม่ระบุ / Unknown' : '' }}
            </div>
          </div>
          <div class="detail">
            <div class="key">ทำหมัน / Sterilize</div>
            <div class="value">{{ pet.sterilize ? `หมัน / Sterilize (${sterilizeDate})` : '-' }}</div>
          <div class="detail">
            <div class="key">น้ำหนัก</div>
            <div class="value">{{ pet.weight && pet.weight != "null" ? pet.weight : '-' }} kg.</div>
          </div>
          </div>
          <div class="divider"></div>
          <div class="detail">
            <div class="key">กรุ๊ปเลือด / Blood Group</div>
            <div class="value">{{ pet.blood_group && pet.blood_group != "null" ? pet.blood_group : '-' }}</div>
          </div>
          <div class="detail">
            <div class="key">รหัสไมโครชิฟ / Microchip</div>
            <div class="value">{{ pet.microchip ? pet.microchip : '-' }}</div>
          </div>
          <div class="detail">
            <div class="key">เลขที่กรมธรรม์ประกันภัย / Insurance No.</div>
            <div class="value">{{ pet.insurance_number ? pet.insurance_number : '-' }}</div>
          </div>
          <div class="divider"></div>
          <div class="detail">
            <div class="key">ประวัติการแพ้ยา / Drug Allergy</div>
            <div class="value">{{ pet.drug_allergy_detail ? pet.drug_allergy_detail : '-' }}</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pet: {
      type: Object
    },
    showProfile: {
      default: false,
      type: Boolean
    },
    routeEditPet: {
      default: "petGeneralInfoEdit",
      type: String
    }
  },
  computed: {
    sterilizeDate() {
      if (this.pet.sterilize_date == null) return "";
      const arr = this.pet.sterilize_date.split("-");
      const year = parseInt(arr[0]) + 543;
      const month = [ "ม.ค.", "ก.พ.", "มี.ค.", "เม.ษ.", "พ.ค.", "มิ.ย.",
        "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค." ][parseInt(arr[1]-1)];
      return `${parseInt(arr[2])} ${month} ${year.toString().slice(-2)}`;
    },
    birthday() {
      if (this.pet.birthdate == null) return "";
      const arr = this.pet.birthdate.split("-");
      const year = parseInt(arr[0]) + 543;
      const month = [ "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน",
        "กรกฏาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม" ][parseInt(arr[1]-1)];
      return `${parseInt(arr[2])} ${month} ${year}`;
    },
    age() {
      if (this.pet.birthdate == null) return;
      var diff = new Date(Date.now()-new Date(this.pet.birthdate));
      if (diff >= 0) {
        return `${diff.getYear()-70} ปี ${diff.getMonth()} เดือน`;
      }
      return `แรกเกิด`;
    }
  },

  methods: {
    getStatusIcon(value) {
      switch (value) {
        case 1:
          return require("@/assets/icons/medal-gold.svg");

        case 2:
          return require("@/assets/icons/medal-silver.svg");

        case 3:
          return require("@/assets/icons/medal-bronze.svg");
      }
    },
  }

}
</script>

<style lang="scss" scoped>
.contain {
  max-width: 320px;
  margin: 0 auto;
  padding: 12px;
}
.form-block {
  @extend %block-container;
  @extend %form-style;

  padding: 3px 6px;
  text-align: center;

  .inner-block {
    padding: 10px 6px;
  }
}
.panel-block {
  @extend %block-container;
  padding: 0px;
  margin-bottom: 10px;

  border: none;
  background: none;
  box-shadow: none;
  
  .el-card {
    box-shadow: none;

  }
}
.footer-block {
  font-size: 14px;
  text-align: center;
  span {
    color: $gray;
  }
  a {
    text-decoration: none;
    color: #000;
  }
}

.detail-image {
  text-align: left;
  vertical-align: middle;
  .image {
    display: inline-block;
  }
  .detail {
    margin-left: 10px;
    display: inline-block;
  }
}
</style>

<style lang="scss" scoped>
.login-optional {
  .el-form-item__content {
    div {
      width: auto;
      display: inline-block;
      vertical-align: middle;

      .el-checkbox__label, a {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 0.1px;
        text-align: center;
        color: #8e8e93;
        text-decoration: none;
      }
    }
    div:first-child {
      float: left;
    }
    div:last-child {
      float: right;
    }
  }
  .el-checkbox__inner {
    border-radius: 8px;
  }
}
button.btn-pet-login {
  width: 213px !important;
  font-size: 17px !important;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #e6eaee;
  margin-bottom: 6px;
}

.split {
  // height: 100%;
  // float: left;
  vertical-align: top;
  display: inline-block;
  // position: fixed;
  // z-index: 1;
  top: 0;
  // overflow-x: hidden;
  // padding-top: 20px;

  &.left {
    left: 0;
    width: 120px;
  }

  &.right {
    right: 0;
  }
}

img.cover {
  position: relative;
  width: 100%;
  height: 100px;
  margin-bottom: -70px;
  margin-top: -14px;
}

img.profile {
  width: 128px;
  height: 128px;
  object-fit: cover;
  border-radius: 60px;

  position: relative;
  z-index: 1;
  
  &.large {
    width: 83px;
    height: 83px;
  }
}

img.medal {
  position: relative;
  height: 2.4em;
  left: -92px;
  top: -18px;
  margin-right: -34px;
}

img.pet-profile {
  display: inline;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
  border: solid 1px #979797;
}

img.gender {
  width: 18px;
  height: 18px;
  object-fit: contain;
}


img.pet-type {
  width: 27px;
  height: 27px;
  object-fit: contain;
}

.title {
  font-family: default;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #354052;

  margin-bottom: 0px;
}

.subtitle {
  margin-top: 0px;
  // margin-bottom: 40px;

  font-family: default;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #7f8fa4;
  .c1 {
    font-weight: normal;
  }
}

.rectangle {
  display: flex;
  margin: 10px 6px;
  align-items: center;
  align-self: center;
  height: 45px;
  border-radius: 5px;
  background-color: #f5f8fa;
}
.health-point {
  margin-left: 15%;
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: #7f8fa4;

  .pet-point {
    margin: 0 20px;
    font-family: Arial;
    font-size: 18px;
    font-weight: Bold;
    color: #d0025b;
  }
}

.social-info {
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;

  margin-bottom: 7px
}

.el-button {
  &.action-btn {
    color: #156f88;
    border-radius: 5px;
    border: none;
    &:not(:hover) {
      background-color: #c2e5f0;
    }
  }

  &.edit-btn {
    position: relative;
    right: 0;
    margin-top: -24px;
    margin-right: -24px;
    @include mobile {
      margin-right: -12px;
    }
    float: right;

    z-index: 1;
  }

  &.connect-btn {
    width: 100%;
    display: block;
    clear: both;

    height: 50px;
    border-radius: 5px;

    font-family: Arial;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;

    margin-left: 0px;
    margin-bottom: 14px;
    // border: solid 2px #e7e7e7;
    // background-color: #0384ff;
  }
}

.el-card__header {
  background-image: linear-gradient(to top, #f4f7fa, #ffffff);
}

.detail {
  text-align: left;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0px;
  }

  .key {
    font-family: default;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #8e8e93;
  }
  .value {
    font-family: default;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #030303;

    &.small {
      font-family: default;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: normal;
      color: #354052;
    }

    &.big {
      font-family: default;
      font-size: 18px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    &.line {
      border-bottom: 1px solid #e6eaee;
    }

    .sub {
      font-size: 16px;
      font-weight: 0;
    }
  }
}
</style>