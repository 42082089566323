<template>
  <div class="appt-reminder">
    <h3 class="label">
      <i class="el-icon-bell" /> เลือกช่องทางการแจ้งแตือน
    </h3>
    <el-row class="credit-row" v-if="currentPlan">
      <el-col :span="12">Your Credit</el-col>
      <el-col :span="12" class="credit-number">{{ currentPlan.credit_section.credit_remain }}</el-col>
    </el-row>
    <template v-if="template && template.before_template">
      <h3 class="label">Before Appointment Date</h3>
      <div v-if="template.before_template.length === 0"> - </div>
      <div class="before-appt-date">
        <div v-for="item in reminderBeforeTemplateValue" :key="item.line_item">
          <h4 class="subtitle">{{ item.name }}</h4>
          <el-checkbox
            label="SMS"
            border
            class="checkbox"
            :checked="item.remind_via_sms"
            @change="(value) => checkReminderBefore({ line_item: item.line_item, remind_via_sms: value})"
            v-if="item.reminder_channel.sms === true"
          ></el-checkbox>
          <el-checkbox
            label="Social"
            border
            class="checkbox"
            :checked="item.remind_via_social"
            @change="(value) => checkReminderBefore({ line_item: item.line_item, remind_via_social: value})"
            v-if="item.reminder_channel.social === true"
          ></el-checkbox>
        </div>
      </div>
    </template>
    <h3 class="label">Select Receive Owner</h3>
    <el-select size="small" v-model="remindTo" style="width: 100%;" placeholder="Select Owner" @change="emitChange">
      <el-option
        style="height: 50px;"
        v-for="owner in owners"
        :key="owner.id"
        :value="owner.id"
        :label="`${owner.profile.firstname} ${owner.profile.lastname} (${owner.profile.reminder_phone})`"
      >
        <el-row style="padding: 5px 0;">
          <el-col :span="6">
            <el-avatar :size="40" :src="owner.profile.image.small" />
          </el-col>
          <el-col :span="18">
            <div
              style="margin-top: 8px; line-height: 1.2em; font-size: .9em;"
            >{{`${owner.profile.firstname} ${owner.profile.lastname}`}}</div>
            <div style="line-height: 1.2em;font-size: .9em;">{{ owner.profile.reminder_phone }}</div>
          </el-col>
        </el-row>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: ["owners", "selectedOwnerId"],
  data() {
    return {
      template: null,
      currentPlan: null,
      reminderBeforeTemplateValue: null,
      remindTo: this.selectedOwnerId
    };
  },
  methods: {
    emitChange() {
      this.$emit('change',{
        reminderBeforeTemplate: this.reminderBeforeTemplateValue,
        selectedOwnerId: this.remindTo
      })
    },
    checkReminderBefore(updateData) {
      this.reminderBeforeTemplateValue = this.reminderBeforeTemplateValue.map(item => {
        if (item.line_item === updateData.line_item) {
          return {
            ...item,
            ...updateData
          };
        }
        return item
      })
      this.emitChange()
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      this.currentPlan = await this.$store.dispatch("plan/getCurrentPlan");
      this.template = await this.$store.dispatch(
        "appointment/getReminderTemplate"
      );
      if (this.template && this.template.before_template) {
        this.reminderBeforeTemplateValue = this.template.before_template.map(
          item => {
            return {
              line_item: item.id,
              name: item.name,
              remind_via_sms: false,
              remind_via_social: false,
              reminder_channel: item.reminder_channel
            };
          }
        );
      }
      this.emitChange()
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    } catch (error) {
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
    }
  },
  watch: {
    'selectedOwnerId' (newValue) {
      this.remindTo = newValue
    }
  }
};
</script>

<style lang="scss" scoped>
.appt-reminder {
  .label {
    margin: 0;
    padding: 5px 5px 5px 0;
    color: $light-blue;
    font-size: 14px;
    font-weight: bold;
  }

  .checkbox {
    margin-right: 5px;
  }

  .subtitle {
    margin: 5px 0;
    font-size: 0.9em;
  }

  .credit-row {
    margin: 5px 0 10px 0;
    border-bottom: 1px solid #ccc;

    .credit-number {
      color: #d0021b;
      text-align: right;
    }
  }

  .before-appt-date {
    margin: 5px 0 10px;
  }
}
</style>