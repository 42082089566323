<template>
  <div class="appointment-full-detail">
    <template v-if="appt">
      <!-- <a
        class="edit-button"
        v-if="canEdit"
        @click="$router.push({
          name: 'appointment-markdone',
          params: { id: parentId },
          query: { markdone_id: appt.id }
        })"
      > -->
      <a
        class="edit-button"
        v-if="canEdit"
        @click="$router.push({ 
          name: 'medicals-markdone-appointment',
          params: { type: medicalType.TYPE_APPOINTMENT, id: parentId }
        })"
      >
        <i class="fa fa-edit"/>
      </a>
      <div class="header" v-if="!isMarkDone">
        <label class="label">Appointment Date</label>
        <div class="appt-date">{{ appt.date_time | toThaiDate }} 
          <template v-if="!appt.is_no_time">{{appt.date_time | toHHMM}} น.</template>
          <template v-else>(ไม่ระบุเวลา)</template>
          </div>
        <div>
          <label class="label">Appointment ID:</label>
          <span class="value">{{ appt.id }}</span>
        </div>
      </div>
      <div class="section" v-if="!isMarkDone">
        <PetProfile2 :pet="appt.pet" :owner="appt.remind_to || appt.owner" />
      </div>
      <div class="section">
        <ActivityList :activities="appt.activities"/>
      </div>
      <div class="section">
        <div class="sub-section">
          <label class="label">
            <img src="@/assets/icons/icon-doctor.png" class="icon">สัตวแพทย์
          </label>
          <p class="value">{{ appt.vet | fullname}}</p>
        </div>
        <div class="sub-section">
          <label class="label">
            <img src="@/assets/icons/icon-notepad.png" class="icon">บันทึกเพิ่มเติม
          </label>
          <p class="value">{{ appt.note | hyphen}}</p>
        </div>
        <div class="sub-section">
          <label class="label">
            <img src="@/assets/icons/icon-info.png" class="icon">หมายเหตุ
          </label>
          <ul class="remark-list" v-if="remarks?.length > 0">
            <li v-for="(remark, index) in remarks" :key="index">{{ remark.note }}</li>
          </ul>
          <div v-if="remarks?.length === 0">-</div>
        </div>
        <div class="sub-section">
          <label class="label">
            <i class="el-icon-bell"
              :style="{ color: '#2198f1' }" />
            การแจ้งเตือนที่เลือกไว้ 
          </label>
          <ReminderTemplate
            v-if="appt && appt.reminder_stores && appt.reminder_stores?.length > 0"
            :style="{ margin: '4px 0 24px 24px' }"
            :reminder-stores="appt.reminder_stores"
          ></ReminderTemplate>
          <div v-else>-</div>
        </div>
      </div>
      <div
        class="section clearfix"
        v-if="this.appt.attachments && this.appt.attachments?.length > 0"
      >
        <FilePreviewer
          style="float: left; width: 110px; height: 110px; margin: 4px;"
          v-for="file in this.appt.attachments"
          :key="file.id"
          :file="file"
        />
      </div>
      <div class="footer" v-if="!isMarkDone">
        <div class="created-date">
          <label class="label">Created Date:</label>
          {{ appt.created | toThaiDate }} {{appt.created | toHHMM}}
        </div>

        <div class="sub-section">
          <label class="label">
            <img src="@/assets/icons/icon-notepad.png" class="icon">บันทึกการติดตามนัดหมาย
            <span v-if="!appt.follow_up_note">
              <el-button
                type="text" 
                @click="handleAddFollowUpNoteClick"
              >+ เพิ่มบันทึก</el-button>
            </span>
            <span v-else>
              <el-button
                type="text" 
                @click="handleAddFollowUpNoteClick"
              >แก้ไขบันทึก</el-button>
            </span>
          </label>
          <p class="value" :style="{ whiteSpace: 'break-spaces' }">{{ appt.follow_up_note | hyphen}}</p>
        </div>

      </div>
    </template>
    <template v-else>
      <DataLoading/>
    </template>
  </div>
</template>

<script>
import _ from "lodash"
import medicalType from '@/constants/medical/medical-type.js'
import ActivityList from "@/components/activity/ActivityList"
import DataLoading from "@/components/loading/DataLoading"
import PetProfile2 from "@/components/pet/PetProfile2"
import FilePreviewer from "@/components/upload/FilePreviewer"
import ReminderTemplate from "@/components/appointment/ReminderTemplate"

export default {
  props: ["appt", "isMarkDone", "canEdit", "parentId"],
  components: {
    ActivityList,
    DataLoading,
    PetProfile2,
    FilePreviewer,
    ReminderTemplate
  },
  data() {
    return {
      medicalType: medicalType
    }
  },
  computed: {
    remarks() {
      let remarks = []
      this.appt.activities.forEach(activity => {
        if (activity.note?.length > 0) {
          return activity.note.forEach(notes => {
            remarks.push(notes)
          })
        }
      })

      return _.uniqBy(remarks, "id")
    }
  },
  methods: {
    async handleAddFollowUpNoteClick() {
      this.$emit("addnote");
    },
  }
}
</script>

<style lang="scss" scoped>
.appointment-full-detail {
  position: relative;
}
.appt-date {
  margin: 5px 0;
  font-size: 20px;
  font-weight: bold;
}
.header,
.footer {
  padding: 10px 0;
}
.section {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;

  &:last-child {
    border-bottom: none;
  }
}
.sub-section {
  margin: 10px 0;
}
.value {
  margin: 5px 0;
  color: #666;
}
.remark-list {
  margin: 10px 0 0 10px;
  padding: 0;
  list-style-type: none;
  text-indent: -10px;
  font-size: 15px;
  color: #666;

  li:before {
    content: "-";
    padding-right: 5px;
  }
}
.created-date {
  color: #666;
}
.edit-button {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  &:hover {
    color: $light-blue;
  }
}
</style>

