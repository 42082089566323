<template>
  <div class="pet-diary-timeline">
    <div v-if="activities.length">
      <el-collapse accordion v-model="activeCollapse">
        <el-collapse-item
          :name="inM"
          v-for="(itMain, inM) in activities"
          :key="inM"
        >
          <template slot="title">
            <div class="px-4">
              {{ itMain.year }}
            </div>
          </template>
          <div class="mt-2">
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in itMain.items"
                :key="index"
                :timestamp="displayDateFormat(activity.date)"
                placement="top"
              >
                <el-card>
                  <div class="container pa-4">
                    <strong style="color: #156f88; font-size: 16px">
                      {{ activity?.type_name }}
                    </strong>

                    <span class="mt-2" v-if="activity?.type === 0">
                      <el-row
                        class="mt-2"
                        v-for="(item, index) in activity?.prevention_items"
                        :key="index"
                      >
                        <el-col class="text-gray text-base">
                          {{ item?.category_name }}
                        </el-col>
                        <el-col class="">
                          ผลิตภัณฑ์ : {{ item?.product_name }}
                        </el-col>
                      </el-row>
                    </span>

                    <span v-else-if="activity?.type === 2">
                      <el-row
                        class="mt-2"
                        v-for="(item, index) in activity?.food_items"
                        :key="index"
                      >
                        <el-col class="text-gray text-base">
                          {{ item?.category_name }}
                        </el-col>
                        <el-col class="">
                          ผลิตภัณฑ์ : {{ item?.product_name }}
                        </el-col>
                      </el-row>
                    </span>

                    <el-row class="mt-2" v-else-if="activity?.type === 4">
                      <el-col class="text-gray text-base">
                        {{ activity?.weight?.weight }} กิโลกรัม
                      </el-col>
                    </el-row>

                    <span v-else>
                      <el-row class="mt-2">
                        <el-col class="text-gray text-base">
                          {{ activity?.detail?.subject }}
                        </el-col>
                      </el-row>
                      <el-row class="mt-2">
                        <el-col>
                          <el-tag
                            v-for="(item, index) in activity?.detail?.tags"
                            :key="index"
                            color="#005972"
                            effect="dark"
                            size="medium"
                          >
                            {{ item }}
                          </el-tag>
                        </el-col>
                      </el-row>
                    </span>

                    <el-row class="mt-2">
                      <el-col class="text-sm text-gray">ข้อมูลเพิ่มเติม</el-col>
                      <el-col>{{ activity?.note || "-" }}</el-col>
                    </el-row>
                    <el-row class="mt-2">
                      <el-col class="text-sm text-gray">เอกสารแนบ</el-col>
                      <el-col
                        v-for="(item, index) in activity.attachments"
                        :key="index"
                      >
                        <el-image
                          style="width: 150px; height: 150px; cursor: zoom-in"
                          :src="item.attachment"
                          fit="cover"
                          @click="$refs.dialogImage.show(item.attachment)"
                        ></el-image>
                      </el-col>
                    </el-row>
                    <el-row class="mt-2">
                      <el-col class="text-sm text-gray"
                        >วันที่ครั้งถัดไป</el-col
                      >
                      <el-col v-if="activity?.next_date">{{
                        displayDateFormat(activity?.next_date)
                      }}</el-col>
                      <el-col v-else>-</el-col>
                    </el-row>
                    <el-row class="mt-2">
                      <el-col class="text-sm text-gray">วันที่แจ้งเตือน</el-col>
                      <el-col v-if="activity?.notify_date">{{
                        displayDateFormat(activity?.notify_date)
                      }}</el-col>
                      <el-col v-else>-</el-col>
                    </el-row>
                    <el-divider class="my-0 py-0"></el-divider>
                    <p class="text-gray">
                      ผู้บันทึก : {{ activity?.owner_name }}
                    </p>
                  </div>
                  <!-- <el-row type="flex"  justify="center">
                    <el-col align="center" :span="24">
                      <el-button
                      style="width: 100%"
                        type="primary"
                        class="w-full"
                        plain
                        @click="
                          $router.push({
                            name: 'petHealthRecoard',
                            params: { id: activity?.id },
                          })
                        "
                        >ดูรายละเอียด</el-button
                      >
                    </el-col>
                  </el-row> -->
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="no-item" v-if="!activities.length">
      <el-row type="flex" class="row" justify="center">
        <el-col align="center" :span="14">
          <img
            width="350px"
            src="@/assets/images/no-result/no-result-04.png"
            class="responsive-img"
          />
          <div class="text-gray text-xl">ไม่มีรายการบันทึก</div></el-col
        >
      </el-row>
    </div>
  </div>
</template>

<script>
import _ from "lodash/";
import { get } from "vuex-pathify";

export default {
  components: {},
  data() {
    return {
      reverse: true,
      activeCollapse: 0,
      activities: [],
    };
  },
  computed: {
    pet: get("pet/selected@pet"),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      const { results } = await this.$store.dispatch(
        "healthRecord/getListHealthRecord",
        {
          query: { pet: this.pet.id, 'share_with_hospital': true },
        }
      );
      this.activities = _.chain(results)
        .groupBy((it) => it.date.substring(4, 0))
        .map((value, key) => ({ year: Number(key) + 543, items: value }))
        .value();
      //   let result = results.reduce(function (r, a) {
      //     let year = r.date.substring(4, 0)
      //     r[year] = r[year] || [];
      //     r[year].push(a);
      //     return r;
      // }, Object.create(null));

      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    },
    displayDateFormat(date) {
      return this.$options.filters.toThaiDate(date);
    },
  },
};
</script>

<style lang="scss" >
.pet-diary-timeline {
  .el-timeline {
    padding: 0px 0px 0px 12px;
    margin: auto;
    overflow: hidden;
  }

  .el-timeline-item__timestamp {
    font-size: 16px;
    font-weight: 800;
  }

  .el-timeline-item__wrapper {
    padding-left: 50px;
  }

  .el-timeline-item__tail {
    left: 3px;
    border-left: 4px solid #e4e7ed;
  }

  .el-timeline-item {
    .el-timeline-item__node--normal {
      left: -12px;
      width: 34px;
      height: 34px;
      background-image: url("../../assets/icons/icon-timeline-vaccine.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .el-divider--horizontal {
    margin: 10px 0;
  }

  .el-tag {
    border: white;
    margin: 2px 2px;
  }

  .el-collapse-item__header {
    background-color: #c3e5f1;
    border-radius: 5px;
    font-size: 18px;
  }
  .text-gray {
    color: #737d89;
  }
}
</style>