<template>
  <div class="pet-search-result" v-if="pets.count > 0">
    <div class="header">{{ pets.count }} PETS RESULT</div>
    <div class="content">
      <table class="pet-table">
        <thead>
          <tr>
            <th class="name">Pet Name</th>
            <th class="type">Type</th>
            <th class="breed">Microchip No.</th>
            <th class="owner">Owner</th>
            <th class="tel-email">Tel</th>
            <th class="tel-email">Health Point</th>
            <th class="action"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="pet in pets.results" :key="pet.id">
            <td>
              <div class="thumbnail">
                <img v-if="pet.image && pet.image.medium" :src="pet.image.medium">
              </div>
              <div class="pet-info">
                 <router-link :to="{ name:'hospitalPetProfile', params: { id: pet.pet_hospital.id }}">
                {{ pet.name }}
                </router-link>
              </div>
            </td>
            <td>
              <PetTypeIcon :type="pet.type.key"/>
              <PetGenderIcon :gender="pet.gender"/>
            </td>
            <td class="text-center">{{ pet.microchip || '-' }}</td>
            <td>
              <template v-if="pet.owners && pet.owners.length > 0">
                <div>
                  <router-link :to="{ name:'hospitalPetOwnerProfile', params: { id: pet.owners[0].id }}">
                  {{ `${pet.owners[0].profile.firstname} ${pet.owners[0].profile.lastname}` }}
                  </router-link>
                </div>
              </template>
            </td>
            <td class="text-center">
              <template v-if="pet.owners && pet.owners.length > 0">
                <div>{{ pet.owners[0].profile.reminder_phone }}</div>
              </template>
              <template v-else>
                <div class="text-center">-</div>
              </template>
            </td>
            <td class="text-center">
             <div>{{ pet.point | number}}</div>
            </td>
            <td class="text-center">
                <button
                  v-if="pet.pet_hospital"
                  class="btn btn-tiny btn-outline"
                  @click="$router.push({ name: 'redeem-pet', params: { id: pet.pet_hospital.id }})"
                >SELECT  <i class="el-icon-arrow-right el-icon-right"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="footer">
      <el-pagination
        background
        @current-change="(page) => $emit('current-change', page)"
        :current-page="currentPage"
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="pets.count"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import PetGenderIcon from "@/components/pet/PetGenderIcon"
import PetTypeIcon from "@/components/pet/PetTypeIcon"

export default {
  components: {
    PetGenderIcon,
    PetTypeIcon
  },
  props: ["pets", "currentPage"],
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>

a {
  color: #333;
  text-decoration: none;
  &:hover {
    color: #02b0e1;
  }
}

.pet-search-result {
  .header {
    margin-bottom: 5px;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
  }

  .footer {
    padding: 20px;
    text-align: center;
  }
}

.pet-table {
  width: 100%;
  border-collapse: collapse;

  thead {
    tr {
      border: #e6eaee 1px solid;

      th {
        padding: 10px 5px;
        font-weight: normal;
        color: #7f8fa4;
        background-color: #f5f8fa;
        font-size: 0.9em;

        &.hn {
          width: 80px;
        }

        &.type {
          width: 100px;
        }

        &.action {
          width: 100px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        position: relative;
        padding: 8px 5px;
        font-size: 0.9em;
        border-bottom: 1px solid #eee;

        .subtitle {
          color: #666;
        }

        .thumbnail {
          display: inline-block;
          width: 50px;
          height: 50px;
          overflow: hidden;
          background: #eee;
          border-radius: 5px;
          margin-right: 10px;
          vertical-align: middle;

          > img {
            width: 50px;
            height: 50px;
            border-radius: 5px;
          }
        }

        .pet-info {
          display: inline-block;
          width: calc(100% - 60px);
          vertical-align: middle;
        }

        .gender-icon {
          margin: 0 5px;
          height: 20px;
          vertical-align: middle;
          opacity: 0.5;
        }
      }
    }
  }
}

.pagination {
  margin-top: 20px;
  text-align: right;
}
</style>
