<template>
  <div>
    <Subnavbar>
      <template slot="title">Request Appointment List</template>
    </Subnavbar>

    <ReqApptSearch v-model="filter"/>

    <RequestAppTable :filter="filter"/>
  </div>
</template>

<script>
import Subnavbar from "@/components/navigation/Subnavbar.vue";
import ReqApptSearch from "@/components/requestAppointment/requestAppSearch.vue"
import RequestAppTable from "@/components/requestAppointment/requestAppTable.vue"
export default {
  components: {
    Subnavbar,
    ReqApptSearch,
    RequestAppTable,
  },
  data() {
    return {
      filter: null
    }
  },
};
</script>

<style lang="scss" scoped>

</style>