<template>
  <div class="row el-row flex-middle lab-slider">
    <div class="col-md-9 col-xs-9 flex-start">
      <label
        v-if="isRequired"
        class="error-field-label"
      >
        {{prefix}}
        <span>*</span>
      </label>
      <label v-if="!isRequired">{{prefix}}</label>
      <tooltip
        mode="tooltip"
        v-if="tooltipObject != null"
        :title="tooltipObject.fullname"
        :tooltip="tooltipObject.tooltip"
        :tooltipContent="tooltipObject.tooltipContent"
      ></tooltip>
      <tooltip
        mode="alert"
        v-if="tooltipObject != null && computedValue < min"
        :title="tooltipObject.fullname"
        :tooltip="tooltipObject.minTooltip"
        :tooltipContent="tooltipObject.minTooltipContent"
      ></tooltip>
      <tooltip
        mode="alert"
        v-if="tooltipObject != null && computedValue > max"
        :title="tooltipObject.fullname"
        :tooltip="tooltipObject.maxTooltip"
        :tooltipContent="tooltipObject.maxTooltipContent"
      ></tooltip>
    </div>
    <div class="col-md-3 col-xs-3">
      <el-input
        :disabled="disabled"
        @focus="onFocus"
        @blur="onBlur"
        v-model="value"
        :status="!isValid ? 'error' : ''"
      ></el-input>
      <!-- <tooltip v-if="tooltipObject != null" :title="tooltipObject.fullname" :tooltip="tooltipObject.tooltip" :tooltipContent="tooltipObject.tooltipContent"></tooltip> -->
    </div>
    <div class="col-md-3 col-xs-3 flex-middle">
      <label>&nbsp;{{suffix}}</label>
    </div>
    <div class="col-md-3 col-xs-3 flex-middle">
      <label
        class="flex-end"
        style="font-size:10px"
      >{{min}} - {{max}}</label>
    </div>
    <div
      class="col-md-6 col-xs-6"
      id="slider"
    >
      <at-slider
        v-model="computedValue"
        @change="sliderValueChange"
        :min="min - range"
        :max="max + range"
      ></at-slider>
    </div>
  </div>
</template>

<script>
import tooltipCtrl from "@/components/bloodbank/controls/tooltip/Tooltip";
import petTypeEnum from "@/constants/bloodbank/pet-type-enum.js";
import { mapState } from "vuex";

export default {
  name: "lab-slider",
  components: {
    tooltip: tooltipCtrl
  },
  data() {
    return {
      onFocusValue: "",
      value:
        isNaN(parseFloat(this.inputValue)) || isNaN(this.inputValue)
          ? null
          : Number(this.inputValue),
      range: this.max - this.min,
      bars: null,
      petTypeEnum: petTypeEnum
    };
  },
  mounted: function() {
    this.bars = this.$el.getElementsByClassName("at-slider__dot");
    for (let i = 0; i < this.bars.length; i++) {
      if (this.computedValue < this.firstTreshold) {
        this.bars[i].style.backgroundColor = "orange";
      } else if (this.computedValue < this.secondTreshold) {
        this.bars[i].style.backgroundColor = "blue";
      } else {
        this.bars[i].style.backgroundColor = "red";
      }
    }
  },
  methods: {
    sliderValueChange: function(sliderValue) {
      var i = 0;
      if (sliderValue < this.firstTreshold) {
        for (i = 0; i < this.bars.length; i++) {
          this.bars[i].style.backgroundColor = "orange";
        }
      } else if (sliderValue < this.secondTreshold) {
        for (i = 0; i < this.bars.length; i++) {
          this.bars[i].style.backgroundColor = "blue";
        }
      } else {
        for (i = 0; i < this.bars.length; i++) {
          this.bars[i].style.backgroundColor = "red";
        }
      }
    },

    onFocus: function() {
      this.onFocusValue = this.value;
      this.value = "";
    },

    onBlur: function() {
      if (this.value === "") {
        this.value = this.onFocusValue;
      }
    }
  },
  computed: {
    ...mapState({
      tooltips: state => state.bloodbank.tooltips
    }),
    firstTreshold: function() {
      return this.min;
    },
    secondTreshold: function() {
      return this.max;
    },
    computedValue: {
      get: function() {
        if (
          this.value === "" ||
          this.value == null ||
          this.value === "0" ||
          this.value === "0.0000" ||
          this.value === 0
        ) {
          return Number(this.min - this.range);
        }
        var result =
          this.value < this.min - this.range
            ? this.min - this.range
            : this.value > this.max + this.range
            ? this.max + this.range
            : this.value;
        return (Number(result) * 100) / 100;
      },
      // eslint-disable-next-line no-unused-vars
      set: function(val) {}
    },
    tooltipObject: function() {
      if (!this.tooltips) return null;
      return this.petType === petTypeEnum.DOG
        ? this.tooltips.labExamMeta.dog[this.propName.toUpperCase()]
        : this.tooltips.labExamMeta.cat[this.propName.toUpperCase()];
    }
  },
  watch: {
    value: function(val) {
      if (val !== null)
        this.$emit("updated", { prop: this.propName, value: val.toString() });
    }
  },
  props: [
    "prefix",
    "suffix",
    "min",
    "max",
    "tooltip-low",
    "tooltip-medium",
    "tooltip-high",
    "propName",
    "inputValue",
    "petType",
    "isValid",
    "isRequired",
    "disabled"
  ]
};
</script>

<style>
.lab-slider label {
  font-size: 14px;
}

.at-slider__bar {
  background-color: transparent !important;
}
.at-slider__dot {
  width: 2px !important;
  margin-left: 4px !important;
  margin-top: 6px !important;
  height: 18px !important;
  border-radius: 0% !important;
}

.at-slider__track {
  height: 20px !important;
  width: 100% !important;
  border-style: solid !important;
  border-color: grey !important;
  border-width: 1px !important;
  background-color: transparent !important;
}

.at-slider__track:not(child):before {
  position: absolute !important;
  margin-left: 33% !important;
  width: 1px !important;
  height: 18px !important;
  content: "" !important;
  display: block !important;
  background: grey !important;
}

.at-slider__track:not(child):after {
  position: absolute !important;
  margin-left: 66% !important;
  width: 1px !important;
  height: 18px !important;
  content: "" !important;
  display: block !important;
  background: grey !important;
}

#slider
  > .at-slider
  > .at-slider__track
  > .at-slider__dot-wrapper
  > .el-tooltip
  > .el-tooltip__popper {
  display: none;
}
</style>
